import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import clsx from 'clsx';
import React from 'react';

interface Props extends ButtonProps {
  text: string;
  loading: boolean;
}

function SubmitButton(props: Props) {
  const {text, loading, disabled, className, children, ...rest} = props;

  const classes = useStyles();

  return (
    <Button
      variant="contained"
      color="primary"
      type="submit"
      disabled={loading || disabled}
      className={clsx(classes.button, className)}
      {...rest}
    >
      {loading ? <CircularProgress size={24} /> : text}
    </Button>
  );
}

SubmitButton.defaultProps = {
  text: 'Submit',
  loading: false,
};

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(2),
    width: 200
  },
}));

export default SubmitButton;