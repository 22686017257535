import axios from 'axios';
import { action } from 'mobx';

import BaseAPIStore, { IAPIStore } from '../api.store';

export class StockReceiveStore extends BaseAPIStore implements IAPIStore {
  @action
  public async request(
    transferId: string,
    branch: string,
    supplies: ITransferSupplyItem[],
    note: string = ''
  ) {
    this.clear();

    const url = this.apiUrl + '/stocks/action/receive';
    this.requesting = true;
    try {
      const params = { transferId, branch, supplies, note };
      const response = await axios.post(url, params, this.authorizeConfigs());
      const responseData = response.data;

      if (responseData.data) {
        this.data = responseData.data;
      } else {
        this.data = undefined;
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.data = undefined;
      this.error = error;
    }

    this.requesting = false;
  }
}

const stockReceiveStore = new StockReceiveStore();
export default stockReceiveStore;
