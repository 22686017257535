import {
  Button,
  CircularProgress,
  LinearProgress,
  makeStyles,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import SnackBarContent from '../../../components/SnackBarContent';
import { StoreContext } from '../../../mobx';
import { RoleStore } from '../../../mobx/stores/roleStore/roleStore';
import { currencyString, dateISOFomatter, dateStringFormatter } from '../../../utils/formatter';

export const UnpaidBillingPage = observer(() => {
  const store = useContext(StoreContext);
  const getBillingApi = store.report.getBillingReport;
  const settleApi = store.bill.settlePayment;
  const classes = useStyles();

  const [bills, setBills] = useState<IBilling[]>([]);
  const [notifiedData, setNotifiedData] = useState<INotifiedData>({
    open: false,
    variant: 'success',
    message: '',
  });

  useEffect(() => {
    getBillingApi.request('pending');
  }, []);

  useEffect(() => {
    setBills(getBillingApi.data ?? []);
  }, [getBillingApi.data]);

  const handleSettleBill = (bill: IBilling) => async () => {
    await settleApi.request(bill._id);
    if (settleApi.error) {
      setNotifiedData({
        open: true,
        variant: 'error',
        message: settleApi.error.message || 'Something went wrong',
      });
    } else {
      getBillingApi.request('pending');
    }
  };

  const handleClose = (event?: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotifiedData((oldValue) => ({
      ...oldValue,
      open: false,
    }));
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" align="left">
        Unpaid Bill
      </Typography>

      <Paper className={classes.paper}>
        <LinearProgress hidden={!getBillingApi.requesting} />
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Vendor</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bills.map((item, index) => (
              <TableRow key={item._id}>
                <TableCell>{item.no || '-'}</TableCell>
                <TableCell>{item.vendor || '-'}</TableCell>
                <TableCell>{dateISOFomatter(item.date)}</TableCell>
                <TableCell>{currencyString(item.totalPrice)}</TableCell>
                <TableCell>
                  {RoleStore.canSettleUnpaidBill(store.auth.currentUser) && (
                    <Button
                      title="ชำระเงินเรียบร้อย"
                      variant="contained"
                      color="primary"
                      disabled={settleApi.requesting}
                      onClick={handleSettleBill(item)}
                    >
                      {settleApi.requesting ? <CircularProgress size={24} /> : 'ชำระเงินเรียบร้อย'}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notifiedData.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackBarContent
          variant={notifiedData.variant}
          message={notifiedData.message}
          onClose={handleClose}
        />
      </Snackbar>
    </div>
  );
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 500,
  },
  textField: {
    minWidth: 200,
  },
  menu: {
    minWidth: 200,
  },
  margin: {
    margin: theme.spacing(1),
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  inputGrid: {
    padding: theme.spacing(1),
  },
}));
