import axios from 'axios';
import { action } from 'mobx';

import BaseAPIStore, { IAPIStore } from '../api.store';

export class AdjustStockReportStore extends BaseAPIStore<IStockLog[]> implements IAPIStore {
  @action
  public async request(fromDate: string, toDate?: string, branch?: string) {
    const url = this.apiUrl + `/reports/stock/adjust/${fromDate}/${toDate}`;
    this.error = undefined;
    this.requesting = true;

    try {
      const response = await axios.get(url, this.authorizeConfigs());
      const responseData = response.data;

      if (responseData.data) {
        this.data = responseData.data;
      } else {
        this.data = undefined;
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.data = undefined;
      this.error = error;
    } finally {
      this.requesting = false;
    }
  }
}

const adjustStockReportStore = new AdjustStockReportStore();
export default adjustStockReportStore;
