import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { compile } from 'path-to-regexp';
import useSWR from 'swr';
import { Key, SWRConfiguration } from 'swr/dist/types';
import { COOKIE_TOKEN_KEY } from '../../../mobx/stores/authStore';

type Parameters = Record<string, any>;

interface IUseFetchApiConfigs {
  params?: Record<string, any>;
  urlParams?: Record<string, any>;
  dataPath?: string;
  notReady?: boolean; // true when not ready to fetch
}

function fetchApiSwrKey(endpoint: string, configs: IUseFetchApiConfigs = {}): Key {
  if (configs.notReady) {
    return null;
  }
  const baseApi = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000/api/v1';
  const toPath = compile(endpoint, { encode: encodeURIComponent });
  const url = baseApi + toPath(configs.urlParams);

  const { params, dataPath } = configs;
  return [url, params, dataPath];
}

async function fetcher<Data>(url: string, params?: Record<string, any>, dataPath?: string) {
  const token = Cookies.get(COOKIE_TOKEN_KEY);
  const configs: AxiosRequestConfig = {
    headers: { Authorization: 'bearer ' + token },
    params,
  };
  const res = await Axios.get<APIResponse<Data>>(url, configs);
  const data = getDataPath<Data>(res.data?.data, dataPath);
  return data;
}

export function useFetchApi<Data>(
  endpoint: string,
  configs: IUseFetchApiConfigs & SWRConfiguration<Data> = {}
) {
  const key = fetchApiSwrKey(endpoint, configs);
  return useSWR<Data>(key, fetcher, {
    errorRetryCount: 2,
    ...configs,
  });
}

export function getDataPath<R>(res: any, path?: string): R {
  if (typeof res !== 'object' || path == null || path.trim() === '' || Array.isArray(res)) {
    return res;
  }

  const paths = path.split('.');
  let data = res;

  paths.forEach((v) => {
    if (typeof res === 'object' && Object.keys(res).includes(v)) {
      data = res[v];
    }
  });
  return data;
}
