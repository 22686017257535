import { observable } from 'mobx';

import adjustStore from './stock.adjust.store';
import BuyStore from './stock.buy.store';
import mixStore from './stock.mix.store';
import receiveStore from './stock.receive.store';
import transferStore from './stock.transfer.store';
import stockVendorsStore from './stock.vendors.store';
import cancelTransferStore from './stock.transfer-cancel.store';

export class StockStore {
  @observable public buyStore = new BuyStore(this);
  @observable public mixStore = mixStore;
  @observable public adjustStore = adjustStore;
  @observable public transferStore = transferStore;
  @observable public receiveStore = receiveStore;
  @observable public getVendors = stockVendorsStore;
  @observable public cancelTransferStore = cancelTransferStore;
}

const stockStore = new StockStore();

export default stockStore;
