import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  FormControl,
  Grid,
  LinearProgress,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { dateISOFomatter } from '../../utils/formatter';
import { useFetchApi } from '../../utils/services/api/useFetchApi';
import { TransferItemReportModal } from '../../components/modals/TransferItemReportModal';
import BranchPicker from '../../components/Input/BranchPicker';

export const TransferReportPage = observer(() => {
  const classes = useStyles();

  const [fromDate, setFromDate] = useState<Date | null>(() => {
    const dateFns = new DateFnsUtils();
    return dateFns.addDays(new Date(), -30);
  });
  const [toDate, setToDate] = useState<Date | null>(new Date());
  const [status, setStatus] = useState<ITransferSupply['status']>();
  const [openingItem, setOpeningItem] = useState<ITransferSupply | null>(null);
  const [fromBranch, setFromBranch] = useState<string | null>(null);
  const [toBranch, setToBranch] = useState<string | null>(null);

  const { data: transfers, isValidating } = useFetchApi<ITransferSupply[]>(
    '/reports/stock/transfer/',
    {
      params: {
        fromDate,
        toDate,
        status,
        fromBranch,
        toBranch,
      },
    }
  );

  const handleOpenItem = (item: ITransferSupply) => () => {
    setOpeningItem(item);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" align="left">
        Transfer Report
      </Typography>
      <Paper className={classes.paper}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="flex-start" className={classes.inputGrid}>
            <Grid item className={classes.marginRight}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="from date"
                value={fromDate}
                onChange={setFromDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <Grid item>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="to date"
                value={toDate}
                onChange={setToDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <FormControl className={classes.formControl} required>
              <Select
                name="status"
                value={status}
                label="status"
                placeholder="status"
                displayEmpty={true}
                onChange={(e) => setStatus(e.target.value as ITransferSupply['status'])}
              >
                <MenuItem key={'ALL'} value={''}>
                  {'ALL'}
                </MenuItem>
                <MenuItem key={'TRANSFERRED'} value={'TRANSFERRED'}>
                  {'TRANSFERRED'}
                </MenuItem>
                <MenuItem key={'RECEIVED'} value={'RECEIVED'}>
                  {'RECEIVED'}
                </MenuItem>
                <MenuItem key={'LOST'} value={'LOST'}>
                  {'LOST'}
                </MenuItem>
              </Select>
            </FormControl>
            <BranchPicker
              label="จากสาขา"
              name="fromBranch"
              noneOptionTitle="ทั้งหมด"
              classname={classes.branchPicker}
              onSelect={setFromBranch}
            />
            <BranchPicker
              label="ไปยังสาขา"
              name="toBranch"
              noneOptionTitle="ทั้งหมด"
              classname={classes.branchPicker}
              onSelect={setToBranch}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Paper>
      <Paper className={classes.paper}>
        <LinearProgress hidden={!isValidating} />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>title</TableCell>
              <TableCell>วันที่</TableCell>
              <TableCell>จากสาขา</TableCell>
              <TableCell>ไปยังสาขา</TableCell>
              <TableCell>สถานะ</TableCell>
              <TableCell>ผู้ส่ง</TableCell>
              <TableCell>ผู้รับ</TableCell>
              <TableCell>โน๊ต</TableCell>
              <TableCell>actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transfers?.map((row) => (
              <TableRow key={row._id}>
                <TableCell>{row.title || '-'}</TableCell>
                <TableCell>
                  {dateISOFomatter(row.createdAt ?? '', 'ccc, dd-MM-yyyy HH:mm')}
                </TableCell>
                <TableCell>{row.branch.name}</TableCell>
                <TableCell>{row.toBranch.name}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{row.actor.firstname || '-'}</TableCell>
                <TableCell>{row.receiver?.firstname || '-'}</TableCell>
                <TableCell>{row.note || '-'}</TableCell>
                <TableCell>
                  <Button variant="contained" onClick={handleOpenItem(row)}>
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <TransferItemReportModal
        open={!!openingItem}
        handleClose={() => setOpeningItem(null)}
        item={openingItem}
      />
    </div>
  );
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 500,
  },
  textField: {
    minWidth: 200,
  },
  menu: {
    minWidth: 200,
  },
  margin: {
    margin: theme.spacing(1),
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  inputGrid: {
    padding: theme.spacing(1),
  },
  formControl: {
    marginLeft: theme.spacing(2),
    minWidth: 120,
    alignSelf: 'flex-end',
    top: -7,
  },
  branchPicker: {
    marginLeft: theme.spacing(2),
    minWidth: 120,
    top: 8,
  },
}));
