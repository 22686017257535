import { AppBar, Button, CssBaseline, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { StoreContext } from '../../mobx';
import { RoleStore } from '../../mobx/stores/roleStore/roleStore';
import routeName from '../../routes/routeName';
import Sidebar from './components/Sidbar/Sidebar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  main: {
    flexGrow: 1,
    padding: theme.spacing(2, 3),
  },
  toolbar: theme.mixins.toolbar,
}));

const BackofficeLayout: React.FC = (props) => {
  const store = useContext(StoreContext);
  const history = useHistory();
  const classes = useStyles();
  const canAccessPage = RoleStore.canAccessPage(history.location.pathname, store.auth.currentUser);

  // useEffect(()=> {
  //   if (!store.auth.isAuthenticated()) {
  //     history.replace(routeName.auth.login);
  //   } else if (!canAccessPage) {
  //     history.replace('/');
  //   }
  // }, []);

  if (!store.auth.isAuthenticated()) {
    return <Redirect to={routeName.auth.login} />;
  } else if (!canAccessPage) {
    return <Redirect to={routeName.backoffice.dashboard} />;
  }

  const handleLogout = async () => {
    await store.auth.logout();
    history.push(routeName.auth.login);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography>Susan Croissant</Typography>
          <Button type="submit" style={{ color: 'white' }} onClick={handleLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Sidebar />
      <main className={classes.main}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
};

export default BackofficeLayout;
