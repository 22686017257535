import axios from 'axios';
import { action } from 'mobx';
import BaseAPIStore, { IAPIStore } from '../api.store';

export class SuppliesUsageStore extends BaseAPIStore<ISuppliesUsagePlan> implements IAPIStore {
  @action
  public async request(date: string) {
    const url = this.apiUrl + '/plans/supplies-usage/' + date;

    this.error = undefined;
    this.requesting = true;
    try {
      const response = await axios.get(url, this.authorizeConfigs());
      const responseData = response.data;

      if (responseData.data) {
        this.data = responseData.data;
      } else {
        this.data = undefined;
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.data = undefined;
      this.error = error;
    }

    this.requesting = false;
  }
}

const suppliesUsageStore = new SuppliesUsageStore();
export default suppliesUsageStore;
