import Axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import { compile } from 'path-to-regexp';
import { COOKIE_TOKEN_KEY } from '../../../mobx/stores/authStore';

export async function requestPostApi<R>(
  endpoint: string,
  params?: any,
  urlParams?: Record<string, any>
): Promise<R> {
  const baseApi = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000/api/v1';
  const toPath = compile(endpoint, { encode: encodeURIComponent });
  const url = baseApi + toPath(urlParams);

  const response = await Axios.post<APIResponse<R>>(url, params, authorizeConfigs());
  const responseData = response.data;

  if (responseData.data) {
    return responseData.data;
  } else {
    throw new Error('Something went wrong.');
  }
}

export async function requestPutApi<R>(
  endpoint: string,
  params?: any,
  urlParams?: Record<string, any>
): Promise<R> {
  const baseApi = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000/api/v1';
  const toPath = compile(endpoint, { encode: encodeURIComponent });
  const url = baseApi + toPath(urlParams);

  const response = await Axios.put<APIResponse<R>>(url, params, authorizeConfigs());
  const responseData = response.data;

  if (responseData.data) {
    return responseData.data;
  } else {
    throw new Error('Something went wrong.');
  }
}

function authorizeConfigs() {
  const token = Cookies.get(COOKIE_TOKEN_KEY);
  const configs: AxiosRequestConfig = {
    headers: { Authorization: 'bearer ' + token },
  };
  return configs;
}
