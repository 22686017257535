import axios from 'axios';
import { action } from 'mobx';

import BaseAPIStore, { IAPIStore } from '../api.store';

export class StockTransferStore extends BaseAPIStore implements IAPIStore {
  @action
  public async request(
    branch: string,
    toBranch: string,
    supplies: Array<{ supply: string; amount: number }>,
    note: string = '',
    title?: string
  ) {
    this.clear();

    const url = this.apiUrl + '/stocks/action/transfer';
    this.requesting = true;
    try {
      const params = { title, branch, toBranch, supplies, note };
      const response = await axios.post(url, params, this.authorizeConfigs());
      const responseData = response.data;

      if (responseData.data) {
        this.data = responseData.data;
      } else {
        this.data = undefined;
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.data = undefined;
      const err = error.response ? error.response.data : error;
      this.error = err;
    }

    this.requesting = false;
  }
}

const storeMixStore = new StockTransferStore();
export default storeMixStore;
