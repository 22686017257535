import axios from 'axios';
import {action} from 'mobx';

import BaseAPIStore, {IAPIStore} from '../api.store';

export class RecipeCreateStore extends BaseAPIStore<IRecipe> implements IAPIStore {

  @action
  public async request(supply: string, amount: number, unit: string, ingredients: IIngredientRecipe[], recipeId?: string) {
    let url = this.apiUrl + '/recipes';
    if (recipeId) {
      url = url + '/' + recipeId;
    }

    this.error = undefined;
    this.requesting = true;
    try {
      const params = {supply, amount, unit, ingredients};
      const response = await axios.post(url, params, this.authorizeConfigs());
      const responseData = response.data;

      if (responseData.data) {
        this.data = responseData.data;
      } else {
        this.data = undefined;
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.data = undefined;
      this.error = error;
    }

    this.requesting = false;
  }
}

const recipeCreateStore = new RecipeCreateStore();
export default recipeCreateStore;
