import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import routes, { IRouteItem } from './routes';

function AppRouter() {
  const renderRoute = (route: IRouteItem, i: number) => {
    return (
      <Route key={i} path={route.path}>
        <route.layout>
          <route.component {...route.props} />
        </route.layout>
      </Route>
    );
  };

  return (
    <Switch>
      <Route exact path="/">
        <HomePage />
      </Route>
      {routes.map(renderRoute)}
    </Switch>
  );
}

export default AppRouter;
