import axios, { AxiosResponse } from 'axios';
import { action } from 'mobx';

import BaseAPIStore, { IAPIStore } from '../api.store';

export class SupplyListStore extends BaseAPIStore<ISupplyItemType[]> implements IAPIStore {
  constructor() {
    super();
    this.data = [];
  }

  @action
  public async request() {
    const url = this.apiUrl + '/supplies';
    this.error = undefined;
    this.requesting = true;
    try {
      const response = await axios.get<AxiosResponse<ISupplyItemType[]>>(
        url,
        this.authorizeConfigs()
      );
      const responseData = response.data;
      if (responseData.data) {
        this.data = responseData.data.filter((v) => v.name !== '');
      } else {
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.error = error;
    }

    this.requesting = false;
  }
}

const supplyListStore = new SupplyListStore();
export default supplyListStore;
