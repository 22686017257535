import React, { useContext } from 'react';
import { StoreContext } from '../../mobx';
import { Autocomplete } from '@material-ui/lab';
import { TextField, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';

interface Props {
  value?: string;
  onChange?: (value?: string) => void;
}

export const SupplyInput = observer((props: Props) => {
  const store = useContext(StoreContext);
  const { supply } = store;
  const suppies = supply.list.data?.slice() || [];

  const classes = useStyles();

  React.useEffect(() => {
    supply.list.request();
  }, [supply.list]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
  };

  const handleProducedSupplyChange = (e: React.ChangeEvent<{}>, value?: ISupplyItemType | null) => {
    if (value == null) {
      return props.onChange?.(undefined);
    }
    const supplyId = typeof value === 'string' ? value : value._id;
    props.onChange?.(supplyId);
  };

  // const name = suppies.find((v) => inputProps?.rowData?.supply === v._id)?.name || '';
  return (
    <Autocomplete
      id="search-supply"
      options={suppies}
      getOptionLabel={(option) =>
        option.code != null ? `${option.code}: ${option.name}` : option.name
      }
      className={classes.mixedAutocomplete}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.input}
          label="วัตถุดิบที่ได้"
          name="supply"
          type="text"
          onChange={handleChange}
        />
      )}
      onChange={handleProducedSupplyChange}
    />
  );
});

const useStyles = makeStyles((theme) => ({
  mixedAutocomplete: {
    display: 'inline-flex',
    minWidth: 200,
  },
  input: {
    margin: theme.spacing(1),
  },
}));
