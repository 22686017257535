import routeName from '../../../routes/routeName';

export class RoleStore {
  public static canAccessPage(page: string, user: IUser): boolean {
    if (user === null || user === undefined) {
      return false;
    }

    if (user.role === 'admin') {
      return true;
    }

    if (user.role === 'operation-manager') {
      const notPages: string[] = [
        ...Object.values(routeName.backoffice.user),
        ...Object.values(routeName.backoffice.recipes),
        routeName.backoffice.supplies,
        routeName.backoffice.stock.buy,
        routeName.backoffice.stock.buyEdit,
        routeName.backoffice.reports.unpaidBilling,
      ];
      return !notPages.includes(page);
    }

    if (user.role === 'stock-manager') {
      const pages: string[] = [
        routeName.backoffice.dashboard,
        routeName.backoffice.supplies,
        routeName.backoffice.product.list,

        routeName.backoffice.stock.buy,
        routeName.backoffice.stock.index,
        routeName.backoffice.stock.mix,
        routeName.backoffice.stock.receiveList,
        routeName.backoffice.stock.transfer,

        ...Object.values(routeName.backoffice.branches),
        ...Object.values(routeName.backoffice.reports),
        ...Object.values(routeName.backoffice.plans),
        ...Object.values(routeName.backoffice.settings),
        ...Object.values(routeName.backoffice.orders),
      ];
      return pages.includes(page);
    }

    if (user.role === 'stock-staff') {
      const pages: string[] = [
        routeName.backoffice.dashboard,
        routeName.backoffice.supplies,
        routeName.backoffice.product.list,

        routeName.backoffice.stock.buy,
        routeName.backoffice.stock.transfer,

        routeName.backoffice.reports.stockDailyReport,
        routeName.backoffice.reports.stockRangeReport,
        routeName.backoffice.reports.productSalesReport,
        routeName.backoffice.reports.productSalesReportList,
        routeName.backoffice.reports.productSalesReportDateDetail,
        routeName.backoffice.reports.unpaidBilling,
        routeName.backoffice.reports.expensesReport,
        routeName.backoffice.reports.expensesCategoryReport,

        ...Object.values(routeName.backoffice.plans),
      ];

      return pages.includes(page);
    }

    if (user.role === 'account-manager') {
      const pages: string[] = [
        routeName.backoffice.dashboard,
        routeName.backoffice.supplies,
        // routeName.backoffice.product.list,
        routeName.backoffice.stock.buy,
        routeName.backoffice.stock.transfer,
        routeName.backoffice.stock.receiveList,

        routeName.backoffice.reports.stockDailyReport,
        routeName.backoffice.reports.stockRangeReport,
        routeName.backoffice.reports.productSalesReport,
        routeName.backoffice.reports.productSalesReportList,
        routeName.backoffice.reports.productSalesReportDateDetail,
        routeName.backoffice.reports.unpaidBilling,
        routeName.backoffice.reports.expensesReport,
        routeName.backoffice.reports.expensesCategoryReport,

        ...Object.values(routeName.backoffice.orders),
        routeName.backoffice.settings.salesReportImport,
      ];

      return pages.includes(page);
    }

    if (user.role === 'account-staff') {
      const pages: string[] = [
        routeName.backoffice.dashboard,
        routeName.backoffice.reports.unpaidBilling,
        routeName.backoffice.reports.expensesReport,
      ];

      return pages.includes(page);
    }

    if (user.role === 'kitchen-manager') {
      const pages: string[] = [
        routeName.backoffice.dashboard,
        routeName.backoffice.supplies,
        // routeName.backoffice.product.list,

        routeName.backoffice.stock.mix,
        routeName.backoffice.stock.transfer,
        routeName.backoffice.stock.receiveList,

        routeName.backoffice.reports.stockDailyReport,
        routeName.backoffice.reports.stockRangeReport,
        routeName.backoffice.reports.productSalesReport,
        routeName.backoffice.reports.productSalesReportList,
        routeName.backoffice.reports.productSalesReportDateDetail,
        // routeName.backoffice.reports.adjustReport,
        routeName.backoffice.reports.transferReport,

        ...Object.values(routeName.backoffice.plans),
      ];

      return pages.includes(page);
    }

    if (user.role === 'kitchen-staff') {
      return false;
    }

    if (user.role === 'store-manager') {
      const pages: string[] = [
        routeName.backoffice.dashboard,
        routeName.backoffice.supplies,
        // routeName.backoffice.product.list,

        routeName.backoffice.reports.stockDailyReport,
        routeName.backoffice.reports.stockRangeReport,
        routeName.backoffice.reports.productSalesReport,
        routeName.backoffice.reports.productSalesReportList,
        routeName.backoffice.reports.productSalesReportDateDetail,
        // routeName.backoffice.reports.adjustReport,
        routeName.backoffice.reports.transferReport,

        routeName.backoffice.settings.salesReportImport,
        ...Object.values(routeName.backoffice.plans),
      ];

      return pages.includes(page);
    }

    if (user.role === 'store-staff') {
      const pages: string[] = [
        routeName.backoffice.dashboard,
        routeName.backoffice.reports.transferReport,
      ];

      return pages.includes(page);
    }

    if (user.role === 'line-admin') {
      const pages: string[] = [routeName.backoffice.dashboard];

      return pages.includes(page);
    }

    return false;
  }

  public static canEditSupply(user?: IUser) {
    if (user == null) {
      return false;
    }
    const roles: Role[] = ['admin', 'stock-manager', 'account-manager'];
    return roles.includes(user.role);
  }

  public static canSettleUnpaidBill(user?: IUser) {
    if (user == null) {
      return false;
    }
    const roles: Role[] = [
      'admin',
      'operation-manager',
      'stock-manager',
      'stock-staff',
      'account-manager',
      'account-staff',
    ];
    return roles.includes(user.role);
  }

  public static canExportStockDailyReport(user?: IUser) {
    if (user?.role == null) {
      return false;
    }
    const roles: Role[] = [
      'admin',
      'operation-manager',
      'stock-manager',
      'stock-staff',
      'kitchen-manager',
    ];
    return roles.includes(user.role);
  }

  public static canMixIngredientWithAutoSuggestItem(user?: IUser) {
    if (user?.role == null) {
      return false;
    }
    const roles: Role[] = ['admin'];
    return roles.includes(user.role);
  }

  public static canEditBilling(user?: IUser) {
    if (user?.role == null) {
      return false;
    }
    const roles: Role[] = ['admin'];
    return roles.includes(user.role);
  }

  public static canCancelTransfer(item: ITransferSupply, user?: IUser) {
    if (user?.role == null || item.createdAt == null) {
      return false;
    }

    const roles: Role[] = ['admin', 'operation-manager'];
    if (roles.includes(user.role)) {
      return true;
    }

    const transferDate = new Date(item.createdAt);
    const today = new Date();
    const diffTime = Math.abs(today.getTime() - transferDate.getTime());
    const diffHours = Math.ceil(diffTime / (1000 * 60 * 60)); // hours

    const roles2: Role[] = ['stock-manager', 'kitchen-manager'];

    return diffHours <= 1 && roles2.includes(user.role);
  }
}
