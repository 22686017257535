import axios from 'axios';
import { action } from 'mobx';

import BaseAPIStore, { IAPIStore } from '../api.store';
import { AuthStore } from './auth.store';

class AuthLoginStore extends BaseAPIStore<IUser> implements IAPIStore {
  private authStore: AuthStore;

  constructor(authStore: AuthStore) {
    super();
    this.authStore = authStore;
  }

  @action
  public async request(username: string, password: string, branch: string) {
    const url = this.apiUrl + '/auth/login';
    const params = { username, password, branch };
    this.requesting = true;
    this.error = undefined;

    try {
      const response = await axios.post(url, params);
      const responseData = response.data;

      if (responseData.status === 'success' && responseData.data) {
        this.data = responseData.data;
        this.authStore.storeUserToken(responseData.data.token);
        this.authStore.setCurrentUser(responseData.data);
      } else {
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.error = error;
    }
    this.requesting = false;
  }
}

export default AuthLoginStore;
