import DateFnsUtils from '@date-io/date-fns';
import { Button, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Column } from 'material-table';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MaterialTable } from '../../../../components';
import { StoreContext } from '../../../../mobx';
import routeName from '../../../../routes/routeName';
import { dateFormatter, dateISOFomatter } from '../../../../utils/formatter';

function SalesReportListByDatePage() {
  const store = useContext(StoreContext);
  const history = useHistory();
  const productSalesByDateReport = store.report.productSalesByDateReport;
  const classes = useStyles();

  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  useEffect(() => {
    if (fromDate && toDate) {
      const fromDateString = dateFormatter(fromDate);
      const toDateString = dateFormatter(toDate);
      productSalesByDateReport.request(fromDateString, toDateString);
    } else {
      productSalesByDateReport.request();
    }
  }, [fromDate, toDate]);

  const columns: Array<Column<ISalesReportGroupByDateModel>> = [
    { title: 'Date', field: 'date', render: (data) => dateISOFomatter(data.date) },
    {
      title: 'Actions',
      field: 'name',
      render: (data) => <Button onClick={handleOnClickViewItem(data)}>View</Button>,
    },
  ];

  const handleOnClickViewItem = (item: ISalesReportGroupByDateModel) => () => {
    history.push(routeName.backoffice.reports.productSalesReportDateDetail, {
      date: item.date,
    });
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" align="left">
        Sales Report
      </Typography>

      <Paper className={classes.paper}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="flex-start" spacing={2} className={classes.inputGrid}>
            <Grid item>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="From Date"
                value={fromDate}
                onChange={setFromDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <Grid item>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="To Date"
                value={toDate}
                onChange={setToDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </Paper>
      <Paper className={classes.paper}>
        <MaterialTable
          title="Sales Report"
          columns={columns}
          data={productSalesByDateReport.data || []}
          isLoading={productSalesByDateReport.requesting}
          options={{
            actionsColumnIndex: columns.length,
            pageSize: 20,
          }}
        />
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
    minHeight: 50,
  },
  textField: {
    minWidth: 200,
  },
  menu: {
    minWidth: 200,
  },
  margin: {
    margin: theme.spacing(1),
  },
  inputGrid: {
    padding: theme.spacing(1),
  },
}));

export default observer(SalesReportListByDatePage);
