import axios from 'axios';
import { action } from 'mobx';

import BaseAPIStore, { IAPIStore } from '../api.store';

export class StockVendorsStore extends BaseAPIStore implements IAPIStore {
  @action
  public async request() {
    this.clear();

    const url = this.apiUrl + '/stocks/vendors';
    this.requesting = true;
    try {
      const response = await axios.get(url, this.authorizeConfigs());
      const responseData = response.data;

      if (responseData.data) {
        this.data = responseData.data;
      } else {
        this.data = undefined;
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.data = undefined;
      this.error = error;
    }

    this.requesting = false;
  }
}

const stockVendorsStore = new StockVendorsStore();
export default stockVendorsStore;
