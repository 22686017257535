import { Chip, makeStyles, Paper, Typography } from '@material-ui/core';
import { Column } from 'material-table';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MaterialTable } from '../../../../components';
import { StoreContext } from '../../../../mobx';
import { currencyString, dateISOFomatter } from '../../../../utils/formatter';

function SalesReportDateDetailPage() {
  const store = useContext(StoreContext);
  const report = store.report.productSalesDateReport;

  const {
    state: { date },
  } = useLocation<{ date: string }>();

  const classes = useStyles();

  const columns: Array<Column<ISaleReportGroupByDateItem>> = [
    { title: 'Product', render: (data) => data.product.name },
    { title: 'Sale Product Id', render: renderSaleProductIdsRow },
    {
      title: 'Value',
      render: (data) => currencyString(data.totalValue),
    },
    {
      title: 'Volume',
      render: (data) => `${data.totalVolume} ${data.product.unit}`,
    },
  ];

  useEffect(() => {
    const apiDate = dateISOFomatter(date);
    report.request(apiDate);
  }, [date, report]);

  function renderSaleProductIdsRow(rowData?: ISaleReportGroupByDateItem) {
    if (rowData) {
      const items = rowData.product.saleProductIds;
      const chips = items.map((v, index) => (
        <Chip key={index + v} label={v} className={classes.chip} />
      ));
      return chips;
    }
  }

  return (
    <div className={classes.root}>
      <Typography variant="h4" align="left">
        Sales Report {dateISOFomatter(date)}
      </Typography>
      <Paper className={classes.paper}>
        <MaterialTable title="Sales Report" columns={columns} data={report.data || []} />
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
    minHeight: 50,
  },
  chip: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default observer(SalesReportDateDetailPage);
