// @flow
import React, {createContext, ReactNode, useEffect} from 'react';
import globalStore, {GlobalStore} from './stores/globalStore';

const StoreContext = createContext<GlobalStore>(globalStore);

interface Props {
  children?: ReactNode;
}

const StoreProvider = ({children}: Props) => {
  return (
    <StoreContext.Provider value={globalStore}>
      {children}
    </StoreContext.Provider>
  );
};

export {StoreContext};
export default StoreProvider;
