import {
  Button,
  CircularProgress,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import { Column, EditComponentProps } from 'material-table';
import { observer } from 'mobx-react';
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MaterialTable } from '../../components';
import SnackBarContent from '../../components/SnackBarContent';
import { StoreContext } from '../../mobx';
import routeName from '../../routes/routeName';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  mixedAutocomplete: {
    display: 'inline-flex',
  },
  input: {
    minWidth: 200,
    margin: theme.spacing(1),
  },
  menu: {
    minWidth: 200,
  },
  table: {
    minWidth: 650,
  },
  button: {
    marginTop: theme.spacing(2),
    width: 200,
  },
  padding: {
    padding: theme.spacing(2),
  },
}));

function RecipeNewPage(props: any) {
  const store = useContext(StoreContext);
  const { supply } = store;

  const location = useLocation<{ supplyId?: string; supplyAmount?: number }>();
  const history = useHistory();
  const classes = useStyles();

  const [recipeId, setRecipeId] = useState<string>();
  const [formData, setFormData] = useState({
    mixtedItem: location.state?.supplyId ?? '',
    mixtedAmount: location.state?.supplyAmount ?? 0,
  });
  const [ingredients, setIngredients] = useState<IIngredientRecipe[]>([]);
  const [unit, setUnit] = useState('');
  const [notifiedData, setNotifiedData] = useState<INotifiedData>({
    open: false,
    variant: 'success',
    message: '',
  });

  // Display values
  const suppies = supply.list.data?.slice() || [];
  const mixedItems = suppies.filter((item) => item.type === 'mixedIngredient');
  // const rawItems = suppies.filter((item) => item.type === 'rawIngredient');
  const suppiesItems = suppies.filter(
    (item) => item.type === 'mixedIngredient' || item.type === 'rawIngredient'
  );
  const mixedItem = suppies.find((item) => item._id === location.state?.supplyId);

  const columns: Array<Column<IIngredientRecipe>> = [
    {
      title: 'วัตถุดิบ',
      field: 'ingredient',
      render: renderSupply,
      editComponent: renderSupplyInput,
    },
    { title: 'จำนวนที่ใช้', field: 'amount' },
    { title: 'หน่วย', field: 'unit', render: renderUnit, editComponent: renderEditableUnit },
  ];

  //

  useEffect(() => {
    supply.list.request();
    store.recipe.listStore.request();
  }, [supply.list, store.recipe.listStore]);

  useEffect(() => {
    if (formData.mixtedItem === '') {
      return;
    }
    // const recipeIndex = recipes.findIndex(r => r.supply._id === formData.mixtedItem);
  }, [formData.mixtedItem]);

  useEffect(() => {
    const recipes = store.recipe.listStore.data;

    if (recipes === undefined) {
      return;
    }
    const recipe = recipes.find((r) => r.supply._id === formData.mixtedItem);
    if (recipe == null) {
      return;
    }

    const items: IIngredientRecipe[] = recipe.ingredients.map((item) => ({
      ingredient: item.ingredient._id,
      amount: item.amount,
      unit: item.unit,
    }));
    setIngredients(items);
    setRecipeId(recipe._id);
  }, [formData.mixtedItem, store.recipe.listStore.data]);

  const canSubmit = () => {
    let valid = formData.mixtedItem !== '' && formData.mixtedAmount > 0 && ingredients.length > 0;

    valid = valid && ingredients.filter((v) => v.ingredient === '' || v.amount > 0).length > 0;

    return valid;
  };

  // helper methods

  const getUnit = (ingredient: string) => {
    const data = suppies.find((value) => ingredient === value._id);
    return (data && data.unit) || '-';
  };

  const sanitizedSupplyData = (data: IIngredientRecipe): IIngredientRecipe => {
    const amount = typeof data.amount === 'string' ? parseFloat(data.amount) : data.amount;
    if (isNaN(amount)) {
      throw new Error('กรุณากรอกจำนวนเป็นตัวเลข');
    }

    return {
      ...data,
      amount,
    };
  };

  // handle Events

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const name = event.target.name;
    const value = event.target.value;

    if (name === 'mixtedItem' && value) {
      const index = suppies.findIndex((item) => item._id === value);
      if (index >= 0) {
        const item = suppies[index];
        setUnit(item.unit);
      }
    }

    setFormData((oldVals) => ({
      ...oldVals,
      [name]: value,
    }));
  };

  const handleMixedItemChange = (e: React.ChangeEvent<{}>, value?: ISupplyItemType | null) => {
    if (value == null) {
      return;
    }

    const item = suppies.find((v) => v._id === value._id);
    if (item) {
      setUnit(item.unit);
    }

    setFormData((oldVals) => ({
      ...oldVals,
      mixtedItem: value._id,
    }));
  };

  const handRowAdd = async (newData: IIngredientRecipe) => {
    const ingredientUnit = getUnit(newData.ingredient);
    const data = sanitizedSupplyData({ ...newData, unit: ingredientUnit });
    setIngredients((oldItems) => [...oldItems, data]);
  };

  const handleRowUpdate = async (newData: IIngredientRecipe, oldData?: IIngredientRecipe) => {
    const newIngredients = [...ingredients];

    const ingredientUnit = getUnit(newData.ingredient);
    const newIngredient = sanitizedSupplyData({ ...newData, unit: ingredientUnit });

    if (oldData) {
      const prevIndex = ingredients.findIndex((v) => v.ingredient === oldData.ingredient);
      if (prevIndex >= 0) {
        newIngredients[prevIndex] = newIngredient;
      } else {
        newIngredients.push(newIngredient);
      }
    } else {
      newIngredients.push(newIngredient);
    }

    setIngredients(newIngredients);
  };

  const handleRowDelete = async (oldData: IIngredientRecipe) => {
    const newIngredients = [...ingredients];
    const index = ingredients.findIndex((v) => v.ingredient === oldData.ingredient);
    if (index >= 0) {
      newIngredients.splice(index, 1);
    }
    setIngredients(newIngredients);
  };

  const handleSubmit = async () => {
    if (!canSubmit()) {
      return;
    }

    try {
      const { mixtedItem, mixtedAmount } = formData;
      await store.recipe.createStore.request(mixtedItem, mixtedAmount, unit, ingredients, recipeId);
      if (store.recipe.createStore.error) {
        setNotifiedData({
          open: true,
          variant: 'error',
          message: store.recipe.createStore.error.message,
        });
      } else {
        setFormData({
          mixtedItem: '',
          mixtedAmount: 0,
        });
        setIngredients([]);
        history.push(routeName.backoffice.recipes.list);
      }
    } catch (error) {
      setNotifiedData({
        open: true,
        variant: 'error',
        message: error instanceof Error ? error.message : 'Something went wrong.',
      });
    }
  };

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotifiedData((oldValue) => ({
      ...oldValue,
      open: false,
    }));
  };

  // render methods
  function renderSupplyInput(inputProps: EditComponentProps<IIngredientRecipe>) {
    const name = suppiesItems.find((v) => inputProps?.rowData?.ingredient === v._id)?.name || '';
    return (
      <Autocomplete
        id="material-input-suggest"
        options={suppiesItems}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} placeholder={name} margin="normal" />}
        onChange={(event, value) =>
          inputProps.onChange(typeof value === 'string' ? value : value?._id)
        }
      />
    );
  }

  function renderSupply(rowData?: IIngredientRecipe) {
    const name = suppiesItems.find((v) => rowData?.ingredient === v._id)?.name || '';
    return (
      <Typography variant="caption" align="left">
        {name}
      </Typography>
    );
  }

  function renderUnit(rowData?: IIngredientRecipe) {
    let rowUnit = '-';
    if (rowData) {
      rowUnit = getUnit(rowData.ingredient);
    }
    return (
      <Typography variant="caption" align="left">
        {rowUnit}
      </Typography>
    );
  }

  function renderEditableUnit(props: EditComponentProps<IIngredientRecipe>) {
    const { rowData } = props;
    return renderUnit(rowData);
  }

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notifiedData.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackBarContent
          variant={notifiedData.variant}
          message={notifiedData.message}
          onClose={handleClose}
        />
      </Snackbar>
      <Typography variant="h4" align="left">
        Recipe
      </Typography>
      <Paper className={clsx(classes.paper, classes.padding)}>
        <Typography variant="subtitle1" align="left">
          วัตถุดิบที่ผสม
        </Typography>
        <Autocomplete
          id="mixtedItem"
          options={mixedItems}
          getOptionLabel={(option) => option?.name}
          defaultValue={mixedItem}
          className={classes.mixedAutocomplete}
          onChange={handleMixedItemChange}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.input}
              label="วัตถุดิบที่ได้"
              name="mixtedItem"
            />
          )}
        />
        <TextField
          value={formData.mixtedAmount}
          className={classes.input}
          label={`จำนวน`}
          name="mixtedAmount"
          type="number"
          onChange={handleChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
          }}
        />
      </Paper>
      <Paper className={classes.paper}>
        <MaterialTable
          title="วัตถุดิบที่ใช้"
          columns={columns}
          data={ingredients}
          editable={{
            onRowAdd: handRowAdd,
            onRowUpdate: handleRowUpdate,
            onRowDelete: handleRowDelete,
          }}
          options={{
            search: false,
            paging: false,
            showEmptyDataSourceMessage: false,
            actionsColumnIndex: 3,
          }}
        />
      </Paper>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={!canSubmit() || store.recipe.createStore.requesting}
        className={classes.button}
        onClick={handleSubmit}
      >
        {store.recipe.createStore.requesting ? <CircularProgress size={24} /> : 'Submit'}
      </Button>
    </div>
  );
}

export default observer(RecipeNewPage);
