import { observable } from 'mobx';

import UserAddStore from './user.add.store';
import UserDeleteStore from './user.delete.store';
import userListStore from './user.list.store';
import UserUpdateStore from './user.update.store';

export class UserStore {
  @observable public list = userListStore;
  @observable public add = new UserAddStore(this);
  @observable public update = new UserUpdateStore(this);
  @observable public delete = new UserDeleteStore(this);

  public canCreateUserRoles(role: Role): Role[] {
    if (role === 'admin') {
      return [
        'admin',
        'operation-manager',
        'stock-manager',
        'stock-staff',
        'account-manager',
        'account-staff',
        'kitchen-manager',
        'kitchen-staff',
        'store-manager',
        'store-staff',
        'line-admin',
      ];
    }

    return [];
  }
}

const userStore = new UserStore();
export default userStore;
