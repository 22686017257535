import axios from 'axios';
import { action } from 'mobx';

import BaseAPIStore, { IAPIStore } from '../api.store';

export class StockCancelTransferStore extends BaseAPIStore implements IAPIStore {
  @action
  public async request(transferId: string, note: string = '') {
    this.clear();

    const url = this.apiUrl + `/stocks/action/transfer/${transferId}/cancel`;
    this.requesting = true;
    try {
      const params = { note };
      const response = await axios.post(url, params, this.authorizeConfigs());
      const responseData = response.data;

      if (response.status !== 200) {
        throw new Error(responseData.message || 'Something went wrong.');
      } else if (responseData.data) {
        this.data = responseData.data;
      } else {
        this.data = undefined;
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.data = undefined;
      if (error.response) {
        this.error = new Error(error.response.data.message);
      } else {
        this.error = error;
      }
    }

    this.requesting = false;
  }
}

const cancelTransferStore = new StockCancelTransferStore();
export default cancelTransferStore;
