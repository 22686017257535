import { Chip, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MaterialTable } from '../../components';
import { StoreContext } from '../../mobx';
import routeName from '../../routes/routeName';
import { numberFormatter, supplyAmountFormat } from '../../utils/formatter';

const SAPARTATE_INGREDIENT_ITEM = '~~';

interface RecipesListItem {
  id: string;
  supply: string;
  supplyId: string;
  amount: string;
  ingredients: string;
  // ingredients: Array<{ingredient: string, amount: string}>;
}

function RecipesListPage() {
  const store = useContext(StoreContext);

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    store.recipe.listStore.request();
  }, [store.recipe.listStore]);

  const columns = [
    { title: 'id', field: 'id', hidden: true },
    { title: 'supplyId', field: 'supplyId', hidden: true },
    { title: 'วัตถุดิบที่ได้', field: 'supply' },
    { title: 'จำนวน', field: 'amount' },
    { title: 'วัตถุดิบ', field: 'ingredients', render: renderIngredientRow },
    { title: 'ราคา', field: 'totalPrice' },
  ];
  const recpipesData = store.recipe.listStore.data || [];
  const recpipes: RecipesListItem[] = recpipesData.map((recipe) => {
    const ingredients = recipe.ingredients.reduce((previous, current, index) => {
      const value = `${current.ingredient?.name}: ${current.amount} ${current.unit}`;
      if (index === 0) {
        return value;
      } else {
        return previous + SAPARTATE_INGREDIENT_ITEM + value;
      }
    }, '');
    return {
      id: recipe._id,
      supply: recipe.supply.name,
      supplyId: recipe.supply._id,
      amount: supplyAmountFormat(recipe.amount, recipe.unit),
      ingredients,
      totalPrice: `${numberFormatter(recipe.totalPrice)} บาทต่อ ${supplyAmountFormat(
        recipe.amount,
        recipe.unit
      )}`,
    };
  });

  // handle events methods
  const handleRowAdd = () => {
    history.push(routeName.backoffice.recipes.new);
  };

  const handleRowEdit = (event: any, data: RecipesListItem | RecipesListItem[]) => {
    if (!Array.isArray(data)) {
      history.push(routeName.backoffice.recipes.new, {
        recipeId: data.id,
        supplyId: data.supplyId,
        supplyAmount: parseFloat(data.amount),
      });
    }
  };

  // render methods
  function renderIngredientRow(rowData?: RecipesListItem) {
    if (rowData) {
      const items = rowData.ingredients.split(SAPARTATE_INGREDIENT_ITEM);
      const chips = items.map((item, index) => (
        <Chip key={index + item} label={item} className={classes.ingredientChip} />
      ));
      return chips;
    }
  }

  return (
    <div className={classes.root}>
      <Typography variant="h4" align="left">
        Recipes
      </Typography>
      <Paper className={classes.paper}>
        <MaterialTable
          title="Recipes"
          columns={columns}
          data={recpipes}
          isLoading={store.recipe.listStore.requesting}
          actions={[
            {
              icon: 'add_box',
              tooltip: 'Add Recipe',
              isFreeAction: true,
              onClick: handleRowAdd,
            },
            {
              icon: 'edit',
              tooltip: 'Edit',
              onClick: handleRowEdit,
            },
          ]}
          options={{
            actionsColumnIndex: 4,
            pageSize: 20,
          }}
        />
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  ingredientChip: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default observer(RecipesListPage);
