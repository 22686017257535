import axios from 'axios';
import { action } from 'mobx';

import BaseAPIStore, { IAPIStore } from '../api.store';
import { BranchStore } from './branchStore';

class BranchAddStore extends BaseAPIStore<IBranch> implements IAPIStore {
  private branchStore: BranchStore;

  constructor(branchStore: BranchStore) {
    super();
    this.branchStore = branchStore;
  }

  @action
  public async request(newData: IBranch) {
    const url = this.apiUrl + '/branches/';
    this.error = undefined;
    this.requesting = true;
    try {
      const response = await axios.post(url, newData, this.authorizeConfigs());
      const responseData = response.data;

      if (responseData.data) {
        this.data = responseData.data;

        if (this.branchStore.list.data) {
          this.branchStore.list.data = [...this.branchStore.list.data, responseData.data];
        }
      } else {
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.error = error;
    }

    this.requesting = false;
  }
}

export default BranchAddStore;
