import React, { useContext, useState } from 'react';

import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { StoreContext } from '../../../mobx';
import routeName from '../../../routes/routeName';

function CreateUserPage() {
  const store = useContext(StoreContext);
  const addApi = store.user.add;
  const updateApi = store.user.update;
  const currentUser = store.auth.currentUser;
  const history = useHistory();
  // const { id: userId } = useParams();
  const location = useLocation<IUser & { id?: string }>();
  const isEditing = location?.state?.username != null;

  const classes = useStyles();

  const [formData, setFormData] = useState<Partial<IUser> & { password: string }>({
    username: location.state?.username || '',
    firstname: location.state?.firstname || '',
    lastname: location.state?.lastname || '',
    mobile: location.state?.mobile || '',
    role: location.state?.role,
    password: '',
  });

  const submitAddUser = async () => {
    if (addApi.requesting) {
      return;
    }
    await addApi.request(formData);
    if (addApi.data) {
      history.push(routeName.backoffice.user.list);
    } else {
      throw addApi.error ?? new Error('Something went wrong');
    }
  };

  const submitEditUser = async () => {
    if (updateApi.requesting) {
      return;
    }
    await updateApi.request(location.state.id ?? '', formData);
    if (updateApi.data) {
      history.push(routeName.backoffice.user.list);
    } else {
      throw updateApi.error ?? new Error('Something went wrong');
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>
  ) => {
    event.persist();
    if (event.target.name) {
      setFormData((oldValue) => ({
        ...oldValue,
        [event.target.name!]: event.target.value,
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      if (isEditing) {
        await submitEditUser();
      } else {
        await submitAddUser();
      }
    } catch (error) {
      alert(error instanceof Error ? error.message : 'Something went wrong.');
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <LinearProgress hidden={!addApi.requesting} />
        <form autoComplete="off">
          <CardHeader title={isEditing ? 'Edit User' : 'New User'} />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Username"
                  value={formData.username}
                  margin="dense"
                  name="username"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Password"
                  value={formData.password}
                  margin="dense"
                  name="password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  label="First name"
                  value={formData.firstname}
                  margin="dense"
                  name="firstname"
                  variant="outlined"
                  required
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Last name"
                  value={formData.lastname}
                  margin="dense"
                  name="lastname"
                  variant="outlined"
                  required
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Mobile number"
                  value={formData.mobile}
                  margin="dense"
                  name="mobile"
                  type="phone"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl className={classes.formControl} fullWidth required>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    name="role"
                    value={formData.role || ''}
                    onChange={handleChange}
                    displayEmpty
                    className={classes.selectEmpty}
                  >
                    {store.user.canCreateUserRoles(currentUser!.role).map((role) => (
                      <MenuItem key={role} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              disabled={addApi.requesting}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </CardActions>
        </form>
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    overflowX: 'auto',
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default observer(CreateUserPage);
