import {observable} from 'mobx';
import SupplyAddStore from './supply.add.store';
import listStore from './supply.list.store';
import SupplyUpdateStore from './supply.update.store';

export class SupplyStore {
  @observable public list = listStore;

  @observable public update: SupplyUpdateStore;
  @observable public add: SupplyAddStore;

  constructor() {
    this.update = new SupplyUpdateStore(this);
    this.add = new SupplyAddStore(this);
  }
}

const supplyStore = new SupplyStore();
export default supplyStore;