import { Paper, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Column, EditComponentProps } from 'material-table';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { MaterialTable } from '../../../components';
import { StoreContext } from '../../../mobx';

type StockItemVatType = 'included' | 'excluded' | 'none';
export interface SupplyItem {
  supply: string;
  amount: string;
  unit: string;
  price: string;
  isNonVat: boolean;
  vatPrice: string;
  nonVatPrice: string;
}

interface Props {
  isVatIncluded: boolean;
  onItemsChange: (items: SupplyItem[], totalPrice: number) => void;
  clear?(fn: () => void): void;
}

function StockBuyTable(props: Props) {
  const store = useContext(StoreContext);
  const classes = useStyles();

  const [items, setItems] = useState<SupplyItem[]>([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalVat, setTotalVat] = useState(0);

  useEffect(() => {
    const newItems = items.map(adjustSupplyItemWithVat);
    setItems(newItems);
  }, [props.isVatIncluded]);

  useEffect(() => {
    const price = items.reduce<number>((sum, item) => {
      return sum + parseFloat(item.price);
    }, 0);

    const sumVat = items.reduce<number>((sum, item) => {
      let vat = 0;
      if (!props.isVatIncluded) {
        vat = parseFloat(item.vatPrice);
      }
      return sum + vat;
    }, 0);

    const _totalPrice = parseFloat(price.toFixed(2));
    const _totalVat = parseFloat(sumVat.toFixed(2));
    setTotalPrice(_totalPrice);
    setTotalVat(_totalVat);
    props.onItemsChange(items, _totalPrice);
  }, [items]);

  useEffect(() => {
    if (props.clear) {
      props.clear(clearData);
    }
  }, [props.clear]);

  const supplies = store.supply.list.data || [];
  const filteredSupplies = supplies.slice().filter((item) => item.type !== 'mixedIngredient') || [];
  const columns: Array<Column<SupplyItem>> = [
    {
      title: 'วัตถุดิบ',
      field: 'supply',
      render: renderSupply,
      editComponent: renderSupplyInput,
    },
    { title: 'ราคา', field: 'price', type: 'numeric', hidden: !props.isVatIncluded },
    { title: 'ราคา', field: 'nonVatPrice', type: 'numeric', hidden: props.isVatIncluded },
    { title: 'จำนวน', field: 'amount', type: 'numeric' },
    { title: 'หน่วย', field: 'unit', render: renderUnit, editComponent: renderEditableUnit },
    { title: 'สินค้าไม่คิด Vat', field: 'isNonVat', type: 'boolean', hidden: props.isVatIncluded },
    {
      title: 'VAT',
      field: 'vatPrice',
      type: 'numeric',
      editable: 'never',
      hidden: props.isVatIncluded,
    },
  ];

  const clearData = () => {
    setItems([]);
  };

  const adjustSupplyItemWithVat = (newData: SupplyItem): SupplyItem => {
    if (props.isVatIncluded) {
      return {
        supply: newData.supply,
        amount: newData.amount,
        unit: newData.unit,
        price: newData.price,
        isNonVat: false,
        vatPrice: '0',
        nonVatPrice: newData.price,
      };
    }

    let vat = 0;
    const nonVatPrice = parseFloat(newData.nonVatPrice);
    if (!newData.isNonVat) {
      vat = nonVatPrice * 0.07;
    }

    return {
      supply: newData.supply,
      amount: newData.amount,
      unit: newData.unit,
      price: (nonVatPrice + vat).toFixed(2),
      isNonVat: newData.isNonVat,
      vatPrice: vat.toFixed(2),
      nonVatPrice: newData.nonVatPrice,
    };
    // return { ...newData, vatPrice: vat.toFixed(2), totalPrice: price + vat };
  };

  // handle event methods

  const handleRowAdd = async (newData: SupplyItem) => {
    const adjuestedItem = adjustSupplyItemWithVat(newData);
    setItems([...items, adjuestedItem]);
  };

  const handleRowUpdate = async (newData: SupplyItem, oldData?: SupplyItem) => {
    const newItems = [...items];
    const adjuestedItem = adjustSupplyItemWithVat(newData);
    if (oldData) {
      const prevIndex = items.findIndex((v) => v.supply === oldData.supply);
      if (prevIndex >= 0) {
        newItems[prevIndex] = adjuestedItem;
      } else {
        newItems.push(adjuestedItem);
      }
    } else {
      newItems.push(adjuestedItem);
    }

    setItems(newItems);
  };

  const handleRowDelete = async (oldData: SupplyItem) => {
    const newItems = [...items];
    const index = newItems.findIndex((item) => oldData.supply === item.supply);
    if (index >= 0) {
      newItems.splice(index, 1);
    }
    setItems(newItems);
  };

  // render methods
  function renderSupplyInput(inputProps: EditComponentProps<SupplyItem>) {
    const item = filteredSupplies.find((v) => inputProps?.rowData?.supply === v._id);
    const name =
      item != null ? (item.code != null ? `${item.code ?? ''}: ${item.name}` : item.name) : '';

    return (
      <Autocomplete
        id="material-input-suggest"
        options={filteredSupplies}
        getOptionLabel={(option) =>
          option.code != null ? `${option.code}: ${option.name}` : option.name
        }
        renderInput={(params) => <TextField {...params} placeholder={name} margin="normal" />}
        onChange={(event, value) =>
          inputProps.onChange(typeof value === 'string' ? value : value?._id)
        }
        // filterOptions={(options, state) => {
        //   const filteredItems = filteredSupplies.filter((v) => {
        //     const input = state.inputValue.trim().toLowerCase();
        //     const matched =
        //       v.name.toLowerCase().indexOf(input) >= 0 ||
        //       (v.code != null && v.code.toLowerCase().indexOf(input) >= 0);
        //     if (matched) {
        //       console.log(v.code, v.name);
        //     }
        //     return matched;
        //   });
        //   console.log(state.inputValue);

        //   return filteredItems;
        // }}
      />
    );
  }

  function renderSupply(rowData?: SupplyItem) {
    const item = filteredSupplies.find((v) => rowData?.supply === v._id);
    const name =
      item != null ? (item.code != null ? `${item.code ?? ''}: ${item.name}` : item.name) : '';
    return (
      <Typography variant="caption" align="left">
        {name}
      </Typography>
    );
  }

  function renderUnit(rowData?: SupplyItem) {
    let unit = '-';
    if (rowData) {
      const data = supplies.find((value) => rowData.supply === value._id);
      unit = (data && data.unit) || '-';
    }
    return (
      <Typography variant="caption" align="left">
        {unit}
      </Typography>
    );
  }

  function renderEditableUnit(editProps: EditComponentProps<SupplyItem>) {
    const { rowData } = editProps;
    return renderUnit(rowData);
  }

  return (
    <Paper className={classes.paper}>
      <MaterialTable
        title={`รายการสินค้าที่ซื้อ รวม ${totalPrice} บาท${
          props.isVatIncluded ? '' : ' (vat ' + totalVat + ' บาท)'
        }`}
        columns={columns}
        data={items}
        editable={{
          onRowAdd: handleRowAdd,
          onRowUpdate: handleRowUpdate,
          onRowDelete: handleRowDelete,
        }}
        options={{
          search: false,
          paging: false,
          showEmptyDataSourceMessage: false,
          actionsColumnIndex: props.isVatIncluded ? 4 : 6,
        }}
      />
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  input: {
    minWidth: 200,
    margin: theme.spacing(1),
  },
  menu: {
    minWidth: 200,
  },
  table: {
    minWidth: 650,
  },
  button: {
    marginTop: theme.spacing(2),
    width: 200,
  },
  padding: {
    padding: theme.spacing(2),
  },
}));

export default observer(StockBuyTable);
