import axios from 'axios';
import { action } from 'mobx';

import BaseAPIStore, { IAPIStore } from '../api.store';

export class BranchTransferListStore extends BaseAPIStore<ITransferSupply[]> implements IAPIStore {
  constructor() {
    super();
    this.data = [];
  }

  @action
  public async request(id: string) {
    const url = this.apiUrl + `/branches/${id}/transfers`;
    this.error = undefined;
    this.requesting = true;
    try {
      const response = await axios.get(url, this.authorizeConfigs());
      const responseData = response.data;
      if (responseData.data) {
        this.data = responseData.data;
      } else {
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.error = error;
    }

    this.requesting = false;
  }
}

const transferListStore = new BranchTransferListStore();
export default transferListStore;
