import { makeStyles, MenuItem, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../mobx';

interface Props {
  initBranch: string;
  id: string;
  name: string;
  label: string;
  noneOptionTitle?: string;
  classname?: string;
  onSelect?: (branch: string) => void;
}

function BranchPicker(props: Props) {
  const store = useContext(StoreContext);
  const [branch, setBranch] = useState<string>(props.initBranch);

  useEffect(() => {
    store.branch.list.request();
  }, [store.branch.list]);

  useEffect(() => {
    if (props.onSelect) {
      props.onSelect(branch);
    }
  }, [branch]);

  const branches = store.branch.list.data || [];

  const classes = useStyles();

  return (
    <TextField
      id={props.id}
      name={props.name}
      label={props.label}
      value={branch}
      defaultValue={''}
      select
      className={clsx(classes.textField, classes.margin, props.classname)}
      onChange={(e) => setBranch(e.target.value)}
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
      }}
    >
      {props.noneOptionTitle !== undefined ? (
        <MenuItem value="">
          <em>{props.noneOptionTitle}</em>
        </MenuItem>
      ) : undefined}
      {branches.map((item) => (
        <MenuItem key={item._id} value={item._id}>
          {item.name}
        </MenuItem>
      ))}
    </TextField>
  );
}

BranchPicker.defaultProps = {
  initBranch: '',
  id: 'branch',
  name: 'branch',
  label: 'สาขา',
};

const useStyles = makeStyles((theme) => ({
  textField: {
    minWidth: 200,
  },
  menu: {
    minWidth: 200,
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

export default observer(BranchPicker);
