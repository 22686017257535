import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';

import {
  FormControl,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Select,
  Snackbar,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { observer } from 'mobx-react';
import SnackBarContent from '../../../../components/SnackBarContent';
import { StoreContext } from '../../../../mobx';

interface Props {
  open: boolean;
  supply: ISupply;
  branch: IBranch;
  amount: number;
  onClose: () => void;
  onSubmitSuccess: () => void;
}

const NOTE_OTHER = 'NOTE_OTHER';
const NOTE_REASONS = [
  { label: 'ตัด Stock', value: 'ตัด Stock' },
  { label: 'ของหาย', value: 'ของหาย' },
  { label: 'หมดอายุ', value: 'หมดอายุ' },
  { label: 'ชั่ง/ตวง/วัด/นับ ผิดพลาด', value: 'ชั่ง/ตวง/วัด/นับ ผิดพลาด' },
  { label: 'ของหก ของหล่น', value: 'ของหก ของหล่น' },
  { label: 'เสียหาย', value: 'เสียหาย' },
  { label: 'อื่นๆ (ระบุ)', value: NOTE_OTHER },
];

function AdjustDialog(props: Props) {
  const store = useContext(StoreContext);
  const adjustStore = store.stock.adjustStore;
  const classes = useStyles();

  const [adjustAmount, setAdjustAmount] = useState(props.amount);
  const [note, setNote] = useState('');
  const [noteError, setNoteError] = useState<string>();
  const [noteOther, setNoteOther] = useState('');
  const [noteOtherError, setNoteOtherError] = useState<string>();
  const [notifiedData, setNotifiedData] = useState<INotifiedData>({
    open: false,
    variant: 'success',
    message: '',
  });

  useEffect(() => {
    setAdjustAmount(props.amount);
    setNote('');
  }, [props.amount]);

  // Handle event methods
  const handleSnackbarClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotifiedData((oldValue) => ({
      ...oldValue,
      open: false,
    }));
  };

  const handleClose = () => {
    adjustStore.clear();
    props.onClose();
  };

  const handleSubmit = async () => {
    const diffNumber = adjustAmount - props.amount;
    if (diffNumber !== 0) {
      if (note === '') {
        setNoteError('กรุณาเลือกเหตุผล');
        return;
      }

      if (note === NOTE_OTHER && (noteOther == null || noteOther === '')) {
        setNoteOtherError('กรุณาระบุเหตุผล');
        return;
      }

      const noteValue = note === NOTE_OTHER ? noteOther : note;

      await adjustStore.request(props.supply._id, diffNumber, props.branch._id, noteValue);
      if (adjustStore.error) {
        setNotifiedData({
          open: true,
          variant: 'error',
          message: adjustStore.error.message,
        });
      } else {
        setNotifiedData({
          open: true,
          variant: 'success',
          message: 'แก้ไขรายการเรียบร้อย',
        });
        setTimeout(() => {
          adjustStore.clear();
          props.onSubmitSuccess();
        }, 500);
      }
    }
  };

  return (
    <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
      {adjustStore.requesting && <LinearProgress />}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notifiedData.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <SnackBarContent
          variant={notifiedData.variant}
          message={notifiedData.message}
          onClose={handleSnackbarClose}
        />
      </Snackbar>
      <DialogTitle id="form-dialog-title">แก้ไข Stock</DialogTitle>
      <DialogContent>
        <DialogContentText color="textPrimary">
          {`Supply: ${props.supply.name}`}
          <br />
          {`จำนวน: ${props.amount} ${props.supply.unit}`}
          <br />
          {`สาขา: ${props.branch.name}`}
        </DialogContentText>
        <TextField
          value={adjustAmount}
          autoFocus
          margin="dense"
          id="adjustAmount"
          label="จำนวนที่เหลือจริง"
          type="number"
          fullWidth
          onChange={(e) => setAdjustAmount(parseFloat(e.target.value))}
        />
        <FormControl fullWidth required>
          <InputLabel id="demo-simple-select-label">เหตุผล</InputLabel>
          <Select
            name="note"
            value={note || ''}
            onChange={(e) => {
              if (typeof e.target.value !== 'string') {
                return;
              }
              if (e.target.value.length > 0) {
                setNoteError(undefined);
              }
              setNote(e.target.value);
            }}
            error={noteError != null}
            displayEmpty
            className={classes.selectEmpty}
          >
            {NOTE_REASONS.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {note === NOTE_OTHER && (
          <TextField
            value={noteOther}
            margin="dense"
            id="noteOther"
            label="โปรดระบุเหตุผล"
            type="text"
            fullWidth
            error={noteOtherError != null}
            onChange={(e) => {
              if (e.target.value.length > 0) {
                setNoteOtherError(undefined);
              }
              setNoteOther(e.target.value);
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={adjustStore.requesting}>
          ยกเลิก
        </Button>
        <Button onClick={handleSubmit} color="secondary" disabled={adjustStore.requesting}>
          ยืนยันการแก้ไข
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default observer(AdjustDialog);
