import React from 'react';
import BackofficeLayout from '../layouts/Backoffice';
import LoginPage from '../pages/Auth/LoginPage';
import BranchPage from '../pages/BranchPage/BranchPage';
import DashboardPage from '../pages/DashboardPage';
import {
  BookingOrderListPage,
  BookingOrderUpsertPage,
  OrderProductsReportPage,
} from '../pages/Order';
import SuppliesUsagePlanPage from '../pages/Plans/SuppliesUsagePlanPage';
import ProductListPage from '../pages/Product/ProductListPage/ProductListPage';
import { ProductRecipesCreatePage, ProductRecipesListPage } from '../pages/ProductRecipesPage';
import { RecipesCreatePage, RecipesListPage } from '../pages/RecipesPage';
import { AdjustReportPage } from '../pages/Reports/AdjustReportPage';
import { UnpaidBillingPage } from '../pages/Reports/BillingPage';
import { ExpensesPage } from '../pages/Reports/ExpensesPage/ExpensesPage';
import StockDailyReportPage from '../pages/Reports/StockDailyReportPage';
import SalesReportPage from '../pages/Reports/StockDailyReportPage/SalesReportPage';
import SalesReportDateDetailPage from '../pages/Reports/StockDailyReportPage/SalesReportPage/SalesReportDateDetailPage';
import SalesReportListByDatePage from '../pages/Reports/StockDailyReportPage/SalesReportPage/SalesReportListByDatePage';
import { TransferReportPage } from '../pages/Reports/TransferReportPage';
import SalesReportImportScreen from '../pages/Settings/Import/SalesReportImportScreen';
import StockActionPage, { StockBuyEditPage } from '../pages/Stock/StockActionPage';
import StockMixPage from '../pages/Stock/StockMixPage/StockMixPage';
import StockReceivePage from '../pages/Stock/StockReceivePage/StockReceivePage';
import StockTransferPage from '../pages/Stock/StockTransferPage';
import SupplyPage from '../pages/SupplyPage';
import CreateUserPage from '../pages/User/CreateUserPage';
import UsersPage from '../pages/User/UsersPage';
import routeName from './routeName';

export interface IRouteItem {
  path: string;
  component: React.ElementType;
  layout: React.ElementType;
  protect: boolean;
  routes?: [IRouteItem];
  props?: {};
}

const createRouteItem = (
  path: string,
  component: React.ElementType,
  layout: React.ElementType = React.Fragment,
  protect: boolean = true,
  routes?: [IRouteItem],
  props?: {}
): IRouteItem => {
  return { path, component, layout, protect, routes, props };
};

const routesItems: IRouteItem[] = [
  createRouteItem(routeName.auth.login, LoginPage, undefined, false),
  createRouteItem(routeName.backoffice.dashboard, DashboardPage, BackofficeLayout),

  createRouteItem(routeName.backoffice.user.new, CreateUserPage, BackofficeLayout),
  createRouteItem(routeName.backoffice.user.edit, CreateUserPage, BackofficeLayout),
  createRouteItem(routeName.backoffice.user.list, UsersPage, BackofficeLayout),

  createRouteItem(routeName.backoffice.supplies, SupplyPage, BackofficeLayout),
  createRouteItem(routeName.backoffice.branches, BranchPage, BackofficeLayout),

  createRouteItem(routeName.backoffice.recipes.new, RecipesCreatePage, BackofficeLayout),
  createRouteItem(routeName.backoffice.recipes.update, RecipesCreatePage, BackofficeLayout),
  createRouteItem(routeName.backoffice.recipes.list, RecipesListPage, BackofficeLayout),

  createRouteItem(routeName.backoffice.stock.buyEdit, StockBuyEditPage, BackofficeLayout),
  createRouteItem(routeName.backoffice.stock.buy, StockActionPage, BackofficeLayout),
  createRouteItem(routeName.backoffice.stock.mix, StockMixPage, BackofficeLayout),
  createRouteItem(routeName.backoffice.stock.transfer, StockTransferPage, BackofficeLayout),
  createRouteItem(routeName.backoffice.stock.receiveList, StockReceivePage, BackofficeLayout),

  createRouteItem(
    routeName.backoffice.reports.stockDailyReport,
    StockDailyReportPage,
    BackofficeLayout
  ),
  createRouteItem(
    routeName.backoffice.reports.stockRangeReport,
    StockDailyReportPage,
    BackofficeLayout,
    true,
    undefined,
    { isRange: true }
  ),
  createRouteItem(
    routeName.backoffice.reports.productSalesReportList,
    SalesReportListByDatePage,
    BackofficeLayout
  ),
  createRouteItem(
    routeName.backoffice.reports.productSalesReportDateDetail,
    SalesReportDateDetailPage,
    BackofficeLayout
  ),
  createRouteItem(
    routeName.backoffice.reports.productSalesReport,
    SalesReportPage,
    BackofficeLayout
  ),
  createRouteItem(routeName.backoffice.reports.adjustReport, AdjustReportPage, BackofficeLayout),
  createRouteItem(
    routeName.backoffice.reports.transferReport,
    TransferReportPage,
    BackofficeLayout
  ),

  createRouteItem(
    routeName.backoffice.productRecipes.new,
    ProductRecipesCreatePage,
    BackofficeLayout
  ),
  createRouteItem(
    routeName.backoffice.productRecipes.update,
    ProductRecipesCreatePage,
    BackofficeLayout
  ),
  createRouteItem(
    routeName.backoffice.productRecipes.list,
    ProductRecipesListPage,
    BackofficeLayout
  ),

  createRouteItem(routeName.backoffice.product.list, ProductListPage, BackofficeLayout),

  // Report

  createRouteItem(routeName.backoffice.reports.unpaidBilling, UnpaidBillingPage, BackofficeLayout),
  createRouteItem(routeName.backoffice.reports.expensesReport, ExpensesPage, BackofficeLayout),

  // Plans
  createRouteItem(
    routeName.backoffice.plans.suppliesUsagePlan,
    SuppliesUsagePlanPage,
    BackofficeLayout
  ),

  // Orders
  createRouteItem(routeName.backoffice.orders.new, BookingOrderUpsertPage, BackofficeLayout),
  createRouteItem(
    routeName.backoffice.orders.groupProducts,
    OrderProductsReportPage,
    BackofficeLayout
  ),
  createRouteItem(routeName.backoffice.orders.update, BookingOrderUpsertPage, BackofficeLayout),
  createRouteItem(routeName.backoffice.orders.list, BookingOrderListPage, BackofficeLayout),
  // Settings
  createRouteItem(
    routeName.backoffice.settings.salesReportImport,
    SalesReportImportScreen,
    BackofficeLayout
  ),
];

export default routesItems;
