import axios from 'axios';
import { action } from 'mobx';

import BaseAPIStore, { IAPIStore } from '../api.store';
import { ProductStore } from './product.store';

class ProductAddStore extends BaseAPIStore<IProduct> implements IAPIStore {
  private productStore: ProductStore;

  constructor(productStore: ProductStore) {
    super();
    this.productStore = productStore;
  }

  @action
  public async request(newData: IProduct) {
    const url = this.apiUrl + '/products';
    this.error = undefined;
    this.requesting = true;
    try {
      const response = await axios.post(url, newData, this.authorizeConfigs());
      const responseData = response.data;

      if (responseData.data) {
        this.data = responseData.data;

        if (this.productStore.list.data) {
          this.productStore.list.data = [...this.productStore.list.data, responseData.data];
        }
      } else {
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.error = error;
    }

    this.requesting = false;
  }
}

export default ProductAddStore;
