import axios from 'axios';
import { action } from 'mobx';

import BaseAPIStore, { IAPIStore } from '../api.store';
import { StockStore } from './stockStore';

class StockBuyActionStore extends BaseAPIStore<IStockActionLog> implements IAPIStore {
  private stockStore: StockStore;

  constructor(stockStore: StockStore) {
    super();
    this.stockStore = stockStore;
  }

  @action
  public async request(
    date: string,
    vendor: string,
    totalPrice: number,
    branch: string,
    note: string,
    supplies: Array<{ supply: string; amount: string; unit: string; price: string }>,
    deliveryFee?: number,
    discount?: number,
    paymentStatus?: string,
    no?: string,
    deliveryDate?: string,
    paymentDueDate?: string
  ) {
    const url = this.apiUrl + '/stocks/action/buy';
    this.error = undefined;
    this.requesting = true;
    try {
      const params = {
        date,
        vendor,
        totalPrice,
        branch,
        note,
        supplies,
        deliveryFee,
        discount,
        paymentStatus,
        no,
        deliveryDate,
        paymentDueDate,
      };

      const response = await axios.post(url, params, this.authorizeConfigs());
      const responseData = response.data;

      if (responseData.data) {
        this.data = responseData.data;
      } else {
        this.data = undefined;
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      console.error(error);
      this.data = undefined;
      this.error = error instanceof Error ? error : new Error('Something went wrong.');
    }

    this.requesting = false;
  }
}

export default StockBuyActionStore;
