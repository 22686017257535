import React, { useContext, useEffect, useState } from 'react';

import {
  Button,
  Chip,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { observer } from 'mobx-react';
import ConfirmDialog from '../../../components/Dialog/ConfirmDialog';
import BranchPicker from '../../../components/Input/BranchPicker';
import { StoreContext } from '../../../mobx';
import { dateISOFomatter, supplyAmountFormat } from '../../../utils/formatter';
import { RoleStore } from '../../../mobx/stores/roleStore/roleStore';

interface IConfirmData {
  open: boolean;
  title: string;
  description?: string;
  data?: ITransferSupply;
}

function StockReceivePage() {
  const store = useContext(StoreContext);
  const transferList = store.branch.transferListStore;
  const classes = useStyles();

  const [branch, setBranch] = useState(store.auth.loggedInBranchId || '');
  const [confirmData, setConfirmData] = useState<IConfirmData>({
    open: false,
    title: 'ยืนยันรับวัตถุดิบ',
  });

  const [confirmCancel, setConfirmCancel] = useState<IConfirmData>({
    open: false,
    title: 'ยืนยันการยกเลิก',
  });

  const transferData = transferList.data || [];

  useEffect(() => {
    if (branch !== '') {
      transferList.request(branch);
    }
  }, [branch]);

  // handle event methods
  const handleClickConfirmButton = (tranferSupply: ITransferSupply) => {
    setConfirmData({
      ...confirmData,
      open: true,
      data: tranferSupply,
    });
  };

  const handleClickConfirmReceive = async () => {
    const { data } = confirmData;
    if (data == null) {
      return;
    }
    try {
      await store.stock.receiveStore.request(data._id, branch, data.supplies);
      setConfirmData({ ...confirmData, open: false });
      await transferList.request(branch);
    } catch (error) {
      setConfirmData({ ...confirmData, open: false });
      alert(error.message);
    }
  };

  const handleClickCancelButton = (tranferSupply: ITransferSupply) => {
    setConfirmCancel({
      ...confirmCancel,
      open: true,
      data: tranferSupply,
    });
  };

  const handleClickConfirmCancel = async () => {
    const { data } = confirmCancel;
    if (data == null) {
      return;
    }
    try {
      const res = await store.stock.cancelTransferStore.request(data._id);
      setConfirmCancel({ ...confirmData, open: false });
      if (store.stock.cancelTransferStore.error) {
        throw store.stock.cancelTransferStore.error;
      }
      await transferList.request(branch);
    } catch (error) {
      setConfirmCancel({ ...confirmCancel, open: false });
      alert(error.message);
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" align="left">
        รับวัตถุดิบ
      </Typography>
      <ConfirmDialog
        open={confirmData.open}
        title={confirmData.title}
        onClose={() => setConfirmData({ ...confirmData, open: false })}
        onCancel={() => setConfirmData({ ...confirmData, open: false })}
        onConfirm={handleClickConfirmReceive}
      />
      <ConfirmDialog
        open={confirmCancel.open}
        title={confirmCancel.title}
        onClose={() => setConfirmCancel({ ...confirmCancel, open: false })}
        onCancel={() => setConfirmCancel({ ...confirmCancel, open: false })}
        onConfirm={handleClickConfirmCancel}
      />
      <Paper className={classes.paper}>
        <Grid container justify="flex-start">
          <Grid>
            <BranchPicker initBranch={branch} onSelect={setBranch} />
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.paper}>
        <LinearProgress hidden={!transferList.requesting} />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>วัตถุดิบ</TableCell>
              <TableCell>ผู้ส่ง</TableCell>
              <TableCell>วันที่</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transferData.map((row, index) => (
              <TableRow key={row._id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  {row.supplies
                    .filter((v) => v.supply != null)
                    .map((item, idx) => (
                      <Chip
                        key={item.supply?._id ?? idx.toString()}
                        label={`${item.supply?.name} ${supplyAmountFormat(
                          item.amount,
                          item.supply.unit
                        )}`}
                        className={classes.chip}
                      />
                    ))}
                </TableCell>
                <TableCell>{row.actor.firstname}</TableCell>
                <TableCell>{(row.createdAt && dateISOFomatter(row.createdAt)) || '-'}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleClickConfirmButton(row)}>
                    <Check color="action" />
                  </IconButton>
                  {!!RoleStore.canCancelTransfer(row, store.auth.currentUser) && (
                    <Button
                      variant="contained"
                      className={classes.cancelButton}
                      onClick={() => handleClickCancelButton(row)}
                    >
                      ยกเลิก
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 500,
  },
  margin: {
    margin: theme.spacing(1),
  },
  chip: {
    marginRight: theme.spacing(1),
  },
  circleButton: {
    borderRadius: 99,
  },
  cancelButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

export default observer(StockReceivePage);
