import React, { useContext, useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { Grid, LinearProgress, makeStyles, Paper, TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { endOfMonth, startOfMonth } from 'date-fns';
import { observer } from 'mobx-react';
import BranchPicker from '../../../../components/Input/BranchPicker';
import { StoreContext } from '../../../../mobx';
import { SupplyInput } from '../../../../components/Input/SupplyInput';

interface Props {
  showSupply?: boolean;
  showPaymentDueDate?: boolean;
  onChange: (
    start: Date,
    end: Date,
    branch: string,
    paymentDueDate?: Date,
    supply?: string
  ) => void;
}

function ExpensesSearchForm(props: Props) {
  const store = useContext(StoreContext);

  // const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [fromDate, setFromDate] = useState<Date | null>(startOfMonth(new Date()));
  const [toDate, setToDate] = useState<Date | null>(endOfMonth(new Date()));
  const [branch, setBranch] = useState<string>('');
  const [paymentDueDate, setPaymentDueDate] = useState<Date | null>(null);
  const [supply, setSupply] = useState<string>();

  const classes = useStyles();

  useEffect(() => {
    if (fromDate == null || toDate == null) {
      return;
    }

    props.onChange(fromDate, toDate, branch, paymentDueDate, supply);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, branch, paymentDueDate, supply]);

  const handleSelectSupply = (supplyId?: string) => {
    setSupply(supplyId);
  };

  return (
    <Paper className={classes.paper}>
      <LinearProgress hidden={!store.branch.list.requesting} />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-around">
          <div>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label={'From date'}
              value={fromDate}
              onChange={setFromDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="To date"
              value={toDate}
              className={classes.toDateContainer}
              onChange={setToDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            {!!props.showPaymentDueDate && (
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="วันที่กำหนดชำระ"
                value={paymentDueDate}
                className={classes.toDateContainer}
                onChange={setPaymentDueDate}
                KeyboardButtonProps={{
                  'aria-label': 'change payment due date',
                }}
              />
            )}
          </div>
          {!!props.showSupply && <SupplyInput onChange={handleSelectSupply} />}
          <BranchPicker
            initBranch=""
            noneOptionTitle="ทั้งหมด"
            onSelect={(value) => setBranch(value)}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </Paper>
  );
}

ExpensesSearchForm.defaultProps = {
  isRange: false,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  toDateContainer: {
    marginLeft: theme.spacing(2),
  },
  input: {
    marginTop: theme.spacing(1.8),
  },
}));

export default observer(ExpensesSearchForm);
