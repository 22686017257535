import {
  LinearProgress,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { SyntheticEvent, useContext, useEffect, useRef, useState } from 'react';
import ConfirmDialog from '../../../components/Dialog/ConfirmDialog';
import SnackBarContent from '../../../components/SnackBarContent';
import { StoreContext } from '../../../mobx';
import { fixFloat } from '../../../shared/helper';
import StockBuyForm, { BuyFormData } from './StockBuyForm';
import { useHistory, useLocation } from 'react-router-dom';
import { useFetchApi } from '../../../utils/services/api/useFetchApi';
import { currencyString, supplyAmountFormat } from '../../../utils/formatter';
import { requestPutApi } from '../../../utils/services/api/requestApi';

function StockBuyEditPage() {
  const store = useContext(StoreContext);
  const location = useLocation<{ billingId: string }>();
  const history = useHistory();
  const classes = useStyles();
  const billingId = location.state?.billingId;

  const [initialData, setInitialData] = useState<BuyFormData>();
  const [buyData, setBuyData] = useState<BuyFormData>();
  const [canSubmit, setCanSubmit] = useState(false);
  const [notifiedData, setNotifiedData] = useState<INotifiedData>({
    open: false,
    variant: 'success',
    message: '',
  });
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);

  const clearFormViewFn = useRef<() => void>(() => null);

  const { data: billing, isValidating } = useFetchApi<IBilling>('/billings/:id', {
    urlParams: { id: billingId },
    notReady: !billingId,
    onSuccess: (data) => {
      const isVatIncluded = data.stockLogs.some((log) => !log.isNonVat);
      const newData: BuyFormData = {
        ...data,
        no: data.no ?? '',
        billVatType: isVatIncluded ? 'VAT_INCLUDED' : 'VAT_EXCLUDED',
        branch: data.branch?._id ?? '',
      };
      setInitialData(newData);
      setBuyData(newData);
    },
  });

  const suppliesTotalPrice =
    billing?.stockLogs?.reduce((acc, log) => acc + (log.price || 0), 0) ?? 0;

  useEffect(() => {
    store.supply.list.request();
    store.branch.list.request();
  }, [store.supply.list]);

  useEffect(() => {
    if (buyData === undefined) {
      setCanSubmit(false);
      return;
    }

    const totalPrice =
      Number(suppliesTotalPrice) + Number(buyData.deliveryFee ?? 0) - Number(buyData.discount ?? 0);

    const submitable =
      buyData.date !== '' &&
      buyData.vendor !== '' &&
      buyData.branch !== '' &&
      typeof buyData.totalPrice === 'number' &&
      buyData.totalPrice > 0 &&
      fixFloat(buyData.totalPrice) === fixFloat(totalPrice);

    setCanSubmit(submitable);
  }, [buyData, suppliesTotalPrice]);

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotifiedData((oldValue) => ({
      ...oldValue,
      open: false,
    }));
  };

  const handleSubmit = async () => {
    setOpenConfirmAlert(false);
    if (buyData === undefined || !canSubmit) {
      return;
    }

    const params = {
      ...buyData,
    };

    try {
      await requestPutApi<IBilling>('/billings/:id', params, { id: billingId });
      setNotifiedData({
        open: true,
        variant: 'success',
        message: 'แก้ไขรายการเรียบร้อย',
      });
    } catch (error) {
      setNotifiedData({
        open: true,
        variant: 'error',
        message: error instanceof Error ? error.message : 'Something went wrong.',
      });
    }
  };

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notifiedData.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackBarContent
          variant={notifiedData.variant}
          message={notifiedData.message}
          onClose={handleClose}
        />
      </Snackbar>
      <ConfirmDialog
        open={openConfirmAlert}
        title="ยืนยันการแก้ไข"
        onClose={() => setOpenConfirmAlert(false)}
        onCancel={() => setOpenConfirmAlert(false)}
        onConfirm={handleSubmit}
      />
      <Typography variant="h4" align="left">
        แก้ไขรายการสินค้าที่ซื้อ
      </Typography>
      <LinearProgress hidden={!isValidating} />
      {!isValidating && (
        <div className={classes.paper}>
          <StockBuyForm
            initialData={initialData}
            editMode={true}
            canSubmit={canSubmit}
            onDataChange={(data) => setBuyData(data)}
            onSubmit={() => setOpenConfirmAlert(true)}
            clear={(fn) => (clearFormViewFn.current = fn)}
          />
          <Paper className={classes.tablePaper}>
            <Typography variant="h5" align="left">
              ราคารวม : {currencyString(suppliesTotalPrice)}
            </Typography>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>วัตถุดิบ</TableCell>
                  <TableCell>ราคา</TableCell>
                  <TableCell>จำนวน</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {billing.stockLogs?.map((item, index) => (
                  <TableRow key={item._id}>
                    <TableCell>{item.supply.name || '-'}</TableCell>
                    <TableCell>{currencyString(item.price)}</TableCell>
                    <TableCell>{supplyAmountFormat(item.amount, item.supply.unit)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  tablePaper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
}));

export default observer(StockBuyEditPage);
