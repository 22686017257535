import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  input: {
    marginTop: theme.spacing(1),
  },
  menu: {
    minWidth: 200,
  },
  botton: {
    marginTop: theme.spacing(2),
    width: 200
  },
  errorContainer: {
    marginTop: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main
  }
}));

export default useStyles;