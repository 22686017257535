import { Button, makeStyles, Paper, Typography } from '@material-ui/core';
import { Column } from 'material-table';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { read, utils, writeFileXLSX } from 'xlsx';
import { MaterialTable } from '../../components';
import { StoreContext } from '../../mobx';
import { RoleStore } from '../../mobx/stores/roleStore/roleStore';
import routeName from '../../routes/routeName';
import {
  BOOKING_ORDER_DELIVERY_METHODS,
  BOOKING_ORDER_PAYMENT_STATUS,
  BOOKING_ORDER_TYPE_NAMES,
} from '../../shared/constants';
import { dateISOFomatter, dateStringFormatter } from '../../utils/formatter';
import { useFetchApi } from '../../utils/services/api/useFetchApi';

export function BookingOrderListPage() {
  const history = useHistory();
  const classes = useStyles();
  const store = useContext(StoreContext);

  const { data: orders, isValidating } = useFetchApi<IBookingOrderList>('/orders');

  const [exporting, setExporting] = React.useState(false);

  const columns: Array<Column<IBookingOrder>> = [
    { title: 'ชื่อไลน์', field: 'customerLineName', filtering: false, width: '1%' },
    { title: 'ลูกค้า', field: 'customerName', filtering: false, width: '1%' },
    { title: 'เบอร์โทรศัพท์', field: 'customerMobile', filtering: false, width: '1%' },
    { title: 'วิธีจัดส่ง', field: 'deliveryMethod', lookup: BOOKING_ORDER_DELIVERY_METHODS },
    { title: 'ที่อยู่จัดส่ง', field: 'address', filtering: false },
    {
      title: 'วันที่รับของ',
      field: 'deliveredAt',
      type: 'date',
      render: renderDateInput,
    },
    {
      title: 'รายละเอียด',
      field: 'products',
      render: renderProducts,
      filtering: false,
    },
    { title: 'ยอดรวม', field: 'totalPrice', filtering: false },
    { title: 'TaxInvoice', field: 'taxInvoiceRequested', filtering: false },
    {
      title: 'สถานะการจ่ายเงิน',
      field: 'paymentStatus',
      lookup: BOOKING_ORDER_PAYMENT_STATUS,
    },
    { title: 'Status', field: 'status', lookup: BOOKING_ORDER_TYPE_NAMES },
    { title: 'Note', field: 'note', filtering: false, render: renderNote },
    { title: 'ผู้กรอกข้อมูล', field: 'createdBy.firstname', filtering: false },
  ];

  // handle events methods
  const handleRowAdd = () => {
    history.push(routeName.backoffice.orders.new);
  };

  const handleRowEdit = (event: any, data: IBookingOrder) => {
    if (!Array.isArray(data)) {
      history.push(routeName.backoffice.orders.update, {
        orderId: data._id,
        order: data,
      });
    }
  };

  const handleOnPressExport = () => {
    if (exporting) {
      return;
    }
    setExporting(true);
    const exportingData = orders?.map((order) => {
      return {
        ชื่อไลน์: order.customerLineName,
        ลูกค้า: order.customerName,
        เบอร์โทรศัพท์: order.customerMobile,
        วิธีจัดส่ง: BOOKING_ORDER_DELIVERY_METHODS[order.deliveryMethod],
        ที่อยู่จัดส่ง: order.address,
        วันที่รับของ: order.deliveredAt
          ? dateISOFomatter(order.deliveredAt, 'dd/MM/yyyy HH:mm')
          : '-',
        รายละเอียด: order.products
          .map((v) =>
            typeof v.product === 'string' ? v.product : `${v.product.name} x ${v.amount}`
          )
          .join(', '),
        ยอดรวม: order.totalPrice,
        TaxInvoice: order.taxInvoiceRequested,
        สถานะการจ่ายเงิน: BOOKING_ORDER_PAYMENT_STATUS[order.paymentStatus],
        Status: BOOKING_ORDER_TYPE_NAMES[order.status],
        Note: order.note,
        ผู้กรอกข้อมูล: order.createdBy.firstname,
      };
    });
    const ws = utils.json_to_sheet(exportingData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');
    writeFileXLSX(wb, 'susan-croissant-booking-order.xlsx');
    setExporting(false);
  };

  // render methods
  function renderDateInput(rowData: IBookingOrder) {
    return (
      <Typography variant="body2" align="left">
        {dateISOFomatter(rowData.deliveredAt, 'dd/MM/yyyy HH:mm')}
      </Typography>
    );
  }

  function renderProducts(rowData: IBookingOrder) {
    return rowData.products.map((v) => {
      if (typeof v.product === 'string') {
        return null;
      }
      return (
        <Typography key={v._id} variant="body2" align="left">
          <span className={classes.bold}>{`${v.product.name}`}</span>
          {` x ${v.amount}`}
        </Typography>
      );
    });
  }

  function renderNote(rowData: IBookingOrder) {
    return (
      <Typography variant="body2" align="left">
        {rowData.note.slice(0, 50)}
      </Typography>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h4" align="left">
          Booking Order List
        </Typography>
        <Button
          variant="contained"
          color="primary"
          disabled={exporting}
          onClick={handleOnPressExport}
        >
          {exporting ? 'Exporting...' : 'Export'}
        </Button>
      </div>
      <Paper className={classes.paper}>
        <MaterialTable
          title=""
          columns={columns}
          data={orders || []}
          isLoading={isValidating}
          actions={[
            RoleStore.canAccessPage('backoffice.orders.new', store.auth.currentUser)
              ? {
                  icon: 'add_box',
                  tooltip: 'Add Order',
                  isFreeAction: true,
                  onClick: handleRowAdd,
                }
              : null,
            {
              icon: 'edit',
              tooltip: 'Edit',
              onClick: handleRowEdit,
            },
          ]}
          options={{ actionsColumnIndex: 0, filtering: true }}
        />
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  itemDetailContainer: {
    padding: theme.spacing(2),
  },
  bold: {
    fontWeight: 500,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
