import Cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { action, observable } from 'mobx';
import AuthLoginStore from './auth.login.store';

export const COOKIE_TOKEN_KEY = '@susan/COOKIE_TOKEN_KEY';

export class AuthStore {
  public static userRoles() {
    return ['customer', 'staff', 'manager', 'admin'];
  }
  @observable public loginStore = new AuthLoginStore(this);

  @observable public token?: string;

  @observable public currentUser?: IUser;
  @observable public loggedInBranchId?: string;

  constructor() {
    this.loadUserToken();
  }

  @action
  public isAuthenticated() {
    let isAuthenticated = false;

    if (this.token !== null && this.token !== undefined && this.token !== '') {
      const decoded = jwtDecode<{ user?: IUser; branch?: string; exp: number }>(this.token);
      const now = new Date();
      isAuthenticated = decoded.exp < now.getTime();
    }

    if (!isAuthenticated) {
      this.token = undefined;
      Cookie.remove(COOKIE_TOKEN_KEY);
    }

    return isAuthenticated;
  }

  @action
  public storeUserToken(token: string) {
    this.token = token;
    Cookie.set(COOKIE_TOKEN_KEY, token, {
      expires: 2,
    });
  }

  @action
  public setCurrentUser(user?: IUser) {
    if (user && user._id && user.firstname && user.role) {
      this.currentUser = user;
    }
  }

  @action
  public loadUserToken() {
    const token = Cookie.get(COOKIE_TOKEN_KEY);
    this.token = token;
    if (token) {
      const decoded = jwtDecode<{ user?: IUser; branch?: string }>(token);
      this.setCurrentUser(decoded.user);
      this.loggedInBranchId = decoded.branch;
    }
  }

  @action
  public async logout() {
    Cookie.remove(COOKIE_TOKEN_KEY);
    this.token = undefined;
    this.currentUser = undefined;
    this.loggedInBranchId = undefined;
  }
}

const authStore = new AuthStore();
export default authStore;
