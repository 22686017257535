import {
  Box,
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../../mobx';
import { currencyString, dateFormatter, dateISOFomatter } from '../../../utils/formatter';
import { ExpensesByCategoriesView } from './components';
import ExpensesSearchForm from './components/ExpensesSearchForm';
import { useHistory } from 'react-router-dom';
import routeName from '../../../routes/routeName';
import { Edit } from '@material-ui/icons';
import { RoleStore } from '../../../mobx/stores/roleStore/roleStore';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const ExpensesPage = observer(() => {
  const history = useHistory();
  const store = useContext(StoreContext);
  const getBillingApi = store.report.getBillingReport;
  const getExpenseApi = store.report.getExpenseByCategoriesReport;

  const classes = useStyles();

  const [searchForm, setSearchForm] = useState<{
    start: Date;
    end: Date;
    branch: string;
    paymentDueDate?: Date | null;
    supply?: string;
  }>();
  const [tabIndex, setTabIndex] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  useEffect(() => {
    if (searchForm == null) {
      return;
    }
    const startStr = dateFormatter(searchForm.start);
    const endStr = dateFormatter(searchForm.end);
    const paymentDueDateStr = dateFormatter(searchForm.paymentDueDate);
    getBillingApi.request(
      'all',
      startStr,
      endStr,
      searchForm.branch,
      paymentDueDateStr,
      searchForm.supply
    );
    getExpenseApi.request('all', startStr, endStr, searchForm.branch);

    return () => {
      getBillingApi.clear();
      getExpenseApi.clear();
    };
  }, [searchForm]);

  useEffect(() => {
    setGrandTotal(() =>
      getBillingApi.data ? getBillingApi.data.reduce((sum, v) => sum + v.totalPrice, 0) : 0
    );
  }, [getBillingApi.data]);

  const handleOnSearchChange = (
    start: Date,
    end: Date,
    branch: string,
    paymentDueDate?: Date | null,
    supply?: string
  ) => {
    setSearchForm({ start, end, branch, paymentDueDate, supply });
  };

  const handleViewItem = (item: IBilling) => () => {
    //
  };
  const handleEditItem = (item: IBilling) => () => {
    history.push(routeName.backoffice.stock.buyEdit, {
      billingId: item._id,
    });
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" align="left">
        Expenses
      </Typography>
      <Box>
        <Tabs
          value={tabIndex}
          indicatorColor={'primary'}
          onChange={(e, value) => setTabIndex(value)}
        >
          <Tab label="Bills" {...a11yProps(0)} />
          <Tab label="Categories" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Paper className={classes.paper}>
        <ExpensesSearchForm
          showSupply={tabIndex === 0}
          showPaymentDueDate={tabIndex === 0}
          onChange={handleOnSearchChange}
        />
        <LinearProgress hidden={!getBillingApi.requesting} />
        <div hidden={tabIndex !== 0}>
          <h2>{`ค่าใช้จ่ายรวมทั้งสิ้น ${currencyString(grandTotal)}`}</h2>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>วันที่ซื้อ</TableCell>
                <TableCell>วันที่จัดส่ง</TableCell>
                <TableCell>วันที่กำหนดชำระ</TableCell>
                <TableCell>ราคารวม</TableCell>
                <TableCell>สาขา</TableCell>
                {!!RoleStore.canEditBilling(store.auth.currentUser) && (
                  <TableCell>Actions</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {getBillingApi.data?.map((item, index) => (
                <TableRow key={item._id}>
                  <TableCell>{item.no || '-'}</TableCell>
                  <TableCell>{dateISOFomatter(item.date)}</TableCell>
                  <TableCell>{dateISOFomatter(item.deliveryDate)}</TableCell>
                  <TableCell>{dateISOFomatter(item.paymentDueDate)}</TableCell>
                  <TableCell>{currencyString(item.totalPrice)}</TableCell>
                  <TableCell>{item.branch?.name || '-'}</TableCell>
                  {!!RoleStore.canEditBilling(store.auth.currentUser) && (
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        className={classes.margin}
                        onClick={handleEditItem(item)}
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div hidden={tabIndex !== 1}>
          <ExpensesByCategoriesView expenseCategories={getExpenseApi.data ?? []} />
        </div>
      </Paper>
    </div>
  );
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 500,
  },
  textField: {
    minWidth: 200,
  },
  menu: {
    minWidth: 200,
  },
  margin: {
    margin: theme.spacing(1),
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  inputGrid: {
    padding: theme.spacing(1),
  },
}));
