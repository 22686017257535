import { observable } from 'mobx';
import createStore from './product.recipe.create.store';
import listStore from './product.recipe.list.store';

export class RecipeStore {
  @observable
  public listStore = listStore;

  @observable
  public createStore = createStore;
}

const recipeStore = new RecipeStore();

export default recipeStore;
