import React, { useContext } from 'react';
import { Redirect } from 'react-router';
import { StoreContext } from '../mobx';
import routeName from '../routes/routeName';

function HomePage() {
  const store = useContext(StoreContext);

  if (store.auth.isAuthenticated()) {
    return <Redirect to={routeName.backoffice.dashboard} />;
  } else {
    return <Redirect to={routeName.auth.login} />;
  }
}

export default HomePage;
