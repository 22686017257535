import { SUPPLY_TYPE_NAMES } from './constants';

export function tablePageSize(itemCount: number, min: number = 5, max: number = 20) {
  return Math.min(Math.max(itemCount, min), max);
}

export function fixFloat(num: number, fractionDigits = 2) {
  if (typeof num === 'string') {
    return parseFloat(num);
  }
  return parseFloat(
    num.toLocaleString('TH-th', { minimumFractionDigits: fractionDigits }).replace(/,/g, '')
  );
}

export function numberFormat(num: number | string, fractionDigits = 2) {
  const number = typeof num === 'string' ? parseFloat(num) : num;
  return number.toLocaleString('TH-th', { minimumFractionDigits: fractionDigits });
}

export function isError(e: unknown): e is IError {
  if (e == null) {
    return false;
  } else if (e instanceof Error) {
    return true;
    // @ts-ignore
  } else if (e != null && typeof e === 'object' && Object.keys(e).includes('message')) {
    return true;
  } else {
    return false;
  }
}

export function supplyTypeName(type: string, fallback = '-') {
  const name = Object.entries(SUPPLY_TYPE_NAMES).find((v) => v[0] === type);

  return name?.[1] || fallback;
}
