import { observable } from 'mobx';
import adjustStockReportStore from './report.adjustStock.store';
import billingGetAllReportStore from './report.billing.getAll.store';
import expenseByCategoriesReportStore from './report.expense.byCategories.store';
import productSalesReportStore from './report.productSales.store';
import productSalesReportByDateStore from './report.productSalesByDate.store';
import productSalesReportDateStore from './report.productSalesDate.store';
import stockDailyReportStore from './report.stockDaily.store';

export class ReportStore {
  public stockDailyReport = stockDailyReportStore;
  public productSalesReport = productSalesReportStore;
  public productSalesByDateReport = productSalesReportByDateStore;
  public productSalesDateReport = productSalesReportDateStore;
  public adjustStockReport = adjustStockReportStore;

  public getBillingReport = billingGetAllReportStore;
  public getExpenseByCategoriesReport = expenseByCategoriesReportStore;
}

const reportStore = new ReportStore();

export default reportStore;
