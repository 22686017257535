import axios from 'axios';
import { action } from 'mobx';

import BaseAPIStore, { IAPIStore } from '../api.store';

export class StockAdjustStore extends BaseAPIStore<IStockActionLog> implements IAPIStore {
  @action
  public async request(supply: string, diffAmount: number, branch: string, note: string) {
    const url = this.apiUrl + '/stocks/action/adjust';
    this.error = undefined;
    this.requesting = true;
    try {
      const params = { supply, diffAmount, branch, note };
      const response = await axios.post(url, params, this.authorizeConfigs());
      const responseData = response.data;
      console.log(responseData);

      if (responseData.data) {
        this.data = responseData.data;
      } else {
        this.data = undefined;
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.data = undefined;
      this.error = error;
    }

    this.requesting = false;
  }
}

const storeAdjustStore = new StockAdjustStore();
export default storeAdjustStore;
