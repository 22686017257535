// @flow

import { observable } from 'mobx';
import authStore from './authStore';
import billStore from './billStore';
import branchStore from './branchStore';
import plansStore from './plansStore';
import productRecipeStore from './productRecipeStore';
import productStore from './productStore';
import recipeStore from './recipeStore';
import reportStore from './reportStore';
import settingsStore from './settingsStore';
import stockStore from './stockStore';
import supplyStore from './supplyStore';
import userStore from './userStore';

export class GlobalStore {
  @observable public auth = authStore;
  @observable public supply = supplyStore;
  @observable public branch = branchStore;
  @observable public stock = stockStore;
  @observable public recipe = recipeStore;
  @observable public report = reportStore;
  @observable public product = productStore;
  @observable public productRecipe = productRecipeStore;
  @observable public settings = settingsStore;
  @observable public user = userStore;
  @observable public plans = plansStore;
  @observable public bill = billStore;
}

const globalStore = new GlobalStore();

export default globalStore;
