import { Snackbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { SyntheticEvent, useContext, useEffect, useRef, useState } from 'react';
import ConfirmDialog from '../../../components/Dialog/ConfirmDialog';
import SnackBarContent from '../../../components/SnackBarContent';
import { StoreContext } from '../../../mobx';
import { fixFloat } from '../../../shared/helper';
import StockBuyForm, { BuyFormData, StockBuyFormHandle } from './StockBuyForm';
import StockBuyTable, { SupplyItem } from './StockBuyTable';

function StockActionPage() {
  const store = useContext(StoreContext);
  const classes = useStyles();

  const [buyData, setBuyData] = useState<BuyFormData>();
  const [supplies, setSupplies] = useState<SupplyItem[]>([]);
  const [sumPrice, setSumPrice] = useState(0); // sum of items price
  const [canSubmit, setCanSubmit] = useState(false);
  const [notifiedData, setNotifiedData] = useState<INotifiedData>({
    open: false,
    variant: 'success',
    message: '',
  });
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);

  const clearFormViewFn = useRef<() => void>(() => null);
  const clearTableFn = useRef<() => void>(() => null);

  useEffect(() => {
    store.supply.list.request();
    store.branch.list.request();
  }, [store.supply.list]);

  useEffect(() => {
    if (buyData === undefined) {
      setCanSubmit(false);
      return;
    }

    const totalPrice =
      Number(sumPrice) + Number(buyData.deliveryFee ?? 0) - Number(buyData.discount ?? 0);

    const submitable =
      buyData.date !== '' &&
      buyData.vendor !== '' &&
      buyData.branch !== '' &&
      typeof buyData.totalPrice === 'number' &&
      buyData.totalPrice > 0 &&
      supplies.length > 0 &&
      fixFloat(buyData.totalPrice) === fixFloat(totalPrice);

    setCanSubmit(submitable);
  }, [buyData, supplies, sumPrice]);

  const handleSuppliesChange = (items: SupplyItem[], price: number) => {
    setSupplies(items);
    setSumPrice(price);
  };

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotifiedData((oldValue) => ({
      ...oldValue,
      open: false,
    }));
  };

  const handleSubmit = async () => {
    setOpenConfirmAlert(false);
    if (buyData === undefined || !canSubmit) {
      return;
    }
    try {
      await store.stock.buyStore.request(
        buyData.date,
        buyData.vendor,
        buyData.totalPrice,
        buyData.branch,
        buyData.note,
        supplies,
        buyData.deliveryFee,
        buyData.discount,
        buyData.paymentStatus,
        buyData.no,
        buyData.deliveryDate,
        buyData.paymentDueDate
      );
      if (store.stock.buyStore.error) {
        setNotifiedData({
          open: true,
          variant: 'error',
          message: store.stock.buyStore.error.message,
        });
      } else {
        setNotifiedData({
          open: true,
          variant: 'success',
          message: 'เพิ่มรายการเข้าไปใน stock เรียบร้อย',
        });
        setBuyData(undefined);
        setSupplies([]);
        clearFormViewFn.current();
        clearTableFn.current();
      }
    } catch (error) {
      setNotifiedData({
        open: true,
        variant: 'error',
        message: error instanceof Error ? error.message : 'Something went wrong.',
      });
    }
  };

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notifiedData.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackBarContent
          variant={notifiedData.variant}
          message={notifiedData.message}
          onClose={handleClose}
        />
      </Snackbar>
      <ConfirmDialog
        open={openConfirmAlert}
        title="ยืนยันเพิ่มรายการซื้อ"
        onClose={() => setOpenConfirmAlert(false)}
        onCancel={() => setOpenConfirmAlert(false)}
        onConfirm={handleSubmit}
      />
      <Typography variant="h4" align="left">
        รายการสินค้าที่ซื้อ
      </Typography>
      <div className={classes.paper}>
        <StockBuyForm
          canSubmit={canSubmit}
          onDataChange={(data) => setBuyData(data)}
          onSubmit={() => setOpenConfirmAlert(true)}
          clear={(fn) => (clearFormViewFn.current = fn)}
        />
        <StockBuyTable
          isVatIncluded={buyData?.billVatType === 'VAT_INCLUDED'}
          onItemsChange={handleSuppliesChange}
          clear={(fn) => (clearTableFn.current = fn)}
        />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
}));

export default observer(StockActionPage);
