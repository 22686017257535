import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface Props {
  open: boolean;
  title: string;
  description?: string;
  confirmText: string;
  cancelText: string;
  confirmColor?: string;
  isDisabled?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onCancel: () => void;
}

export default function ConfirmDialog(props: Props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        {props.description && (
          <DialogContentText id="alert-dialog-description">{props.description}</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel} color="primary" disabled={props.isDisabled}>
          {props.cancelText}
        </Button>
        <Button
          onClick={props.onConfirm}
          color="secondary"
          style={{ color: props.confirmColor }}
          autoFocus
          disabled={props.isDisabled}
        >
          {props.confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.defaultProps = {
  open: false,
  confirmText: 'ยืนยัน',
  cancelText: 'ยกเลิก',
};
