import axios from 'axios';
import { action } from 'mobx';
import { isError } from '../../../shared/helper';

import BaseAPIStore, { IAPIStore } from '../api.store';

export class ProductSalesReportStore
  extends BaseAPIStore<IProductSalesSummary[]>
  implements IAPIStore
{
  @action
  public async request(fromDate: string, toDate?: string, branch?: string) {
    let url = this.apiUrl + `/reports/product-sales/${fromDate}/${toDate}`;

    if (branch) {
      url += '/' + branch;
    }

    this.error = undefined;
    this.requesting = true;

    try {
      const response = await axios.get(url, this.authorizeConfigs());
      const responseData = response.data;

      if (responseData.data) {
        this.data = responseData.data;
      } else {
        this.data = undefined;
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.data = undefined;
      this.error = isError(error) ? error : new Error('Something went wrong');
    }

    this.requesting = false;
  }
}

const productSalesReportStore = new ProductSalesReportStore();
export default productSalesReportStore;
