import React, { useContext, useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  FormControl,
  Grid,
  LinearProgress,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { observer } from 'mobx-react';
import BranchPicker from '../../../../components/Input/BranchPicker';
import { StoreContext } from '../../../../mobx';
import { SUPPLY_TYPE_NAMES } from '../../../../shared/constants';

interface Props {
  isRange: boolean;
  onSupplySearch: (supply?: string, supplyType?: SupplyType) => void;
  onChange: (fromDate: Date, branch: string, toDate?: Date) => void;
}

function SearchForm(props: Props) {
  const store = useContext(StoreContext);

  // const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [fromDate, setFromDate] = useState<Date | null>(new Date());
  const [toDate, setToDate] = useState<Date | null>(new Date());
  const [branch, setBranch] = useState<string>('');
  const [supply, setSupply] = useState<string>('');
  const [supplyType, setSupplyType] = useState<SupplyType | ''>('');

  const classes = useStyles();

  useEffect(() => {
    if (fromDate && !props.isRange) {
      props.onChange(fromDate, branch);
    } else if (fromDate && toDate) {
      props.onChange(fromDate, branch, toDate);
    }
  }, [fromDate, toDate, branch]);

  useEffect(() => {
    props.onSupplySearch(supply, supplyType || undefined);
  }, [supply, supplyType]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (event.target.name === 'supply') {
      setSupply(event.target.value);
    }
  };

  return (
    <Paper className={classes.paper}>
      <LinearProgress hidden={!store.branch.list.requesting} />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-around">
          <TextField
            value={supply}
            label="วัตถุดิบ"
            name="supply"
            type="text"
            className={classes.input}
            onChange={handleChange}
          />
          <FormControl className={classes.formControl}>
            <Select
              name="supplyType"
              value={supplyType}
              label="supplyType"
              placeholder="supplyType"
              displayEmpty={true}
              onChange={(e) => setSupplyType(e.target.value as SupplyType)}
            >
              <MenuItem key={'ALL'} value={''}>
                {'ALL'}
              </MenuItem>
              {Object.keys(SUPPLY_TYPE_NAMES).map((key) => (
                <MenuItem key={key} value={key}>
                  {SUPPLY_TYPE_NAMES[key]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label={props.isRange ? 'From date' : 'Date'}
              value={fromDate}
              onChange={setFromDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            {props.isRange && (
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="To date"
                value={toDate}
                className={classes.toDateContainer}
                onChange={setToDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            )}
          </div>
          <BranchPicker onSelect={(value) => setBranch(value)} />
        </Grid>
      </MuiPickersUtilsProvider>
    </Paper>
  );
}

SearchForm.defaultProps = {
  isRange: false,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  toDateContainer: {
    marginLeft: theme.spacing(2),
  },
  input: {
    marginTop: theme.spacing(1.8),
  },
  formControl: {
    marginLeft: theme.spacing(2),
    minWidth: 120,
    alignSelf: 'flex-end',
    top: -7,
  },
}));

export default observer(SearchForm);
