import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import { StoreProvider } from './mobx';
import AppRouter from './routes/Router';

const theme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#43491D',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
    },
  },
});

const App: React.FC = () => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <StoreProvider>
        <ThemeProvider theme={theme}>
          <Router>
            <div className="App">
              <AppRouter />
            </div>
          </Router>
        </ThemeProvider>
      </StoreProvider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
