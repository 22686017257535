import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { MaterialTable } from '../../components';
import { StoreContext } from '../../mobx';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
}));

function BranchPage() {
  const store = useContext(StoreContext);
  const {branch} = store;
  const classes = useStyles();

  useEffect(()=> {
    branch.list.request();
  }, [branch.list]);

  const columns = [
    {title: 'Name', field: 'name'},
    {title: 'Location', field: 'location'},
  ];

  const handRowAdd = async (newData: IBranch) => {
    await branch.add.request(newData);
  };

  const handleRowUpdate = async (newData: IBranch, oldData?: IBranch) => {
    await branch.update.request(newData._id, newData);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" align="left">
        สาขา
      </Typography>
      <Paper className={classes.paper}>
        <MaterialTable
          title="สาขา"
          columns={columns}
          data={branch.list.data || []}
          isLoading={branch.list.requesting}
          options={{actionsColumnIndex:  2}}
          editable={{
            onRowUpdate: handleRowUpdate,
            onRowAdd: handRowAdd,
          }}
        />
      </Paper>
    </div>
  );
}

export default observer(BranchPage);
