import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {observer} from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { StoreContext } from '../../../mobx';
import routeName from '../../../routes/routeName';
import useStyles from './Login.style';

function LoginPage() {
  const store = useContext(StoreContext);
  const {auth: {loginStore}} = store;

  const [formData, setFormData] = useState({
    username: '',
    password: '',
    branch: '',
  });
  const history = useHistory();
  const classes = useStyles();

  const branches = store.branch.list.data || [];

  useEffect(() => {
    store.branch.list.request();
  }, [store.branch.list]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setFormData((oldValue) => ({
      ...oldValue,
      [event.target.name]: event.target.value
    }));
  };

  const handleTapLogin = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>)=> {
    event.preventDefault();
    const {username, password, branch} = formData;

    if (username === '' || password === '') {
      return;
    }

    await loginStore.request(username, password, branch);
    if (loginStore.data) {
      history.push(routeName.backoffice.dashboard);
    }
  };

  if (store.auth.isAuthenticated()) {
    return (<Redirect to={routeName.backoffice.dashboard}/>);
  }

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={10} md={5} lg={4}>
        <Box boxShadow="2" padding={2} marginTop={5} borderRadius={5}>
          <AppBar position="static">
            <Toolbar>
              <Typography>Login</Typography>
            </Toolbar>
          </AppBar>
          {loginStore.error && (
            <Box className={classes.errorContainer}>
              <Typography variant="caption" className={classes.error}>
                {loginStore.error.message}
              </Typography>
            </Box>
          )}
          <form>
            <TextField
              value={formData.username}
              className={classes.input}
              fullWidth={true}
              label="Username"
              name="username"
              type="text"
              onChange={handleChange}
            />
            <TextField
              value={formData.password}
              className={classes.input}
              fullWidth={true}
              label="Password"
              name="password"
              type="password"
              onChange={handleChange}
            />
            <TextField
              select
              value={formData.branch}
              className={classes.input}
              fullWidth={true}
              label="สาขา"
              name="branch"
              type="text"
              onChange={handleChange}
              SelectProps={{
                MenuProps: {className: classes.menu}
              }}
            >
              {branches.map(branch => (
                <MenuItem key={branch._id} value={branch._id}>{branch.name}</MenuItem>
              ))}
            </TextField>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loginStore.requesting}
              className={classes.botton}
              onClick={handleTapLogin}>
              {loginStore.requesting ?
                <CircularProgress size={24} /> :
                'Login'
              }
            </Button>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
}

export default observer(LoginPage);
