import {
  Button,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  Snackbar,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react';
import ConfirmDialog from '../../../components/Dialog/ConfirmDialog';
import BranchPicker from '../../../components/Input/BranchPicker';
import SnackBarContent from '../../../components/SnackBarContent';
import { StoreContext } from '../../../mobx';
import EditingSuppliesTable, { ISupplyAmoutData } from '../components/EditingSuppliesTable';
import { dateFormatter } from '../../../utils/formatter';

interface IFormData {
  branch: string;
  toBranch: string;
  note: string;
}

function StockTransferPage() {
  const store = useContext(StoreContext);
  const { supply } = store;
  const { loggedInBranchId } = store.auth;

  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
  const [formData, setFormData] = useState<IFormData>({
    branch: loggedInBranchId || '',
    toBranch: '',
    note: '',
  });
  const [supplies, setSupplies] = useState<ISupplyAmoutData[]>([]);
  const [notifiedData, setNotifiedData] = useState<INotifiedData>({
    open: false,
    variant: 'success',
    message: '',
  });
  const [submitting, setSubmitting] = useState(false);

  const classes = useStyles();

  //
  useEffect(() => {
    supply.list.request();
  }, [supply.list]);

  const title = useMemo(() => {
    let titleStr: string | undefined;
    const fromBranch = store.branch.list.data.find((b) => b._id === formData.branch);
    const toBranch = store.branch.list.data.find((b) => b._id === formData.toBranch);
    if (fromBranch && toBranch) {
      const dateStr = dateFormatter(new Date());
      titleStr = `${fromBranch.name || ''}→${toBranch?.name || ''} (${dateStr})`;
    }
    return titleStr;
  }, [formData.branch, formData.toBranch, store.branch.list.data]);

  //
  const validateData = () => {
    if (formData.branch === '') {
      throw new Error('กรุณาเลือกสาขาที่ส่ง');
    } else if (formData.toBranch === '') {
      throw new Error('กรุณาเลือกสาขาที่รับ');
    } else if (formData.branch === formData.toBranch) {
      throw new Error('กรุณาเลือกสาขาที่ต่างกัน');
    } else if (supplies.length === 0) {
      throw new Error('กรุณาเลือกวัตถุดิบอย่างน้อย 1 อย่าง');
    }
  };

  // Handle event methods
  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    // TODO: Rip this statement after you can clear form data
    if (notifiedData.variant === 'success') {
      window.location.reload();
    }

    if (reason === 'clickaway') {
      return;
    }

    setNotifiedData((oldValue) => ({
      ...oldValue,
      open: false,
    }));
  };

  const handleBranchChange = (key: keyof IFormData, branch: string) => {
    setFormData({ ...formData, [key]: branch });
  };

  const handleSubmit = async () => {
    if (submitting) {
      return;
    }

    setSubmitting(true);
    try {
      validateData();
      await store.stock.transferStore.request(
        formData.branch,
        formData.toBranch,
        supplies,
        formData.note,
        title
      );
      if (store.stock.transferStore.data) {
        setNotifiedData({
          open: true,
          variant: 'success',
          message: 'Success',
        });
        // TODO: clear form data
        setFormData({
          branch: loggedInBranchId || '',
          toBranch: '',
          note: '',
        });
        setSupplies([]);
      } else {
        setNotifiedData({
          open: true,
          variant: 'error',
          message:
            (store.stock.transferStore.error && store.stock.transferStore.error.message) ||
            'Something went wrong.',
        });
      }
    } catch (error) {
      setNotifiedData({
        open: true,
        variant: 'error',
        message: error.message,
      });
    } finally {
      setSubmitting(false);
    }
    setOpenConfirmAlert(false);
  };

  return (
    <div className={classes.root}>
      <ConfirmDialog
        open={openConfirmAlert}
        title="ยืนยันการส่งวัตถุดิบ"
        onClose={() => setOpenConfirmAlert(false)}
        onCancel={() => setOpenConfirmAlert(false)}
        onConfirm={handleSubmit}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notifiedData.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackBarContent
          variant={notifiedData.variant}
          message={notifiedData.message}
          onClose={handleClose}
        />
      </Snackbar>
      <Typography variant="h4" align="left">
        StockTransferPage
      </Typography>
      <Paper className={clsx(classes.paper, classes.padding)}>
        <LinearProgress hidden={!store.stock.transferStore.requesting} />
        {!!title && (
          <Typography align="left" variant="h5" className={classes.title}>
            {title}
          </Typography>
        )}
        <Grid container spacing={1}>
          <Grid>
            <BranchPicker
              label="สาขาที่ส่ง"
              initBranch={loggedInBranchId}
              noneOptionTitle=""
              onSelect={(b) => handleBranchChange('branch', b)}
            />
          </Grid>
          <Grid>
            <BranchPicker
              label="ส่งไปยังสาขา"
              noneOptionTitle=""
              onSelect={(b) => handleBranchChange('toBranch', b)}
            />
          </Grid>
          <Grid>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={store.stock.transferStore.requesting}
              className={classes.button}
              onClick={() => setOpenConfirmAlert(true)}
            >
              Transfer
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <EditingSuppliesTable
        title="วัตถุดิบที่ส่ง"
        suppliesLookup={supply.list.data.slice() || []}
        onChange={(items) => setSupplies(items)}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  input: {
    minWidth: 200,
    margin: theme.spacing(1),
  },
  menu: {
    minWidth: 200,
  },
  table: {
    minWidth: 650,
  },
  button: {
    marginTop: theme.spacing(2),
    width: 200,
  },
  padding: {
    padding: theme.spacing(2),
  },
}));

export default observer(StockTransferPage);
