import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { useMemo } from 'react';
import { supplyTypeName } from '../../../../shared/helper';
import { currencyString } from '../../../../utils/formatter';

interface Props {
  expenseCategories: IExpenseCategory[];
}

export function ExpensesByCategoriesView(props: Props) {
  return (
    <div>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Category</TableCell>
            <TableCell>Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.expenseCategories.map((item, index) => (
            <TableRow key={item._id}>
              <TableCell>{supplyTypeName(item._id) || '-'}</TableCell>
              <TableCell>{currencyString(item.price)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
