import axios from 'axios';
import { action } from 'mobx';

import BaseAPIStore, { IAPIStore } from '../api.store';
import { SupplyStore } from './supplyStore';

class SupplyAddStore extends BaseAPIStore<ISupplyItemType> implements IAPIStore {
  private supplyStore: SupplyStore;

  constructor(supplyStore: SupplyStore) {
    super();
    this.supplyStore = supplyStore;
  }

  @action
  public async request(newData: ISupplyItemType) {
    const url = this.apiUrl + '/supplies/';
    this.error = undefined;
    this.requesting = true;
    try {
      const response = await axios.post(url, newData, this.authorizeConfigs());
      const responseData = response.data;

      if (responseData.data) {
        this.data = responseData.data;

        if (this.supplyStore.list.data) {
          this.supplyStore.list.data = [...this.supplyStore.list.data, responseData.data];
        }
      } else {
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      console.error(error);
      this.error = error;
    }

    this.requesting = false;
  }
}

export default SupplyAddStore;
