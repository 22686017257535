import axios from 'axios';
import { action } from 'mobx';

import BaseAPIStore, { IAPIStore } from '../api.store';
import { UserStore } from './userStore';

class UserAddStore extends BaseAPIStore<IUser> implements IAPIStore {
  private userStore: UserStore;

  constructor(userStore: UserStore) {
    super();
    this.userStore = userStore;
  }

  @action
  public async request(newData: Partial<IUser> & { password: string }) {
    const url = this.apiUrl + '/users/';
    this.error = undefined;
    this.requesting = true;
    try {
      const response = await axios.post(url, newData, this.authorizeConfigs());
      const responseData = response.data;

      if (responseData.data) {
        this.data = responseData.data;

        if (this.userStore.list.data) {
          this.userStore.list.data = [...this.userStore.list.data, responseData.data];
        }
      } else {
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.error = error;
    } finally {
      this.requesting = false;
    }
  }
}

export default UserAddStore;
