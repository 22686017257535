import axios from 'axios';
import { action } from 'mobx';

import BaseAPIStore, { IAPIStore } from '../api.store';

export class UserListStore extends BaseAPIStore<IUser[]> implements IAPIStore {
  constructor() {
    super();
    this.data = [];
  }

  @action
  public async request() {
    const url = this.apiUrl + '/users';
    this.error = undefined;
    this.requesting = true;
    try {
      const response = await axios.get(url, this.authorizeConfigs());
      const responseData = response.data;
      if (responseData.data) {
        this.data = responseData.data;
      } else {
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.error = error instanceof Error ? error : new Error('Something went wrong.');
    } finally {
      this.requesting = false;
    }
  }
}

const userListStore = new UserListStore();
export default userListStore;
