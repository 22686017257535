import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Grid,
  Input,
  LinearProgress,
  makeStyles,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { observer } from 'mobx-react';
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import BranchPicker from '../../../components/Input/BranchPicker';
import SnackBarContent from '../../../components/SnackBarContent';
import { StoreContext } from '../../../mobx';
import { currencyString, dateISOFomatter, numberFormatter } from '../../../utils/formatter';

function SalesReportImportScreen() {
  const store = useContext(StoreContext);
  const salesReportImport = store.settings.salesReportImport;

  const [starts, setStarts] = useState<Date | null>(new Date());
  const [ends, setEnds] = useState<Date | null>(new Date());
  const [file, setFile] = useState<File>();
  const [fileValue, setFileValue] = useState('');
  const [branch, setBranch] = useState<string>();

  const [summary, setSummary] = useState({
    starts: new Date(),
    ends: new Date(),
    branch: '',
  });

  const [notifiedData, setNotifiedData] = useState<INotifiedData>({
    open: false,
    variant: 'success',
    message: '',
  });

  const classes = useStyle();

  useEffect(() => {
    return () => {
      salesReportImport.clear();
    };
  }, []);

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotifiedData((oldValue) => ({
      ...oldValue,
      open: false,
    }));
  };

  const handleInputFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
      setFileValue(event.target.value);
    }
  };

  const handleClickSubmit = async () => {
    if (
      starts === null ||
      ends === null ||
      file === undefined ||
      branch === undefined ||
      branch === ''
    ) {
      return;
    }
    try {
      await salesReportImport.request(starts, ends, branch, file);

      if (salesReportImport.error) {
        setNotifiedData({
          open: true,
          variant: 'error',
          message: salesReportImport.error.message,
        });
      } else if (salesReportImport.data) {
        setNotifiedData({
          open: true,
          variant: 'success',
          message: 'Import Success',
        });
        setSummary({
          starts,
          ends,
          branch,
        });
        setStarts(new Date());
        setEnds(new Date());
        setFile(undefined);
        setFileValue('');
      }
    } catch (error) {
      setNotifiedData({
        open: true,
        variant: 'error',
        message: error.message,
      });
    }
  };

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notifiedData.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackBarContent
          variant={notifiedData.variant}
          message={notifiedData.message}
          onClose={handleClose}
        />
      </Snackbar>

      <Typography variant="h4" align="left">
        Sales Report Import
      </Typography>

      <Paper className={classes.paper}>
        <LinearProgress hidden={!salesReportImport.requesting} />
        <Grid container justify="flex-start" style={{ padding: '0 20px' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="starts-picker-inline"
              label={'Starts'}
              value={starts}
              onChange={setStarts}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="ends-picker-inline"
              label={'Ends'}
              value={ends}
              onChange={setEnds}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
          <div className={classes.fileInputContainer}>
            <Input
              name="reportFile"
              type="file"
              value={fileValue}
              onChange={handleInputFileChange}
            />
          </div>
          <BranchPicker classname={classes.branchPicker} onSelect={(value) => setBranch(value)} />
          <div className={classes.importButtonContainer}>
            <Button
              variant="contained"
              color="primary"
              disabled={salesReportImport.requesting}
              onClick={handleClickSubmit}
            >
              Import
            </Button>
          </div>
        </Grid>
      </Paper>

      {salesReportImport.data && (
        <Paper className={classes.paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>สินค้า</TableCell>
                <TableCell>จำนวนที่ขายได้</TableCell>
                <TableCell>จำนวนเงิน</TableCell>
                <TableCell align="right">วันที่</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {salesReportImport.data
                .filter((r) => r.product)
                .map((row) => (
                  <TableRow key={row.product.name}>
                    <TableCell variant="head">{row.product.name}</TableCell>
                    <TableCell>
                      {`${numberFormatter(row.totalVolume)} ${row.product.unit}`}
                    </TableCell>
                    <TableCell>{currencyString(row.totalValue)}</TableCell>
                    <TableCell align="right">{dateISOFomatter(row.date)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  fileInputContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(1),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  importButtonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(1),
  },
  branchPicker: {
    top: 8,
    marginRight: theme.spacing(3),
  },
  table: {
    minWidth: 500,
  },
}));

export default observer(SalesReportImportScreen);
