import DateFnsUtils from '@date-io/date-fns';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { StoreContext } from '../../../../mobx';
import { dateFormatter } from '../../../../utils/formatter';

function getRandomColor() {
  let letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

function SalesReportPage() {
  const store = useContext(StoreContext);
  const productSalesReport = store.report.productSalesReport;
  const classes = useStyles();

  const [fromDate, setFromDate] = useState<Date | null>(new Date());
  const [toDate, setToDate] = useState<Date | null>(new Date());

  useEffect(() => {
    if (fromDate && toDate) {
      const fromDateString = dateFormatter(fromDate);
      const toDateString = dateFormatter(toDate);
      productSalesReport.request(fromDateString, toDateString);
    }
  }, [fromDate, toDate]);

  return (
    <div className={classes.root}>
      <Typography variant="h4" align="left">
        Sales Report
      </Typography>

      <Paper className={classes.paper}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="flex-start" spacing={2} className={classes.inputGrid}>
            <Grid item>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Date"
                value={fromDate}
                onChange={setFromDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <Grid item>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Date"
                value={toDate}
                onChange={setToDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </Paper>

      {productSalesReport.data && (
        <Paper className={classes.paper}>
          <Doughnut
            data={{
              labels: productSalesReport.data.map(i => i.name),
              datasets: [
                {
                  data: productSalesReport.data.map(i => i.totalValue),
                  backgroundColor: productSalesReport.data.map(i => getRandomColor()),
                },
              ],
            }}
          />
        </Paper>
      )}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
    minHeight: 50,
  },
  textField: {
    minWidth: 200,
  },
  menu: {
    minWidth: 200,
  },
  margin: {
    margin: theme.spacing(1),
  },
  inputGrid: {
    padding: theme.spacing(1),
  },
}));

export default observer(SalesReportPage);
