import React, { useContext, useEffect, useState } from 'react';

import {
  Button,
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import ConfirmDialog from '../../../components/Dialog/ConfirmDialog';
import { StoreContext } from '../../../mobx';
import routeName from '../../../routes/routeName';

function UsersPage() {
  const store = useContext(StoreContext);
  const listApi = store.user.list;
  const deleteApi = store.user.delete;
  const history = useHistory();
  const classes = useStyles();

  const currentUser = store.auth.currentUser;

  const [deletingUser, setDeletingUser] = useState<IUser>();

  useEffect(() => {
    listApi.request();
  }, []);

  const handleClickNewUser = () => {
    history.push(routeName.backoffice.user.new);
  };

  const handleClickEditUser = (user: IUser) => () => {
    history.push(routeName.backoffice.user.edit, {
      id: user._id,
      ...user,
    });
  };

  const handleClickDeleteUser = (user: IUser) => () => {
    if (deleteApi.requesting) {
      return;
    }
    setDeletingUser(user);
  };

  const handleOnCloseConfirmDelete = () => {
    setDeletingUser(undefined);
  };

  const handleConfirmDeleteUser = async () => {
    if (!deletingUser?._id) {
      alert('Error user id is null');
      setDeletingUser(undefined);
      return;
    }
    await deleteApi.request(deletingUser._id);
    setDeletingUser(undefined);
    if (deleteApi.error) {
      alert(deleteApi.error.message || 'Something went wrong.');
    } else {
      //
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h4" align="left">
          Users
        </Typography>
        <Button variant="contained" color="primary" onClick={handleClickNewUser}>
          Create User
        </Button>
      </div>
      <Paper className={classes.paper}>
        <LinearProgress hidden={!listApi.requesting} />
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listApi.data?.map((user, index) => (
              <TableRow key={user._id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{`${user.firstname} ${user.lastname}`}</TableCell>
                <TableCell>{user.mobile || '-'}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>
                  {currentUser?.role === 'admin' &&
                    (user.role !== 'admin' || user._id === currentUser?._id) && (
                      <>
                        <IconButton aria-label="delete" onClick={handleClickEditUser(user)}>
                          <Edit />
                        </IconButton>
                        <IconButton aria-label="delete" onClick={handleClickDeleteUser(user)}>
                          <Delete color="error" />
                        </IconButton>
                      </>
                    )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <ConfirmDialog
        open={deletingUser != null}
        title={`ยืนยันลบผู้ใช้`}
        description={deletingUser?.firstname}
        confirmColor={'red'}
        onClose={handleOnCloseConfirmDelete}
        onCancel={handleOnCloseConfirmDelete}
        onConfirm={handleConfirmDeleteUser}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

export default observer(UsersPage);
