import axios from 'axios';
import { action } from 'mobx';

import { dateAPIFormatter } from '../../../utils/formatter';
import BaseAPIStore, { IAPIStore } from '../api.store';

export class SalesReportImportStore extends BaseAPIStore<IImportSalesReportSummary[]>
  implements IAPIStore {
  @action
  public async request(starts: Date, ends: Date, branch: string, file: File) {
    const url = this.apiUrl + '/sales/import';

    const startsString = dateAPIFormatter(starts);
    const endsString = dateAPIFormatter(ends);

    const data = new FormData();
    data.append('reportFile', file);
    data.append('startDate', startsString);
    data.append('endDate', endsString);
    data.append('branch', branch);

    this.data = undefined;
    this.error = undefined;
    this.requesting = true;

    try {
      const response = await axios.post(url, data, this.authorizeConfigs());
      const responseData = response.data;

      if (responseData.data) {
        console.log(responseData.data);
        this.data = responseData.data;
      } else {
        this.data = undefined;
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.data = undefined;
      this.error = error;
    } finally {
      this.requesting = false;
    }
  }
}

const salesReportImportStore = new SalesReportImportStore();
export default salesReportImportStore;
