import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  makeStyles,
  MenuItem,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import { Column, EditComponentProps } from 'material-table';
import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MaterialTable } from '../../components';
import SnackBarContent from '../../components/SnackBarContent';
import routeName from '../../routes/routeName';
import {
  BOOKING_ORDER_DELIVERY_METHODS,
  BOOKING_ORDER_PAYMENT_STATUS,
  BOOKING_ORDER_TYPE_NAMES,
} from '../../shared/constants';
import { currencyString } from '../../utils/formatter';
import { requestPostApi } from '../../utils/services/api/requestApi';
import { useFetchApi } from '../../utils/services/api/useFetchApi';

type BookingOrderFormData = Omit<IBookingOrder, '_id' | 'products' | 'createdBy' | 'totalPrice'> & {
  _id?: string;
};
const DEFAULT_FORM_DATA: BookingOrderFormData = {
  customerName: '',
  customerLineName: '',
  customerMobile: '',
  deliveryMethod: undefined,
  address: '',

  packagePrice: 0,
  deliveryPrice: 0,
  paymentStatus: 'PENDING',

  status: 'PENDING',
  orderedAt: '',
  deliveredAt: '',
  taxInvoiceRequested: false,
};

export function BookingOrderUpsertPage() {
  const { state: { order, orderId } = {} } = useLocation<{
    orderId?: string;
    order?: IBookingOrder;
  }>();

  const history = useHistory();
  const classes = useStyles();

  const { data: products } = useFetchApi<IProduct[]>('/products');

  const [formData, setFormData] = useState<BookingOrderFormData>(order ?? DEFAULT_FORM_DATA);
  const [items, setItems] = useState<IOrderProductItem[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [notifiedData, setNotifiedData] = useState<INotifiedData>({
    open: false,
    variant: 'success',
    message: '',
  });

  const columns: Array<Column<IOrderProductItem>> = [
    {
      title: 'สินค้า',
      field: 'product',
      render: renderProductField,
      editComponent: renderProductsInput,
    },
    { title: 'จำนวน', field: 'amount', type: 'numeric' },
    {
      title: 'ราคาต่อชิ้น',
      field: 'itemPrice',
      type: 'numeric',
      editComponent: renderItemPriceInput,
    },
    {
      title: 'ราคารวม',
      field: 'totalPrice',
      type: 'numeric',
      editComponent: renderItemTotalPriceInput,
    },
  ];

  const totalPrice = useMemo(() => items.reduce((sum, v) => sum + v.totalPrice, 0), [items]);

  useEffect(() => {
    if (order?.products == null) {
      return setItems([]);
    }
    setItems(() =>
      order.products.map<IOrderProductItem>((v) => ({
        ...v,
        product: typeof v.product === 'string' ? v.product : v.product._id,
      }))
    );
  }, [order?.products]);

  // helper
  const getProductData = (product: string | IProduct): IProduct | undefined => {
    if (product == null) {
      return;
    }

    const productId = typeof product === 'string' ? product : product._id;
    const data = products?.find((value) => productId === value._id);
    return data;
  };

  // handle event methos
  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotifiedData((oldValue) => ({
      ...oldValue,
      open: false,
    }));
  };

  const canSubmit = () => {
    return items.length > 0 && !!formData?.customerName;
  };

  const handRowAdd = async (newData: IOrderProductItem) => {
    const product = getProductData(newData.product);
    const price = newData.itemPrice ?? product?.price ?? 0;
    const _totalPrice = price * newData.amount;

    setItems((oldItems) => [
      ...oldItems,
      { ...newData, itemPrice: price, totalPrice: _totalPrice },
    ]);
  };

  const handleRowUpdate = async (newData: IOrderProductItem, oldData?: IOrderProductItem) => {
    const product = getProductData(newData.product);
    const price = newData.itemPrice ?? product?.price ?? 0;
    const _totalPrice = price * newData.amount;

    const newItems = [...items];
    const newItem = { ...newData, itemPrice: price, totalPrice: _totalPrice };

    if (oldData) {
      const prevIndex = items.findIndex((v) => v.product === oldData.product);
      if (prevIndex >= 0) {
        newItems[prevIndex] = newItem;
      } else {
        newItems.push(newItem);
      }
    } else {
      newItems.push(newItem);
    }

    setItems(newItems);
  };

  const handleRowDelete = async (oldData: IOrderProductItem) => {
    const newItems = [...items];
    const index = items.findIndex((v) => v.product === oldData.product);
    if (index >= 0) {
      newItems.splice(index, 1);
    }
    setItems(newItems);
  };

  const handleOnFormChange = (event: React.ChangeEvent<any>) => {
    event.persist();

    let newValue = event.target.value;
    if (event.target.type === 'number') {
      newValue = parseFloat(event.target.value);
    } else if (event.target.type === 'checkbox') {
      newValue = event.target.checked;
    }

    setFormData((oldValue) => ({
      ...oldValue,
      [event.target.name]: newValue,
    }));
  };

  const handleDateChange =
    (key: keyof Pick<BookingOrderFormData, 'deliveredAt' | 'orderedAt'>) => (date: Date | null) => {
      if (date == null) {
        return;
      }

      try {
        const isoString = date.toISOString();
        setFormData((oldValue) => ({
          ...oldValue,
          [key]: isoString,
        }));
      } catch (error) {
        setFormData((oldValue) => ({
          ...oldValue,
          [key]: '',
        }));
      }
    };

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      const totalPrice =
        formData.deliveryPrice +
        formData.packagePrice +
        items.reduce((sum, v) => sum + v.totalPrice, 0);
      const params: IUpsertBookingOrderParams = {
        ...formData,
        _id: orderId,
        products: items,
        totalPrice,
      };
      await requestPostApi('/orders', params);
      setNotifiedData({
        open: true,
        variant: 'success',
        message: 'Success',
      });
      setTimeout(() => {
        history.push(routeName.backoffice.orders.list);
      }, 1000);
    } catch (error) {
      setNotifiedData({
        open: true,
        variant: 'error',
        message: error.message || 'Something went wrong',
      });
    } finally {
      setSubmitting(false);
    }
  };

  // render methods
  function renderProductField(rowData?: IOrderProductItem) {
    if (rowData?.product == null) {
      return null;
    }
    const product = getProductData(rowData?.product);
    return (
      <Typography variant="caption" align="left">
        {product?.name || '-'}
      </Typography>
    );
  }

  function renderProductsInput(inputProps: EditComponentProps<IOrderProductItem>) {
    const product = getProductData(inputProps.rowData.product);
    const name = product?.name || '';
    return (
      <Autocomplete
        id="material-input-suggest"
        options={products ?? []}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} placeholder={name} margin="normal" />}
        onChange={(event, value) =>
          inputProps.onChange(typeof value === 'string' ? value : value?._id)
        }
      />
    );
  }

  function renderItemPriceInput(inputProps: EditComponentProps<IOrderProductItem>) {
    const product = getProductData(inputProps.rowData.product);
    return (
      <Typography variant="caption" align="left">
        {product?.price ?? '-'}
      </Typography>
    );
  }

  function renderItemTotalPriceInput(inputProps: EditComponentProps<IOrderProductItem>) {
    const product = getProductData(inputProps.rowData.product);
    const price = product?.price ?? 0;
    const totalPrice = inputProps.rowData.amount ? inputProps.rowData.amount * price : '-';
    return (
      <Typography variant="caption" align="left">
        {totalPrice}
      </Typography>
    );
  }

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notifiedData.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackBarContent
          variant={notifiedData.variant}
          message={notifiedData.message}
          onClose={handleClose}
        />
      </Snackbar>
      <Typography variant="h4" align="left">
        Order
      </Typography>
      <Paper className={clsx(classes.paper, classes.padding)}>
        <Typography variant="subtitle1" align="left">
          รายละเอียดผู้สั่งซื้อ
        </Typography>
        <TextField
          id="customerName"
          name="customerName"
          label="ชื่อผู้สั่ง"
          type="text"
          value={formData.customerName}
          required
          className={clsx(classes.textField, classes.margin)}
          onChange={handleOnFormChange}
        />
        <TextField
          id="customerLineName"
          name="customerLineName"
          label="ชื่อ LINE ผู้สั่ง"
          type="text"
          value={formData.customerLineName}
          required
          className={clsx(classes.textField, classes.margin)}
          onChange={handleOnFormChange}
        />
        <TextField
          id="customerMobile"
          name="customerMobile"
          label="เบอร์ติดต่อผู้สั่ง"
          type="tel"
          value={formData.customerMobile}
          className={clsx(classes.textField, classes.margin)}
          onChange={handleOnFormChange}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.taxInvoiceRequested}
              name="taxInvoiceRequested"
              onChange={handleOnFormChange}
            />
          }
          label="ต้องการใบกำกับภาษี"
          className={clsx(classes.checkbox, classes.margin)}
        />
        <TextField
          id="address"
          name="address"
          label="ที่อยู่จัดส่ง"
          variant="outlined"
          multiline
          className={clsx(classes.fullTextField, classes.margin)}
          value={formData.address}
          onChange={handleOnFormChange}
        />
      </Paper>
      <Paper className={clsx(classes.paper, classes.padding)}>
        <Typography variant="subtitle1" align="left">
          รายละเอียดการสั่งซื้อ
        </Typography>
        <TextField
          id="deliveryMethod"
          name="deliveryMethod"
          label="วิธีจัดส่ง"
          value={formData.deliveryMethod}
          select
          className={clsx(classes.textField, classes.margin)}
          onChange={handleOnFormChange}
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }}
        >
          {Object.keys(BOOKING_ORDER_DELIVERY_METHODS).map((key) => (
            <MenuItem key={key} value={key}>
              {BOOKING_ORDER_DELIVERY_METHODS[key as BookingOrderStatus]}
            </MenuItem>
          ))}
        </TextField>

        <KeyboardDateTimePicker
          id="deliveredAt"
          name="deliveredAt"
          variant="inline"
          format="dd/MM/yyyy HH:mm"
          margin="normal"
          label="วันที่รับของ"
          placeholder="30/12/2022 18:01"
          value={formData.deliveredAt}
          className={clsx(classes.textField, classes.margin)}
          onChange={handleDateChange('deliveredAt')}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          InputLabelProps={{ shrink: true }}
        />
        <br />
        <TextField
          id="packagePrice"
          name="packagePrice"
          label="ค่าแพคเกจ"
          type="number"
          value={formData.packagePrice}
          required
          className={clsx(classes.textField, classes.margin)}
          onChange={handleOnFormChange}
        />
        <TextField
          id="deliveryPrice"
          name="deliveryPrice"
          label="ค่าจัดส่ง"
          type="number"
          value={formData.deliveryPrice}
          required
          className={clsx(classes.textField, classes.margin)}
          onChange={handleOnFormChange}
        />
        <TextField
          id="paymentStatus"
          name="paymentStatus"
          label="สถานะการจ่ายเงิน"
          value={formData.paymentStatus}
          select
          className={clsx(classes.textField, classes.margin)}
          onChange={handleOnFormChange}
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }}
        >
          {Object.keys(BOOKING_ORDER_PAYMENT_STATUS).map((key) => (
            <MenuItem key={key} value={key}>
              {BOOKING_ORDER_PAYMENT_STATUS[key as BookingOrderPaymentStatus]}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="status"
          name="status"
          label="สถานะ order"
          value={formData.status}
          select
          className={clsx(classes.textField, classes.margin)}
          onChange={handleOnFormChange}
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }}
        >
          {Object.keys(BOOKING_ORDER_TYPE_NAMES).map((key) => (
            <MenuItem key={key} value={key}>
              {BOOKING_ORDER_TYPE_NAMES[key as BookingOrderStatus]}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          id="note"
          name="note"
          label="โน๊ต/หมายเหตุ"
          variant="outlined"
          multiline
          className={clsx(classes.fullTextField, classes.margin)}
          value={formData.note}
          onChange={handleOnFormChange}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!canSubmit() || submitting}
          className={classes.button}
          onClick={handleSubmit}
        >
          {submitting ? <CircularProgress size={24} /> : 'Submit'}
        </Button>
      </Paper>

      <Paper className={classes.paper}>
        <MaterialTable
          title={`รายการสินค้า - รวม ${currencyString(
            totalPrice,
            'บาท'
          )} | รวมค่าแพคเกจและจัดส่ง ${currencyString(
            totalPrice + formData.deliveryPrice + formData.packagePrice,
            'บาท'
          )}`}
          columns={columns}
          data={items}
          editable={{
            onRowAdd: handRowAdd,
            onRowUpdate: handleRowUpdate,
            onRowDelete: handleRowDelete,
          }}
          options={{
            search: false,
            paging: false,
            showEmptyDataSourceMessage: false,
            actionsColumnIndex: 4,
          }}
        />
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  mixedAutocomplete: {
    display: 'inline-flex',
  },
  input: {
    minWidth: 200,
    margin: theme.spacing(1),
  },
  textField: {
    minWidth: 200,
  },
  fullTextField: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  menu: {
    minWidth: 200,
  },
  table: {
    minWidth: 650,
  },
  button: {
    marginTop: theme.spacing(2),
    width: 200,
  },
  padding: {
    padding: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
  },
  checkbox: {
    position: 'relative',
    top: 10,
  },
}));
