import axios from 'axios';
import { action } from 'mobx';

import BaseAPIStore, { IAPIStore } from '../api.store';

export class RecipeListStore extends BaseAPIStore<IProductRecipe[]> implements IAPIStore {
  @action
  public async request() {
    const url = this.apiUrl + '/products/recipes';
    this.error = undefined;
    this.requesting = true;

    try {
      const response = await axios.get(url, this.authorizeConfigs());
      const responseData = response.data;

      if (responseData.data) {
        this.data = responseData.data;
      } else {
        this.data = undefined;
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.data = undefined;
      this.error = error;
    }

    this.requesting = false;
  }
}

const recipeListStore = new RecipeListStore();
export default recipeListStore;
