import axios from 'axios';
import { action } from 'mobx';

import BaseAPIStore, { IAPIStore } from '../api.store';
import { UserStore } from './userStore';

class UserDeleteStore extends BaseAPIStore<IUser> implements IAPIStore {
  private userStore: UserStore;

  constructor(userStore: UserStore) {
    super();
    this.userStore = userStore;
  }

  @action
  public async request(uid: string) {
    if (uid == null || uid === '') {
      this.error = new Error('Not found User id');
      return;
    }
    const url = this.apiUrl + '/users/' + uid;
    this.error = undefined;
    this.requesting = true;
    try {
      const response = await axios.delete(url, this.authorizeConfigs());
      const responseData = response.data;

      if (responseData.data) {
        this.data = responseData.data;

        if (this.userStore.list.data) {
          const index = this.userStore.list.data.findIndex((v) => v._id === uid);
          if (index >= 0) {
            this.userStore.list.data.splice(index, 1);
          }
        }
      } else {
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.error = error instanceof Error ? error : new Error('Something went wrong.');
    } finally {
      this.requesting = false;
    }
  }
}

export default UserDeleteStore;
