import {
  Button,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import { Column, EditComponentProps } from 'material-table';
import { observer } from 'mobx-react';
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { MaterialTable } from '../../../components';
import ConfirmDialog from '../../../components/Dialog/ConfirmDialog';
import SnackBarContent from '../../../components/SnackBarContent';
import { StoreContext } from '../../../mobx';
import { RoleStore } from '../../../mobx/stores/roleStore/roleStore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  mixedAutocomplete: {
    display: 'inline-flex',
    width: '35%',
    minWidth: 200,
  },
  input: {
    minWidth: 200,
    margin: theme.spacing(1),
  },
  menu: {
    minWidth: 200,
  },
  table: {
    minWidth: 650,
  },
  button: {
    marginTop: theme.spacing(2),
    width: 200,
  },
  padding: {
    padding: theme.spacing(2),
  },
}));

interface IUsedSupply {
  supply: string;
  amount: number;
  unit: string;
}

function StockMixPage() {
  const store = useContext(StoreContext);
  const { supply } = store;

  const loggedInBranchId = store.auth.loggedInBranchId || '';

  const [formData, setFormData] = useState({
    branch: loggedInBranchId,
    mixtedItem: '',
    mixtedAmount: 0,
    mixtedUnit: '',
  });

  const [usedItems, setUsedItems] = useState<IUsedSupply[]>([]);
  const [unit, setUnit] = useState('');
  const [notifiedData, setNotifiedData] = useState<INotifiedData>({
    open: false,
    variant: 'success',
    message: '',
  });
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);

  const classes = useStyles();

  const branches = store.branch.list.data || [];
  const suppies = supply.list.data?.slice() || [];
  const mixedItems = suppies.slice().filter((item) => item.type === 'mixedIngredient');
  const rawItems = suppies.slice().filter((item) => item.type === 'rawIngredient');

  const columns: Array<Column<IUsedSupply>> = [
    {
      title: 'วัตถุดิบ',
      field: 'supply',
      render: renderRawSupply,
      editComponent: renderRawSupplyInput,
    },
    { title: 'จำนวนที่ใช้', field: 'amount', type: 'numeric' },
    { title: 'หน่วย', field: 'unit', render: renderUnit, editComponent: renderEditableUnit },
  ];

  useEffect(() => {
    supply.list.request();
    store.branch.list.request();
    store.recipe.listStore.request();
  }, [supply.list]);

  useEffect(() => {
    const recipes = store.recipe.listStore.data;
    if (recipes === undefined) {
      return;
    }
    const recipeIndex = recipes.findIndex((r) => r.supply._id === formData.mixtedItem);
    if (recipeIndex < 0) {
      return;
    }

    const canViewSuggest = RoleStore.canMixIngredientWithAutoSuggestItem(store.auth.currentUser);

    const recipe = recipes[recipeIndex];
    const ingredients: IUsedSupply[] = recipe.ingredients.map((item) => {
      const amount = (formData.mixtedAmount / recipe.amount) * item.amount;
      const roundedAmount = Math.round((amount + Number.EPSILON) * 100) / 100;
      return {
        supply: item.ingredient._id,
        amount: canViewSuggest ? roundedAmount : 0,
        unit: item.unit,
      };
    });
    setUsedItems(ingredients);
  }, [formData.mixtedItem, formData.mixtedAmount]);

  const canSubmit = () => {
    let valid =
      formData.branch !== '' &&
      formData.mixtedItem !== '' &&
      formData.mixtedAmount > 0 &&
      usedItems.length > 0;

    valid = valid && usedItems.filter((v) => v.supply === '' || v.amount > 0).length > 0;

    return valid;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const name = event.target.name;
    const value = event.target.value;

    if (name === 'mixtedItem' && value) {
      const index = suppies.findIndex((item) => item._id === value);
      if (index >= 0) {
        const item = suppies[index];
        setUnit(item.unit);
      }
    }

    setFormData((oldVals) => ({
      ...oldVals,
      [name]: value,
    }));
  };

  const handleProducedSupplyChange = (e: React.ChangeEvent<{}>, value?: ISupplyItemType | null) => {
    if (value == null) {
      return;
    }
    setFormData((oldVals) => ({
      ...oldVals,
      mixtedItem: value._id,
      mixtedUnit: value.unit,
    }));
  };

  const handRowAdd = async (newData: IUsedSupply) => {
    setUsedItems((oldItems) => [...oldItems, newData]);
  };

  const handleRowUpdate = async (newData: IUsedSupply, oldData?: IUsedSupply) => {
    const newUsedItems = [...usedItems];
    if (oldData) {
      const prevIndex = usedItems.findIndex((v) => v.supply === oldData.supply);
      if (prevIndex >= 0) {
        newUsedItems[prevIndex] = newData;
      } else {
        newUsedItems.push(newData);
      }
    } else {
      newUsedItems.push(newData);
    }

    setUsedItems(newUsedItems);
  };

  const handleRowDelete = async (oldData: IUsedSupply) => {
    const newUsedItems = [...usedItems];
    const index = usedItems.findIndex((v) => v.supply === oldData.supply);
    if (index >= 0) {
      newUsedItems.splice(index, 1);
    }
    setUsedItems(newUsedItems);
  };

  const handleSubmit = async () => {
    setOpenConfirmAlert(false);
    if (!canSubmit()) {
      return;
    }

    try {
      const { mixtedItem, mixtedAmount, branch } = formData;
      await store.stock.mixStore.request(mixtedItem, mixtedAmount, branch, usedItems);
      if (store.stock.mixStore.error) {
        setNotifiedData({
          open: true,
          variant: 'error',
          message: store.stock.mixStore.error.message,
        });
      } else {
        setNotifiedData({
          open: true,
          variant: 'success',
          message: 'เพิ่มรายการเข้าไปใน stock เรียบร้อย',
        });
        setFormData({
          branch: loggedInBranchId,
          mixtedItem: '',
          mixtedAmount: 0,
          mixtedUnit: '',
        });
        setUsedItems([]);
      }
    } catch (error) {
      setNotifiedData({
        open: true,
        variant: 'error',
        message: error.message,
      });
    }
  };

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotifiedData((oldValue) => ({
      ...oldValue,
      open: false,
    }));
  };

  // render methods

  function renderRawSupplyInput(inputProps: EditComponentProps<IUsedSupply>) {
    const name = rawItems.find((v) => inputProps?.rowData?.supply === v._id)?.name || '';
    return (
      <Autocomplete
        id="material-input-suggest"
        options={rawItems}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} placeholder={name} margin="normal" />}
        onChange={(event, value) =>
          inputProps.onChange(typeof value === 'string' ? value : value?._id)
        }
      />
    );
  }

  function renderRawSupply(rowData?: IUsedSupply) {
    const name = rawItems.find((v) => rowData?.supply === v._id)?.name || '';
    return (
      <Typography variant="caption" align="left">
        {name}
      </Typography>
    );
  }

  function renderUnit(rowData?: IUsedSupply) {
    let unit = '-';
    if (rowData) {
      const data = suppies.find((value) => rowData.supply === value._id);
      unit = (data && data.unit) || '-';
    }
    return (
      <Typography variant="caption" align="left">
        {unit}
      </Typography>
    );
  }

  function renderEditableUnit(props: EditComponentProps<IUsedSupply>) {
    let rowUnit = '-';
    const { rowData } = props;
    if (rowData) {
      const data = suppies.find((value) => rowData.supply === value._id);
      rowUnit = (data && data.unit) || '-';
    }
    return (
      <Typography variant="caption" align="left">
        {rowUnit}
      </Typography>
    );
  }

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notifiedData.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackBarContent
          variant={notifiedData.variant}
          message={notifiedData.message}
          onClose={handleClose}
        />
      </Snackbar>
      <ConfirmDialog
        open={openConfirmAlert}
        title="ยืนยันผสมวัตถุดิบ"
        onClose={() => setOpenConfirmAlert(false)}
        onCancel={() => setOpenConfirmAlert(false)}
        onConfirm={handleSubmit}
      />
      <Typography variant="h4" align="left">
        ผสมวัตถุดิบ
      </Typography>
      <Paper className={clsx(classes.paper, classes.padding)}>
        <Typography variant="subtitle1" align="left">
          วัตถุดิบที่ผสม
        </Typography>
        <Autocomplete
          id="mixtedItem"
          options={mixedItems}
          getOptionLabel={(option) =>
            option.code != null ? `${option.code}: ${option.name}` : option.name
          }
          className={classes.mixedAutocomplete}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.input}
              label="วัตถุดิบที่ได้"
              name="mixtedItem"
              type="text"
              onChange={handleChange}
            />
          )}
          onChange={handleProducedSupplyChange}
        />
        <TextField
          value={formData.mixtedAmount}
          className={classes.input}
          label={formData.mixtedUnit ? `จำนวน (${formData.mixtedUnit})` : 'จำนวน'}
          name="mixtedAmount"
          type="number"
          onChange={handleChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
          }}
        />
        <TextField
          select
          value={formData.branch}
          className={classes.input}
          label="สาขา"
          name="branch"
          type="text"
          onChange={handleChange}
          SelectProps={{
            MenuProps: { className: classes.menu },
          }}
        >
          {branches.map((branch) => (
            <MenuItem key={branch._id} value={branch._id}>
              {branch.name}
            </MenuItem>
          ))}
        </TextField>
      </Paper>
      <Paper className={classes.paper}>
        <MaterialTable
          title="วัตถุดิบที่ใช้"
          columns={columns}
          data={usedItems}
          editable={{
            onRowAdd: handRowAdd,
            onRowUpdate: handleRowUpdate,
            onRowDelete: handleRowDelete,
          }}
          options={{
            search: false,
            paging: false,
            showEmptyDataSourceMessage: false,
            actionsColumnIndex: 3,
          }}
        />
      </Paper>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={!canSubmit() || store.stock.mixStore.requesting}
        className={classes.button}
        onClick={() => setOpenConfirmAlert(true)}
      >
        {store.stock.mixStore.requesting ? <CircularProgress size={24} /> : 'Submit'}
      </Button>
    </div>
  );
}

export default observer(StockMixPage);
