import { observable } from 'mobx';
import ProductAddStore from './product.add.store';
import listStore from './product.list.store';
import ProductUpdateStore from './product.update.store';

export class ProductStore {
  @observable public list = listStore;

  @observable public update: ProductUpdateStore;
  @observable public add: ProductAddStore;

  constructor() {
    this.update = new ProductUpdateStore(this);
    this.add = new ProductAddStore(this);
  }
}

const productStore = new ProductStore();
export default productStore;
