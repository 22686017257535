import axios from 'axios';
import { action } from 'mobx';

import BaseAPIStore, { IAPIStore } from '../api.store';

export class StockDailyReportGetStore extends BaseAPIStore<IStockDailyRport[]>
  implements IAPIStore {
  @action
  public async request(date: string, branch?: string, toDate?: string, isRange = false) {
    let url = this.apiUrl;
    if (!isRange) {
      url += '/reports/stock/' + date;
      if (branch) {
        url += '/' + branch;
      }
    } else {
      url += '/reports/stock/range/' + date + '/' + toDate;
      if (branch) {
        url += '/' + branch;
      }
    }

    this.error = undefined;
    this.requesting = true;

    try {
      const response = await axios.get(url, this.authorizeConfigs());
      const responseData = response.data;

      if (responseData.data) {
        this.data = responseData.data;
      } else {
        this.data = undefined;
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.data = undefined;
      this.error = error;
    }

    this.requesting = false;
  }
}

const stockDailyReportGetStore = new StockDailyReportGetStore();
export default stockDailyReportGetStore;
