import React, { useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { observer } from 'mobx-react';

interface Props {
  onChange: (fromDate: Date, toDate: Date, status?: string) => void;
}

function OrderProductSearchForm(props: Props) {
  // const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [fromDate, setFromDate] = useState<Date | null>(new Date());
  const [toDate, setToDate] = useState<Date | null>(new Date());
  const [status, setStatus] = useState<string>('');

  const classes = useStyles();

  useEffect(() => {
    if (fromDate && toDate) {
      props.onChange(fromDate, toDate, status);
    }
  }, [fromDate, toDate, status]);

  return (
    <Paper className={classes.paper}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-around">
          <div>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label={'From date'}
              value={fromDate}
              onChange={setFromDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="To date"
              value={toDate}
              className={classes.toDateContainer}
              onChange={setToDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </div>
        </Grid>
      </MuiPickersUtilsProvider>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  toDateContainer: {
    marginLeft: theme.spacing(2),
  },
  input: {
    marginTop: theme.spacing(1.8),
  },
}));

export default observer(OrderProductSearchForm);
