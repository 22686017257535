import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Column } from 'material-table';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { MaterialTable } from '../../components';
import { StoreContext } from '../../mobx';
import { SUPPLY_TYPE_NAMES } from '../../shared/constants';
import { numberFormatter } from '../../utils/formatter';
import { RoleStore } from '../../mobx/stores/roleStore/roleStore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
}));

function SuppliesPage() {
  const store = useContext(StoreContext);
  const { supply } = store;
  const classes = useStyles();

  useEffect(() => {
    supply.list.request();
  }, [supply.list]);

  const columns: Array<Column<ISupplyItemType>> = [
    { title: 'รหัส', field: 'code', emptyValue: '-' },
    { title: 'ชื่อ', field: 'name' },
    { title: 'คงเหลือทั้งหมด', field: 'remainingAmount', editable: 'never', render: renderAmount },
    { title: 'หน่วย', field: 'unit' },
    { title: 'ประเภท', field: 'type', lookup: SUPPLY_TYPE_NAMES },
    { title: 'Inactive', field: 'inactive', type: 'boolean', render: renderDisable },
  ];

  function renderAmount(rowData?: ISupplyItemType) {
    return (
      <Typography variant="caption" align="left">
        {numberFormatter(rowData?.remainingAmount ?? 0)}
      </Typography>
    );
  }

  function renderDisable(rowData?: ISupplyItemType) {
    return (
      <Typography variant="caption" align="left">
        {rowData?.inactive ? '❌' : ''}
      </Typography>
    );
  }

  const handRowAdd = async (newData: ISupplyItemType) => {
    await supply.add.request(newData);
  };

  const handleRowUpdate = async (newData: ISupplyItemType, oldData?: ISupplyItemType) => {
    await supply.update.request(newData._id, newData);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" align="left">
        รายการวัตถุดิบ
      </Typography>
      <Paper className={classes.paper}>
        <MaterialTable
          title="รายการวัตถุดิบ"
          columns={columns}
          data={supply.list.data || []}
          isLoading={supply.list.requesting}
          editable={{
            onRowUpdate: RoleStore.canEditSupply(store.auth.currentUser)
              ? handleRowUpdate
              : undefined,
            onRowAdd: handRowAdd,
          }}
          options={{
            actionsColumnIndex: columns.length,
            pageSize: 20,
          }}
        />
      </Paper>
    </div>
  );
}

export default observer(SuppliesPage);
