import {observable} from 'mobx';
import recipeCreateStore from './recipe.create.store';
import recipeListStore from './recipe.list.store';

export class RecipeStore {
  @observable
  public listStore = recipeListStore;

  @observable
  public createStore = recipeCreateStore;
}

const recipeStore = new RecipeStore();

export default recipeStore;