import MT, { MaterialTableProps, Options } from 'material-table';
import React from 'react';

type Props<RowData extends object> = MaterialTableProps<RowData>;

export function MaterialTable<RowData extends object>(props: Props<RowData>) {
  const { options, ...rest } = props;

  const mergeOptions: Options<RowData> = {
    addRowPosition: 'first',
    pageSize: 20,
    pageSizeOptions: [10, 20, 30, 40, 50, 100],
    ...options,
  };

  return (
    <>
      <MT options={mergeOptions} {...rest} />
    </>
  );
}
