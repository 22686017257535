import DateFnsUtils from '@date-io/date-fns';
import {
  Grid,
  InputAdornment,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import BranchPicker from '../../components/Input/BranchPicker';
import { StoreContext } from '../../mobx';
import { dateISOFomatter, supplyAmountFormat } from '../../utils/formatter';

export const AdjustReportPage = observer(() => {
  const store = useContext(StoreContext);
  const classes = useStyles();

  const [fromDate, setFromDate] = useState<Date | null>(() => {
    const dateFns = new DateFnsUtils();
    return dateFns.addDays(new Date(), -30);
  });
  const [toDate, setToDate] = useState<Date | null>(new Date());
  const [search, setSearch] = useState<string>('');
  const [branch, setBranch] = useState<string>('');

  const filteredData = useMemo(() => {
    if (search === '' && branch === '') {
      return store.report.adjustStockReport.data;
    }

    return store.report.adjustStockReport.data?.filter((item) => {
      let isMatchName = true;
      let isMatchBranch = true;
      if (search) {
        const regex = new RegExp(search, 'i');
        isMatchName =
          item.supply.code?.search(regex) !== -1 || item.supply.name?.search(regex) !== -1;
      }

      if (branch) {
        isMatchBranch = item.branch._id === branch;
      }

      return isMatchName && isMatchBranch;
    });
  }, [search, branch, store.report.adjustStockReport.data]);

  useEffect(() => {
    if (fromDate == null || toDate == null) {
      return;
    }

    const dateFns = new DateFnsUtils();
    const dateString = dateFns.format(fromDate, 'yyyy-MM-dd');
    const toDateString = toDate && dateFns.format(toDate, 'yyyy-MM-dd');
    store.report.adjustStockReport.request(dateString, toDateString);
  }, [fromDate, toDate]);

  return (
    <div className={classes.root}>
      <Typography variant="h4" align="left">
        Adjust Report
      </Typography>
      <Paper className={classes.paper}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="flex-start" alignItems="center" className={classes.inputGrid}>
            <Grid item className={classes.marginRight}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="from date"
                value={fromDate}
                onChange={setFromDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <Grid item>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="to date"
                value={toDate}
                onChange={setToDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <div className={classes.flex} />
            <Grid item>
              <TextField
                className={classes.input}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  onChange: (e) => setSearch(e.target.value),
                }}
              />
            </Grid>
            <Grid item>
              <BranchPicker
                initBranch=""
                noneOptionTitle="ทั้งหมด"
                onSelect={(value) => setBranch(value)}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </Paper>
      <Paper className={classes.paper}>
        <LinearProgress hidden={!store.report.adjustStockReport.requesting} />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>วันที่</TableCell>
              <TableCell>รหัส</TableCell>
              <TableCell>วัตถุดิบ</TableCell>
              <TableCell>จำนวน</TableCell>
              <TableCell>สาขา</TableCell>
              <TableCell>เหตุผล</TableCell>
              <TableCell>user</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData?.map((row) => (
              <TableRow key={row._id}>
                <TableCell>
                  {dateISOFomatter(row.createdAt ?? '', 'ccc, dd-MM-yyyy HH:mm')}
                </TableCell>
                <TableCell>{row.supply.code}</TableCell>
                <TableCell>{row.supply.name}</TableCell>
                <TableCell>{`${
                  row.type === 'decrease' && row.amount !== 0 ? '-' : ''
                }${supplyAmountFormat(row.amount, row.supply.unit)}`}</TableCell>
                <TableCell>{row.branch.name ?? ''}</TableCell>
                <TableCell>{row.note ?? ''}</TableCell>
                <TableCell>{row.actor.firstname || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  flex: {
    display: 'flex',
    flex: 1,
  },
  paper: {
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 500,
  },
  textField: {
    minWidth: 200,
  },
  menu: {
    minWidth: 200,
  },
  margin: {
    margin: theme.spacing(1),
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  inputGrid: {
    padding: theme.spacing(1),
  },
  input: {
    top: 8,
  },
}));
