import React from 'react';
import { EditComponentProps } from 'material-table';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

interface ISupplyInputItem {
  supply: string;
}

interface Props<T extends ISupplyInputItem> extends EditComponentProps<T> {
  supplies: ISupply[];
}

export function TableUpsertSuppliesInput<T extends ISupplyInputItem>({
  supplies = [],
  ...props
}: Props<T>) {
  const item = supplies.find((v) => v._id === props?.rowData?.supply);

  const name =
    item != null ? (item.code != null ? `${item.code ?? ''}: ${item.name}` : item.name) : '';

  return (
    <Autocomplete
      id="material-input-suggest"
      options={supplies}
      getOptionLabel={(option) =>
        option.code != null ? `${option.code}: ${option.name}` : option.name
      }
      renderInput={(params) => <TextField {...params} placeholder={name} margin="normal" />}
      onChange={(event, value) => props.onChange(typeof value === 'string' ? value : value?._id)}
    />
  );
}
