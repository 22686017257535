import { observable } from 'mobx';
import salesReportImportStore from './settings.salesReportImport.store';

export class SettingsStore {
  @observable
  public salesReportImport = salesReportImportStore;
}

const settingsStore = new SettingsStore();

export default settingsStore;
