import axios from 'axios';
import { action } from 'mobx';

import BaseAPIStore, { IAPIStore } from '../api.store';
import { ProductStore } from './product.store';

class ProductUpdateStore extends BaseAPIStore<IProduct> implements IAPIStore {
  private productStore: ProductStore;

  constructor(productStore: ProductStore) {
    super();
    this.productStore = productStore;
  }

  @action
  public async request(id: string, newData: IProduct) {
    const url = this.apiUrl + '/products/' + id;
    this.error = undefined;
    this.requesting = true;
    try {
      const response = await axios.put(url, newData, this.authorizeConfigs());
      const responseData = response.data;

      if (responseData.data) {
        this.data = responseData.data;

        if (this.productStore.list.data) {
          const index = this.productStore.list.data.findIndex(item => item._id === id);
          if (index >= 0) {
            const newList = [...this.productStore.list.data];
            newList[index] = responseData.data;
            this.productStore.list.data = newList;
          }
        }
      } else {
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.error = error;
    }

    this.requesting = false;
  }
}

export default ProductUpdateStore;
