import React, { useEffect, useState } from 'react';

import { Typography } from '@material-ui/core';
import { Column, EditComponentProps } from 'material-table';
import { observer } from 'mobx-react';
import { MaterialTable } from '../../../components';
import { TableUpsertSuppliesInput } from '../../../components/Input/TableUpsertSuppliesInput';

interface Props {
  title: string;
  suppliesLookup: ISupply[];
  onChange: (supplies: ISupplyAmoutData[]) => void;
}

export interface ISupplyAmoutData {
  supply: string;
  amount: number;
  unit: string;
}

function EditingSuppliesTable(props: Props) {
  const [supplies, setSupplies] = useState<ISupplyAmoutData[]>([]);

  useEffect(() => {
    props.onChange(supplies);
  }, [supplies]);

  const rawItemsLookup = props.suppliesLookup.reduce(
    (prev, item) => ({
      ...prev,
      [item._id]: item.name,
    }),
    {}
  );

  const columns: Array<Column<ISupplyAmoutData>> = [
    {
      title: 'วัตถุดิบ',
      field: 'supply',
      render: renderSupply,
      editComponent: renderSupplyInput,
    },
    { title: 'จำนวน', field: 'amount' },
    { title: 'หน่วย', field: 'unit', render: renderUnit, editComponent: renderEditableUnit },
  ];

  const sanitizedSupplyData = (data: ISupplyAmoutData): ISupplyAmoutData => {
    const amount = typeof data.amount === 'string' ? parseFloat(data.amount) : data.amount;
    if (isNaN(amount)) {
      throw new Error('กรุณากรอกจำนวนเป็นตัวเลข');
    }

    return {
      ...data,
      amount,
    };
  };

  // Table event methods
  const handRowAdd = async (newData: ISupplyAmoutData) => {
    const data = sanitizedSupplyData(newData);
    setSupplies((oldItems) => [...oldItems, data]);
  };

  const handleRowUpdate = async (newData: ISupplyAmoutData, oldData?: ISupplyAmoutData) => {
    const data = sanitizedSupplyData(newData);
    const newUsedItems = [...supplies];
    if (oldData) {
      const prevIndex = supplies.findIndex((v) => v.supply === oldData.supply);
      if (prevIndex >= 0) {
        newUsedItems[prevIndex] = data;
      } else {
        newUsedItems.push(data);
      }
    } else {
      newUsedItems.push(data);
    }

    setSupplies(newUsedItems);
  };

  const handleRowDelete = async (oldData: ISupplyAmoutData) => {
    const newUsedItems = [...supplies];
    const index = supplies.findIndex((v) => v.supply === oldData.supply);
    if (index >= 0) {
      newUsedItems.splice(index, 1);
    }
    setSupplies(newUsedItems);
  };

  // render methods
  function renderSupplyInput(inputProps: EditComponentProps<ISupplyAmoutData>) {
    return (
      <TableUpsertSuppliesInput
        supplies={props.suppliesLookup.slice()}
        {...inputProps}
        onChange={(value) => inputProps.onChange(value)}
      />
    );
  }
  function renderSupply(rowData?: ISupplyAmoutData) {
    const item = props.suppliesLookup.find((v) => rowData?.supply === v._id);
    const name =
      item != null ? (item.code != null ? `${item.code ?? ''}: ${item.name}` : item.name) : '';
    return (
      <Typography variant="caption" align="left">
        {name}
      </Typography>
    );
  }

  function renderUnit(rowData?: ISupplyAmoutData) {
    let unit = '-';
    if (rowData) {
      const data = props.suppliesLookup.find((value) => rowData.supply === value._id);
      unit = (data && data.unit) || '-';
    }
    return (
      <Typography variant="caption" align="left">
        {unit}
      </Typography>
    );
  }

  function renderEditableUnit(p: EditComponentProps<ISupplyAmoutData>) {
    let rowUnit = '-';
    const { rowData } = p;
    if (rowData) {
      const data = props.suppliesLookup.find((value) => rowData.supply === value._id);
      rowUnit = (data && data.unit) || '-';
    }
    return (
      <Typography variant="caption" align="left">
        {rowUnit}
      </Typography>
    );
  }

  return (
    <MaterialTable
      title={props.title}
      columns={columns}
      data={supplies}
      editable={{
        onRowAdd: handRowAdd,
        onRowUpdate: handleRowUpdate,
        onRowDelete: handleRowDelete,
      }}
      options={{
        search: false,
        paging: false,
        showEmptyDataSourceMessage: false,
        actionsColumnIndex: 3,
      }}
    />
  );
}

EditingSuppliesTable.defaultProps = {
  suppliesLookup: [],
};

export default observer(EditingSuppliesTable);
