import axios from 'axios';
import { action } from 'mobx';

import BaseAPIStore, { IAPIStore } from '../api.store';

export class ExpenseByCategoriesReportStore
  extends BaseAPIStore<IExpenseCategory[]>
  implements IAPIStore
{
  @action
  public async request(
    paymentStatus: BillingPaymentStatus | 'all',
    start?: string,
    end?: string,
    branch?: string
  ) {
    const url = this.apiUrl + `/reports/expense-by-categories`;
    this.error = undefined;
    this.requesting = true;

    const params: any = { paymentStatus, start, end, branch };
    if (paymentStatus === 'all') {
      delete params.paymentStatus;
    }

    try {
      const response = await axios.get(url, { ...this.authorizeConfigs(), params });
      const responseData = response.data;

      if (responseData.data) {
        this.data = responseData.data;
      } else {
        this.data = undefined;
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.data = undefined;
      this.error = error instanceof Error ? error : new Error('Something went wrong.');
    } finally {
      this.requesting = false;
    }
  }
}

const expenseByCategoriesReportStore = new ExpenseByCategoriesReportStore();
export default expenseByCategoriesReportStore;
