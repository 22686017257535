import axios from 'axios';
import { action } from 'mobx';

import BaseAPIStore, { IAPIStore } from '../api.store';
import { UserStore } from './userStore';

class UserUpdateStore extends BaseAPIStore<IUser> implements IAPIStore {
  private userStore: UserStore;

  constructor(userStore: UserStore) {
    super();
    this.userStore = userStore;
  }

  @action
  public async request(uid: string, newData: Partial<IUser>) {
    if (uid == null || uid === '') {
      this.error = new Error('Not found User id');
      return;
    }
    const url = this.apiUrl + '/users/' + uid;
    this.error = undefined;
    this.requesting = true;
    try {
      const response = await axios.put(url, newData, this.authorizeConfigs());
      const responseData = response.data;

      if (responseData.data) {
        this.data = responseData.data;
      } else {
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.error = error instanceof Error ? error : new Error('Something went wrong.');
    } finally {
      this.requesting = false;
    }
  }
}

export default UserUpdateStore;
