export const constants = {
  APP_VERSION: '1.0.7',
};

export const SUPPLY_TYPE_NAMES: Record<SupplyType, string> = {
  rawIngredient: 'วัตถุดิบพื้นฐาน',
  mixedIngredient: 'วัตถุดิบที่ผสมแล้ว',
  package: 'Package',
  foodAndBeverage: 'อาหาร/เครื่องดื่ม',
  officeSupply: 'ของใช้สำนักงาน',
  consumables: 'วัสดุสิ้นเปลือง ',
  'R&D': 'R&D ',
  finishedProduct: 'สินค้าสำเร็จรูป',
  fixedAsset: 'Fixed Asset',
  decoration: 'Decorations',
  other: 'อื่นๆ',
};

export const BOOKING_ORDER_TYPE_NAMES: { [key in BookingOrderStatus]: string } = {
  PENDING: 'PENDING',
  READY_TO_DELIVER: 'READY TO DELIVER',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
};

export const BOOKING_ORDER_PAYMENT_STATUS: { [key in BookingOrderPaymentStatus]: string } = {
  PENDING: 'ยังไม่ชำระ',
  PAID: 'ชำระแล้ว',
  DEPOSIT: 'ชำระมัดจำแล้ว',
};

export const BOOKING_ORDER_DELIVERY_METHODS: { [key in IBookingOrder['deliveryMethod']] } = {
  lalamove: 'lalamove',
  'self-pickup': 'รับหน้าร้าน',
  'delivery-outside-bangkok': 'ส่งต่างจังหวัด',
};
