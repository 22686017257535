import { AxiosRequestConfig } from 'axios';
import Cookie from 'js-cookie';
import { action, observable } from 'mobx';
import { COOKIE_TOKEN_KEY } from './authStore';

export interface IAPIStore<T = any> {
  requesting: boolean;
  data?: T;
  error?: IError;
}

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

export default class BaseAPIStore<T = any> implements IAPIStore<T> {
  @observable public requesting = false;
  @observable public error?: IError;
  @observable.deep public data?: T;

  protected apiUrl: string;

  constructor() {
    if (BASE_API_URL) {
      this.apiUrl = BASE_API_URL;
    } else {
      this.apiUrl = 'http://localhost:3000/api/v1';
    }
  }

  @action
  public clear() {
    this.requesting = false;
    this.error = undefined;
    this.data = undefined;
  }

  protected authorizeConfigs() {
    const token = Cookie.get(COOKIE_TOKEN_KEY);
    const configs: AxiosRequestConfig = {
      headers: { Authorization: 'bearer ' + token },
    };
    return configs;
  }
}
