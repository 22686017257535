import DateFnsUtils from '@date-io/date-fns';

export const DATE_ISO_FORMAT = `yyyy-MM-dd'T'HH:mm:ss'Z'`;

const measureConverting = {
  g: 'kg',
  gram: 'kg',
  กรัม: 'กก.',
  มิลลิลิตร: 'ลิตร',
  ml: 'l',
  milliliter: 'liter',
};

export function dateStringFormatter(dateString: string, format: string, toFormat: string) {
  const dateFns = new DateFnsUtils();
  const date = dateFns.parse(dateString, format);
  return dateFns.format(date, toFormat);
}

export function dateISOFomatter(dateString: string, toFormat: string = 'yyyy-MM-dd') {
  if (!dateString) {
    return '';
  }
  const date = new Date(dateString);
  return dateFormatter(date, toFormat);
}

export function dateAPIFormatter(date: Date) {
  return dateFormatter(date);
}

export function dateFormatter(date: Date, format = 'yyyy-MM-dd') {
  try {
    const dateFns = new DateFnsUtils();
    return dateFns.format(date, format);
  } catch (error) {
    return '';
  }
}

export function numberFormatter(num?: number | string, maximumFractionDigits = 2) {
  if (num === undefined) {
    return 0;
  }
  return num.toLocaleString(undefined, { maximumFractionDigits });
}

export function supplyAmountFormat(amount: number, unit: string, maximumFractionDigits = 2) {
  if (amount == null) {
    return '0';
  }
  let convertedAmount = amount;
  let formattedUnit = unit;
  const canConvert = Object.keys(measureConverting).includes(unit);
  if (canConvert && Math.abs(amount) >= 1000) {
    convertedAmount = amount / 1000;
    formattedUnit = measureConverting[unit] || unit;
  }

  const formattedAmount = convertedAmount.toLocaleString(undefined, { maximumFractionDigits });
  return `${formattedAmount} ${formattedUnit}`;
}

export function currencyString(num?: number | string, currency: string = 'THB') {
  return numberFormatter(num, 2) + ' ' + currency;
}
