import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useImperativeHandle, useState } from 'react';
import { StoreContext } from '../../../mobx';
import { dateFormatter } from '../../../utils/formatter';

export const BILL_VAT_TYPES = {
  VAT_INCLUDED: 'รวม VAT แล้ว',
  VAT_EXCLUDED: 'ยังไม่รวม VAT',
};

export type BillVatType = keyof typeof BILL_VAT_TYPES;
export interface BuyFormData {
  no: string;
  date: string;
  deliveryDate?: string;
  paymentDueDate?: string;
  vendor: string;
  totalPrice: number;
  billVatType: BillVatType;
  branch: string;
  note: string;
  deliveryFee?: number;
  discount?: number;
  paymentStatus: 'pending' | 'paid';
}
interface Props {
  initialData?: BuyFormData;
  editMode?: boolean;
  canSubmit?: boolean;
  submitting?: boolean;
  onDataChange: (data: BuyFormData) => void;
  onSubmit: (data: BuyFormData) => void;
  clear?(fn: () => void): void;
}

export interface StockBuyFormHandle {
  reset(): void;
}

const StockBuyForm = (props: Props, ref: ComponentRefHandle<StockBuyFormHandle>) => {
  const store = useContext(StoreContext);

  const defaultBranch = store.auth.loggedInBranchId || '';
  const today = new Date();
  const todayStr = dateFormatter(today, 'MM/dd/yyyy');

  const [formData, setFormData] = useState<BuyFormData>({
    no: '',
    date: todayStr,
    deliveryDate: todayStr,
    paymentDueDate: todayStr,
    vendor: '',
    totalPrice: 0,
    billVatType: 'VAT_INCLUDED',
    branch: defaultBranch,
    note: '',
    deliveryFee: 0,
    discount: 0,
    paymentStatus: 'paid',
  });

  const classes = useStyles();

  useEffect(() => {
    store.stock.getVendors.request();
  }, []);

  useEffect(() => {
    if (props.initialData) {
      setFormData(props.initialData);
    }
  }, [props.initialData]);

  useEffect(() => {
    props.onDataChange(formData);
  }, [formData]);

  useEffect(() => {
    if (props.clear) {
      props.clear(clearData);
    }
  }, [props.clear]);

  // useImperativeHandle(ref, () => ({
  //   reset() {
  //     setFormData({
  //       date: todayStr,
  //       vendor: '',
  //       totalPrice: 0,
  //       billVatType: 'VAT_INCLUDED',
  //       branch: defaultBranch,
  //       note: '',
  //     });
  //   },
  // }));

  const clearData = () => {
    setFormData({
      no: '',
      date: todayStr,
      deliveryDate: todayStr,
      paymentDueDate: todayStr,
      vendor: formData.vendor,
      totalPrice: 0,
      billVatType: 'VAT_INCLUDED',
      branch: defaultBranch,
      note: '',
      deliveryFee: 0,
      discount: 0,
      paymentStatus: 'paid',
    });
  };

  const handleVendorInputChange = (event: React.ChangeEvent<any>, value: string | null) => {
    event.persist();

    setFormData((oldValue) => ({
      ...oldValue,
      vendor: value ?? '',
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const name = event.target.name as keyof BuyFormData;
    const isNumber = event.target.type === 'number';
    const value = event.target.value;
    let newValue = isNumber ? event.target.valueAsNumber : value;

    if (typeof newValue === 'number' && isNaN(newValue)) {
      newValue = value;
    }

    setFormData((oldValue) => ({
      ...oldValue,
      [name]: newValue,
    }));
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const newStatus = event.target.checked ? 'paid' : 'pending';

    setFormData((oldValue) => ({
      ...oldValue,
      paymentStatus: newStatus,
    }));
  };

  const handleDateChange = (key: keyof BuyFormData) => (date: Date | null) => {
    if (date == null) {
      return;
    }

    setFormData((oldValue) => ({
      ...oldValue,
      [key]: dateFormatter(date, 'MM/dd/yyyy'),
    }));
  };

  const handleSubmit = () => {
    props.onSubmit(formData);
  };

  return (
    <Paper className={clsx(classes.paper, classes.container)}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <TextField
          id="no"
          name="no"
          label="หมายเลขบิล"
          type="text"
          value={formData.no}
          className={clsx(classes.textField, classes.margin)}
          onChange={handleChange}
        />
        <KeyboardDatePicker
          id="date"
          name="date"
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="วันที่ซื้อ"
          value={formData.date}
          className={clsx(classes.textField, classes.margin)}
          onChange={handleDateChange('date')}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <KeyboardDatePicker
          id="deliveryDate"
          name="deliveryDate"
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="วันที่จัดส่ง"
          value={formData.deliveryDate}
          className={clsx(classes.textField, classes.margin)}
          onChange={handleDateChange('deliveryDate')}
          KeyboardButtonProps={{
            'aria-label': 'change deliveryDate',
          }}
        />
        <KeyboardDatePicker
          id="paymentDueDate"
          name="paymentDueDate"
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="วันครบกำหนดชำระ"
          value={formData.paymentDueDate}
          className={clsx(classes.textField, classes.margin)}
          onChange={handleDateChange('paymentDueDate')}
          KeyboardButtonProps={{
            'aria-label': 'change paymentDueDate',
          }}
        />
        <Autocomplete
          id="vendor"
          options={(store.stock.getVendors.data ?? []) as string[]}
          getOptionLabel={(option) => option}
          inputValue={formData.vendor}
          freeSolo={true}
          renderInput={(params) => (
            <TextField
              {...params}
              value={formData.vendor}
              name="vendor"
              label="สถานที่ซื้อ/Vendor"
              className={clsx(classes.textField, classes.margin)}
              // onChange={handleChange}
            />
          )}
          className={clsx(classes.autocompleteInline)}
          onInputChange={handleVendorInputChange}
          onChange={handleVendorInputChange}
        />
        <TextField
          id="totalPrice"
          name="totalPrice"
          label="ราคารวม"
          type="number"
          inputMode="decimal"
          value={formData.totalPrice}
          className={clsx(classes.textField, classes.margin)}
          InputProps={{
            inputMode: 'decimal',
            value: formData.totalPrice,
            endAdornment: <InputAdornment position="end">บาท</InputAdornment>,
          }}
          onChange={handleChange}
        />
        <TextField
          id="billVatType"
          name="billVatType"
          label="รูปแบบ VAT"
          value={formData.billVatType}
          select
          disabled={props.editMode}
          className={clsx(classes.textField, classes.margin)}
          onChange={handleChange}
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }}
        >
          {Object.keys(BILL_VAT_TYPES).map((key) => (
            <MenuItem key={key} value={key}>
              {BILL_VAT_TYPES[key as BillVatType]}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="branch"
          name="branch"
          label="สาขา"
          value={formData.branch}
          select
          disabled={props.editMode}
          className={clsx(classes.textField, classes.margin)}
          onChange={handleChange}
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }}
        >
          {store.branch.list.data &&
            store.branch.list.data.map((item) => (
              <MenuItem key={item._id} value={item._id}>
                {item.name}
              </MenuItem>
            ))}
        </TextField>
        <TextField
          id="deliveryFee"
          name="deliveryFee"
          label="ค่าขนส่ง"
          inputMode="decimal"
          value={formData.deliveryFee}
          className={clsx(classes.textField, classes.margin)}
          InputProps={{
            inputMode: 'decimal',
            endAdornment: <InputAdornment position="end">บาท</InputAdornment>,
          }}
          onChange={handleChange}
        />
        <TextField
          id="discount"
          name="discount"
          label="ส่วนลดท้ายบิล"
          inputMode="decimal"
          value={formData.discount}
          className={clsx(classes.textField, classes.margin)}
          InputProps={{
            inputMode: 'decimal',
            endAdornment: <InputAdornment position="end">บาท</InputAdornment>,
          }}
          onChange={handleChange}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.paymentStatus === 'paid'}
              name="paymentStatus"
              onChange={handleStatusChange}
            />
          }
          label="ชำระเงินเรียบร้อย"
          className={clsx(classes.checkbox, classes.margin)}
        />
        <TextField
          id="note"
          name="note"
          label="โน๊ต/หมายเหตุ"
          variant="outlined"
          className={clsx(classes.fullTextField, classes.margin)}
          value={formData.note}
          onChange={handleChange}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!props.canSubmit}
          className={classes.button}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </MuiPickersUtilsProvider>
    </Paper>
  );
};

StockBuyForm.defaultProps = {
  canSubmit: false,
  submitting: false,
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'end',
  },
  paper: {
    width: '100%',
    padding: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    minWidth: 200,
  },
  checkbox: {
    position: 'relative',
    top: 10,
  },
  menu: {
    minWidth: 200,
  },
  fullTextField: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  button: {
    width: '100%',
    maxWidth: 400,
    marginTop: theme.spacing(2),
  },
  autocompleteInline: {
    display: 'inline-flex',
  },
}));

export default observer(StockBuyForm);
