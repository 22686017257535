import { InputAdornment, Snackbar, TextField, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import { Column, EditComponentProps } from 'material-table';
import { observer } from 'mobx-react';
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MaterialTable } from '../../components';
import SubmitButton from '../../components/atoms/SubmitButton';
import ConfirmDialog from '../../components/Dialog/ConfirmDialog';
import SnackBarContent from '../../components/SnackBarContent';
import { StoreContext } from '../../mobx';
import routeName from '../../routes/routeName';

function ProductRecipeNewPage(props: any) {
  const store = useContext(StoreContext);
  const { product, supply } = store;

  const location = useLocation<{ productId?: string; productAmount?: number }>();
  const history = useHistory();
  const classes = useStyles();

  const [recipeId, setRecipeId] = useState<string>();
  const [formData, setFormData] = useState({
    product: location.state?.productId ?? '',
    amount: location.state?.productAmount || 1,
  });
  const [ingredients, setIngredients] = useState<IIngredientRecipe[]>([]);
  const [unit, setUnit] = useState('');
  const [notifiedData, setNotifiedData] = useState<INotifiedData>({
    open: false,
    variant: 'success',
    message: '',
  });
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);

  // Display values
  const products = product.list.data?.slice() || [];
  const suppies = supply.list.data?.slice() || [];
  const rawItems = suppies.filter(
    (item) => item.type === 'mixedIngredient' || item.type === 'rawIngredient'
  );
  const editedProduct = products.find((p) => p._id === location.state?.productId);

  const columns: Array<Column<IIngredientRecipe>> = [
    {
      title: 'วัตถุดิบ',
      field: 'ingredient',
      render: renderSupply,
      editComponent: renderSupplyInput,
    },
    { title: 'จำนวนที่ใช้', field: 'amount' },
    { title: 'หน่วย', field: 'unit', render: renderUnit, editComponent: renderEditableUnit },
  ];

  //

  useEffect(() => {
    supply.list.request();
    product.list.request();
    store.productRecipe.listStore.request();
  }, [supply.list]);

  useEffect(() => {
    if (formData.product === '') {
      return;
    }
    // const recipeIndex = recipes.findIndex(r => r.supply._id === formData.mixtedItem);
  }, []);

  useEffect(() => {
    const recipes = store.productRecipe.listStore.data;

    if (recipes === undefined) {
      return;
    }
    const recipeIndex = recipes.findIndex((r) => r.product._id === formData.product);
    if (recipeIndex < 0) {
      return;
    }

    const recipe = recipes[recipeIndex];
    const items: IIngredientRecipe[] = recipe.ingredients.map((item) => ({
      ingredient: item.ingredient._id,
      amount: formData.amount * item.amount,
      unit: item.unit,
    }));
    setIngredients(items);
    setRecipeId(recipe._id);
  }, [formData.product, formData.amount, store.productRecipe.listStore.data]);

  const canSubmit = () => {
    let valid = formData.product !== '' && formData.amount > 0 && ingredients.length > 0;

    valid = valid && ingredients.filter((v) => v.ingredient === '' || v.amount > 0).length > 0;

    return valid;
  };

  // helper methods

  const getUnit = (ingredient: string) => {
    const data = suppies.find((value) => ingredient === value._id);
    return (data && data.unit) || '-';
  };

  const sanitizedSupplyData = (data: IIngredientRecipe): IIngredientRecipe => {
    const amount = typeof data.amount === 'string' ? parseFloat(data.amount) : data.amount;
    if (isNaN(amount)) {
      throw new Error('กรุณากรอกจำนวนเป็นตัวเลข');
    }

    return {
      ...data,
      amount,
    };
  };

  // handle Events

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const name = event.target.name;
    const value = event.target.value;

    if (name === 'product' && value) {
      const index = products.findIndex((item) => item._id === value);
      if (index >= 0) {
        const item = products[index];
        setUnit(item.unit);
      }
    }

    setFormData((oldVals) => ({
      ...oldVals,
      [name]: value,
    }));
  };

  const handleProductChange = (e: React.ChangeEvent<{}>, value?: IProduct | null) => {
    if (value == null) {
      return;
    }

    const index = products.findIndex((item) => item._id === value._id);
    if (index >= 0) {
      const item = products[index];
      setUnit(item.unit);
    }

    setFormData((oldVals) => ({
      ...oldVals,
      product: value._id,
    }));
  };

  const handRowAdd = async (newData: IIngredientRecipe) => {
    const ingredientUnit = getUnit(newData.ingredient);
    const data = sanitizedSupplyData({ ...newData, unit: ingredientUnit });
    setIngredients((oldItems) => [...oldItems, data]);
  };

  const handleRowUpdate = async (newData: IIngredientRecipe, oldData?: IIngredientRecipe) => {
    const newIngredients = [...ingredients];

    const ingredientUnit = getUnit(newData.ingredient);
    const newIngredient = sanitizedSupplyData({ ...newData, unit: ingredientUnit });

    if (oldData) {
      const prevIndex = ingredients.findIndex((v) => v.ingredient === oldData.ingredient);
      if (prevIndex >= 0) {
        newIngredients[prevIndex] = newIngredient;
      } else {
        newIngredients.push(newIngredient);
      }
    } else {
      newIngredients.push(newIngredient);
    }

    setIngredients(newIngredients);
  };

  const handleRowDelete = async (oldData: IIngredientRecipe) => {
    const newIngredients = [...ingredients];
    const index = ingredients.findIndex((v) => v.ingredient === oldData.ingredient);
    if (index >= 0) {
      newIngredients.splice(index, 1);
    }
    setIngredients(newIngredients);
  };

  const handleSubmit = async () => {
    setOpenConfirmAlert(false);
    if (!canSubmit()) {
      return;
    }

    try {
      const { product, amount } = formData;
      await store.productRecipe.createStore.request(product, amount, unit, ingredients, recipeId);
      if (store.productRecipe.createStore.error) {
        setNotifiedData({
          open: true,
          variant: 'error',
          message: store.productRecipe.createStore.error.message,
        });
      } else {
        setFormData({
          product: '',
          amount: 0,
        });
        setIngredients([]);
        history.push(routeName.backoffice.productRecipes.list);
      }
    } catch (error) {
      setNotifiedData({
        open: true,
        variant: 'error',
        message: error.message,
      });
    }
  };

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotifiedData((oldValue) => ({
      ...oldValue,
      open: false,
    }));
  };

  // render methods
  function renderSupplyInput(inputProps: EditComponentProps<IIngredientRecipe>) {
    const name = rawItems.find((v) => inputProps?.rowData?.ingredient === v._id)?.name || '';
    return (
      <Autocomplete
        id="material-input-suggest"
        options={rawItems}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} placeholder={name} margin="normal" />}
        onChange={(event, value) =>
          inputProps.onChange(typeof value === 'string' ? value : value?._id)
        }
      />
    );
  }

  function renderSupply(rowData?: IIngredientRecipe) {
    const name = rawItems.find((v) => rowData?.ingredient === v._id)?.name || '';
    return (
      <Typography variant="caption" align="left">
        {name}
      </Typography>
    );
  }

  function renderUnit(rowData?: IIngredientRecipe) {
    let rowUnit = '-';
    if (rowData) {
      rowUnit = getUnit(rowData.ingredient);
    }
    return (
      <Typography variant="caption" align="left">
        {rowUnit}
      </Typography>
    );
  }

  function renderEditableUnit(props: EditComponentProps<IIngredientRecipe>) {
    const { rowData } = props;
    return renderUnit(rowData);
  }

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notifiedData.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <SnackBarContent
          variant={notifiedData.variant}
          message={notifiedData.message}
          onClose={handleClose}
        />
      </Snackbar>
      <ConfirmDialog
        open={openConfirmAlert}
        title="ยืนยันเพิ่ม recipe"
        onClose={() => setOpenConfirmAlert(false)}
        onCancel={() => setOpenConfirmAlert(false)}
        onConfirm={handleSubmit}
      />
      <Typography variant="h4" align="left">
        Product Recipe
      </Typography>
      <Paper className={clsx(classes.paper, classes.padding)}>
        <Typography variant="subtitle1" align="left">
          วัตถุดิบที่ผสม
        </Typography>
        <Autocomplete
          id="product-autocomplete"
          options={products}
          getOptionLabel={(option) => option?.name}
          defaultValue={editedProduct}
          className={classes.mixedAutocomplete}
          onChange={handleProductChange}
          renderInput={(params) => (
            <TextField {...params} className={classes.input} label="สินค้าที่ได้" name="product" />
          )}
        />
        <TextField
          value={formData.amount}
          className={classes.input}
          label="จำนวน"
          name="amount"
          type="number"
          onChange={handleChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
          }}
        />
      </Paper>
      <Paper className={classes.paper}>
        <MaterialTable
          title="วัตถุดิบที่ใช้"
          columns={columns}
          data={ingredients}
          editable={{
            onRowAdd: handRowAdd,
            onRowUpdate: handleRowUpdate,
            onRowDelete: handleRowDelete,
          }}
          options={{
            search: false,
            paging: false,
            showEmptyDataSourceMessage: false,
            actionsColumnIndex: 3,
          }}
        />
      </Paper>
      <SubmitButton
        disabled={!canSubmit()}
        loading={store.productRecipe.createStore.requesting}
        onClick={() => setOpenConfirmAlert(true)}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  mixedAutocomplete: {
    display: 'inline-flex',
  },
  input: {
    minWidth: 200,
    margin: theme.spacing(1),
  },
  menu: {
    minWidth: 200,
  },
  table: {
    minWidth: 650,
  },
  button: {
    marginTop: theme.spacing(2),
    width: 200,
  },
  padding: {
    padding: theme.spacing(2),
  },
}));

export default observer(ProductRecipeNewPage);
