import { Chip, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Column } from 'material-table';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { MaterialTable } from '../../../components';
import { StoreContext } from '../../../mobx';

function SuppliesPage() {
  const store = useContext(StoreContext);
  const { product } = store;
  const classes = useStyles();

  useEffect(() => {
    product.list.request();
  }, [product.list]);

  const columns: Array<Column<IProduct>> = [
    { title: 'ชื่อ', field: 'name' },
    {
      title: 'หน่วย',
      field: 'unit',
      width: '1%',
      headerStyle: { width: 100, maxWidth: 100 },
      cellStyle: { width: 100, maxWidth: 100 },
    },
    {
      title: 'ราคา',
      field: 'price',
      emptyValue: '-',
      type: 'currency',
      currencySetting: { locale: 'th-TH', currencyCode: 'THB' },
      width: '1%',
      headerStyle: { width: 100, maxWidth: 100 },
      cellStyle: { width: 100, maxWidth: 100 },
    },
    { title: 'Sale Product Ids', field: 'saleProductIds', render: renderSaleProductIdsRow },
  ];

  const handRowAdd = async (newData: IProduct) => {
    await product.add.request(newData);
  };

  const handleRowUpdate = async (newData: IProduct, oldData?: IProduct) => {
    await product.update.request(newData._id, newData);
  };

  // render methods
  function renderSaleProductIdsRow(rowData?: IProduct) {
    if (rowData) {
      const items = rowData.saleProductIds;
      const chips = items.map((item, index) => (
        <Chip key={index + item} label={item} className={classes.chip} />
      ));
      return chips;
    }
  }

  return (
    <div className={classes.root}>
      <Typography variant="h4" align="left">
        รายการสินค้า
      </Typography>
      <Paper className={classes.paper}>
        <MaterialTable
          title="รายการสินค้า"
          columns={columns}
          data={product.list.data || []}
          isLoading={product.list.requesting}
          editable={{
            onRowUpdate: handleRowUpdate,
            onRowAdd: handRowAdd,
          }}
          options={{
            actionsColumnIndex: columns.length,
          }}
        />
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  chip: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default observer(SuppliesPage);
