import axios from 'axios';
import { action } from 'mobx';

import BaseAPIStore, { IAPIStore } from '../api.store';

export class BillingSettlePaymentStore extends BaseAPIStore<IBilling[]> implements IAPIStore {
  @action
  public async request(id: string) {
    const url = this.apiUrl + `/billings/${id}/settle`;
    this.error = undefined;
    this.requesting = true;

    try {
      const response = await axios.post(url, undefined, this.authorizeConfigs());
      const responseData = response.data;

      if (responseData.data) {
        this.data = responseData.data;
      } else {
        this.data = undefined;
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.data = undefined;
      this.error = error instanceof Error ? error : new Error('Something went wrong.');
    } finally {
      this.requesting = false;
    }
  }
}
