import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Chip,
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { ArrowDownward, ArrowUpward, Edit } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { utils, writeFileXLSX } from 'xlsx';
import { StoreContext } from '../../../mobx';
import { supplyAmountFormat } from '../../../utils/formatter';
import AdjustDialog from './components/AdjustDialog';
import SearchForm from './components/SearchForm';
import { RoleStore } from '../../../mobx/stores/roleStore/roleStore';

interface Props {
  isRange: boolean;
}

function StockDailyReportPage(props: Props) {
  const store = useContext(StoreContext);
  const { stockDailyReport } = store.report;

  const classes = useStyles();

  const [openAdjustDialog, setOpenAdjustDialog] = useState(false);
  const [search, setSearch] = useState('');
  const [supplyType, setSupplyType] = useState<SupplyType | ''>();
  const [searchDate, setSearchDate] = useState<[Date?, Date?]>();
  const [searchBranch, setSearchBranch] = useState<string | ''>();
  const [adjustData, setAdjustData] = useState<{
    supply: ISupply;
    branch: IBranch;
    amount: number;
  }>();

  useEffect(() => {
    console.log(stockDailyReport.error);
  }, [stockDailyReport.error]);

  const filteredData = useMemo(() => {
    const data = stockDailyReport.data?.slice() ?? [];
    const searchFn = (supply: ISupply) => {
      if (search) {
        return (
          supply.name.toLowerCase().includes(search.toLowerCase()) ||
          supply.code?.toLowerCase().includes(search.toLowerCase())
        );
      } else {
        return true;
      }
    };

    const supplyTypeFn = (supply: ISupply) => {
      if (supplyType) {
        return supply.type === supplyType;
      } else {
        return true;
      }
    };

    const newData = data.filter((item) => {
      if (item.supply.name === '') {
        return false;
      }
      const searchMatch = searchFn(item.supply);
      const supplyTypeMatch = supplyTypeFn(item.supply);

      return searchMatch && supplyTypeMatch;
    });

    return newData;
  }, [stockDailyReport.data, search, supplyType]);

  // methods

  // Handle event methods

  const handleExport = () => {
    const getDateDiff = (date1: Date, date2: Date) => {
      const diffTime = Math.abs(date2.getTime() - date1.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    };

    const dateDiff =
      props.isRange && searchDate?.[0] && searchDate?.[1]
        ? getDateDiff(searchDate[0], searchDate[1])
        : -1;

    const itemIsLow = (item: IStockDailyRport) => {
      if (props.isRange && dateDiff > 0) {
        return Math.abs(item.subtractedAmount) > item.remainingAmount;
      } else {
        return;
      }
    };

    const data = filteredData.map((item) => ({
      รหัส: item.supply.code,
      วัตถุดิบ: item.supply.name,
      สาขา: searchBranch ?? '-',
      จำนวนที่เหลือ: supplyAmountFormat(item.remainingAmount, item.supply.unit, 3),
      การเคลื่อนไหวขึ้น: supplyAmountFormat(item.addedAmount, item.supply.unit, 3),
      การเคลื่อนไหวลง: supplyAmountFormat(item.subtractedAmount, item.supply.unit, 3),
      '❗': itemIsLow(item) ? '❗' : undefined,
    }));

    const dateFns = new DateFnsUtils();
    const dateStr = searchDate
      ?.filter((v) => v)
      .map((v) => dateFns.format(v, 'yyyy-MM-dd'))
      .join('-');
    const sheetName = props.isRange ? `Weekly ${dateStr}` : `Daily ${dateStr}`;

    const fileName = props.isRange ? 'stock-weekly-report.xlsx' : 'stock-daily-report.xlsx';
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(data);
    utils.book_append_sheet(wb, ws, sheetName);
    writeFileXLSX(wb, fileName);
  };

  const handleSearchChange = async (fromDate: Date, branch: string, toDate?: Date) => {
    const dateFns = new DateFnsUtils();
    const dateString = dateFns.format(fromDate, 'yyyy-MM-dd');
    const toDateString = toDate && dateFns.format(toDate, 'yyyy-MM-dd');
    setSearchDate([fromDate, toDate]);
    const branchName = store.branch.list.data?.find((b) => b._id === branch)?.name;
    setSearchBranch(branchName);
    await stockDailyReport.request(dateString, branch, toDateString, props.isRange);
  };

  const handleSearchSupplyChange = (supply: string, supplyType?: SupplyType) => {
    setSearch(supply);
    setSupplyType(supplyType);
  };

  const handleClickAdjustSupply = (supply: ISupply, branch: IBranch, amount: number) => {
    setOpenAdjustDialog(true);
    setAdjustData({ supply, branch, amount });
  };

  return (
    <div className={classes.root}>
      {adjustData && (
        <AdjustDialog
          open={openAdjustDialog}
          supply={adjustData.supply}
          branch={adjustData.branch}
          amount={adjustData.amount}
          onClose={() => setOpenAdjustDialog(false)}
          onSubmitSuccess={() => setOpenAdjustDialog(false)}
        />
      )}
      <div className={classes.header}>
        <Typography variant="h4" align="left">
          {props.isRange ? ' Stock Weekly Report' : 'Stock Daily Report'}
        </Typography>

        {!!RoleStore.canExportStockDailyReport(store.auth.currentUser) && (
          <Button variant="contained" color="primary" onClick={handleExport}>
            Export
          </Button>
        )}
      </div>

      <SearchForm
        isRange={props.isRange}
        onChange={handleSearchChange}
        onSupplySearch={handleSearchSupplyChange}
      />

      <Paper className={classes.paper}>
        <LinearProgress hidden={!stockDailyReport.requesting} />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>รหัส</TableCell>
              <TableCell>วัตถุดิบ</TableCell>
              <TableCell>สาขา</TableCell>
              <TableCell align="right">จำนวนที่เหลือ</TableCell>
              <TableCell align="right">การเคลื่อนไหว</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row) => (
              <TableRow key={row._id}>
                <TableCell component="th" scope="row">
                  {row.supply.code ?? ''}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.supply.name}
                </TableCell>
                <TableCell>{(row.branch && row.branch.name) || '-'}</TableCell>
                <TableCell align="right">
                  {supplyAmountFormat(row.remainingAmount, row.supply.unit)}
                </TableCell>
                <TableCell align="right">
                  <Chip
                    icon={<ArrowUpward />}
                    label={supplyAmountFormat(row.addedAmount, row.supply.unit)}
                    className={classes.margin}
                  />
                  <Chip
                    icon={<ArrowDownward />}
                    label={supplyAmountFormat(row.subtractedAmount, row.supply.unit)}
                    className={classes.margin}
                  />
                </TableCell>
                <TableCell align="right">
                  {row.branch && (
                    <IconButton
                      aria-label="edit"
                      className={classes.margin}
                      onClick={() =>
                        handleClickAdjustSupply(row.supply, row.branch!, row.remainingAmount)
                      }
                    >
                      <Edit />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}

StockDailyReportPage.defaultProps = {
  isRange: false,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 500,
  },
  textField: {
    minWidth: 200,
  },
  menu: {
    minWidth: 200,
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

export default observer(StockDailyReportPage);
