import axios from 'axios';
import {action} from 'mobx';

import BaseAPIStore, {IAPIStore} from '../api.store';

export class StockMixStore extends BaseAPIStore implements IAPIStore {

  @action
  public async request(mixedSupply: string, amount: number, branch: string, usedSupplies: Array<{supply: string, amount: number}>) {
    const url = this.apiUrl + '/stocks/action/mix';
    this.error = undefined;
    this.requesting = true;
    try {
      const params = {supply: mixedSupply, amount, branch, usedSupplies};
      const response = await axios.post(url, params, this.authorizeConfigs());
      const responseData = response.data;

      if (responseData.data) {
        this.data = responseData.data;
      } else {
        this.data = undefined;
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.data = undefined;
      this.error = error;
    }

    this.requesting = false;
  }
}

const storeMixStore = new StockMixStore();
export default storeMixStore;
