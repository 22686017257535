import DateFnsUtils from '@date-io/date-fns';
import { Button, makeStyles, Paper, Typography } from '@material-ui/core';
import { Column } from 'material-table';
import React from 'react';
import { utils, writeFileXLSX } from 'xlsx';
import { MaterialTable } from '../../components';
import { useFetchApi } from '../../utils/services/api/useFetchApi';
import OrderProductSearchForm from './components/OrderProductSearchForm';

interface FetchParams {
  start_date?: string;
  end_date?: string;
  status?: string;
}

export function OrderProductsReportPage() {
  const classes = useStyles();

  const [params, setParams] = React.useState<FetchParams>({
    start_date: undefined,
    end_date: undefined,
    status: undefined,
  });
  const [exporting, setExporting] = React.useState(false);

  const { data: products, isValidating } = useFetchApi<IBookingOrderProduct[]>(
    '/orders/group-products',
    { params }
  );

  const columns: Array<Column<IBookingOrderProduct>> = [
    {
      title: 'ชื่อสินค้า',
      field: 'product',
      render: renderProducts,
      filtering: false,
      width: '1%',
    },
    { title: 'จำนวน', field: 'totalQuantity', render: renderQuantity, filtering: false },
  ];

  //

  function handleOnSearchChange(fromDate: Date, toDate: Date, status?: string) {
    const dateFns = new DateFnsUtils();
    setParams({
      start_date: dateFns.format(fromDate, 'yyyy-MM-dd'),
      end_date: dateFns.format(toDate, 'yyyy-MM-dd'),
      status,
    });
    console.log(fromDate, toDate, status);
  }

  const handleOnPressExport = () => {
    if (exporting) {
      return;
    }
    setExporting(true);
    const dateFns = new DateFnsUtils();
    const startDate = dateFns.parse(params.start_date, 'yyyy-MM-dd');
    const endDate = dateFns.parse(params.end_date, 'yyyy-MM-dd');

    const startStr = dateFns.format(startDate, 'dd/MM/yyyy');
    const endStr = dateFns.format(endDate, 'dd/MM/yyyy');

    const exportingData = products?.map((product) => ({
      วันที่: `${startStr}-${endStr}`,
      ชื่อสินค้า: product.product.name,
      จำนวน: product.totalQuantity,
    }));

    const ws = utils.json_to_sheet(exportingData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'data');
    const fileName = `order-products-report-${startStr} - ${endStr}.xlsx`;
    writeFileXLSX(wb, fileName);
    setExporting(false);
  };

  function renderProducts(rowData: IBookingOrderProduct) {
    return (
      <Typography variant="body2" align="left">
        {rowData.product.name}
      </Typography>
    );
  }
  function renderQuantity(rowData: IBookingOrderProduct) {
    return (
      <Typography variant="body2" align="left">
        {rowData.totalQuantity} {rowData.product.unit}
      </Typography>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h4" align="left">
          Booking Order List
        </Typography>
        <Button
          variant="contained"
          color="primary"
          disabled={exporting}
          onClick={handleOnPressExport}
        >
          {exporting ? 'Exporting...' : 'Export'}
        </Button>
      </div>

      <Paper className={classes.paper}>
        <OrderProductSearchForm onChange={handleOnSearchChange} />
        <MaterialTable
          title=""
          columns={columns}
          data={products || []}
          isLoading={isValidating}
          options={{ actionsColumnIndex: 0, filtering: true }}
        />
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  itemDetailContainer: {
    padding: theme.spacing(2),
  },
  bold: {
    fontWeight: 500,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
