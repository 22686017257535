import axios from 'axios';
import { action } from 'mobx';

import BaseAPIStore, { IAPIStore } from '../api.store';
import { BranchStore } from './branchStore';

class BranchUpdateStore extends BaseAPIStore<IBranch> implements IAPIStore {
  private branchStore: BranchStore;

  constructor(branchStore: BranchStore) {
    super();
    this.branchStore = branchStore;
  }

  @action
  public async request(id: string, newData: IBranch) {
    const url = this.apiUrl + '/branches/' + id;
    this.error = undefined;
    this.requesting = true;
    try {
      const response = await axios.put(url, newData, this.authorizeConfigs());
      const responseData = response.data;

      if (responseData.data) {
        this.data = responseData.data;

        if (this.branchStore.list.data) {
          const index = this.branchStore.list.data.findIndex((item) => item._id === id);
          if (index >= 0) {
            const newList = [...this.branchStore.list.data];
            newList[index] = responseData.data;
            this.branchStore.list.data = newList;
          }
        }
      } else {
        this.error = new Error('Something went wrong.');
      }
    } catch (error) {
      this.error = error;
    }

    this.requesting = false;
  }
}

export default BranchUpdateStore;
