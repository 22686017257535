// tslint:disable: variable-name

import {
  Collapse,
  Divider,
  Drawer,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  ArrowBack,
  ArrowForward,
  Dashboard,
  ExpandLess,
  ExpandMore,
  FormatListBulleted,
  Kitchen,
  PlusOne,
  Settings,
  ShoppingCart,
  Storefront,
  Timeline,
  ViewModule,
} from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { StoreContext } from '../../../../mobx';
import { RoleStore } from '../../../../mobx/stores/roleStore/roleStore';
import routeName from '../../../../routes/routeName';
import { constants } from '../../../../shared/constants';

const drawerWidth = 240;

interface ISideMenu {
  title: string;
  href: string;
  icon?: JSX.Element;
  subMenus?: ISideMenu[];
}

const pages: ISideMenu[] = [
  {
    title: 'Dashboard',
    href: routeName.backoffice.dashboard,
    icon: <Dashboard />,
  },
  {
    title: 'Users',
    href: routeName.backoffice.user.list,
    icon: <Icon className="fas fa-user" fontSize="small" />,
  },
  {
    title: 'วัตถุดิบและสิ่งของ',
    href: routeName.backoffice.supplies,
    icon: <Kitchen />,
  },
  {
    title: 'สินค้า',
    href: routeName.backoffice.product.list,
    icon: <Icon className="fas fa-circle" fontSize="small" />,
  },
  {
    title: 'Recipes',
    href: routeName.backoffice.recipes.list,
    icon: <Icon className="fas fa-book" />,
  },
  {
    title: 'Product Recipes',
    href: routeName.backoffice.productRecipes.list,
    icon: <Icon className="fas fa-book" />,
  },
  {
    title: 'สาขา',
    href: routeName.backoffice.branches,
    icon: <Storefront />,
  },
];

const ordersActions: ISideMenu[] = [
  {
    title: 'Order List',
    href: routeName.backoffice.orders.list,
    icon: <FormatListBulleted />,
  },
  {
    title: 'Order Product',
    href: routeName.backoffice.orders.groupProducts,
    icon: <FormatListBulleted />,
  },
  {
    title: 'New Order',
    href: routeName.backoffice.orders.new,
    icon: <PlusOne />,
  },
];

const stockActions: ISideMenu[] = [
  {
    title: 'เพิ่ม Stock',
    href: routeName.backoffice.stock.buy,
    icon: <ViewModule />,
  },
  {
    title: 'ผสมวัตถุดิบ',
    href: routeName.backoffice.stock.mix,
    icon: <Icon className="fas fa-blender" />,
  },
  {
    title: 'ส่งวัตถุดิบ',
    href: routeName.backoffice.stock.transfer,
    icon: <ArrowBack />,
  },
  {
    title: 'รับวัตถุดิบ',
    href: routeName.backoffice.stock.receiveList,
    icon: <ArrowForward />,
  },
];

const reports: ISideMenu[] = [
  {
    title: 'Stock daily report',
    href: routeName.backoffice.reports.stockDailyReport,
    icon: <FormatListBulleted />,
  },
  {
    title: 'Stock weekly report',
    href: routeName.backoffice.reports.stockRangeReport,
    icon: <FormatListBulleted />,
  },
  {
    title: 'Sales Report List',
    href: routeName.backoffice.reports.productSalesReportList,
    icon: <FormatListBulleted />,
  },
  {
    title: 'Sales Report',
    href: routeName.backoffice.reports.productSalesReport,
    icon: <FormatListBulleted />,
  },
  {
    title: 'Adjust Report',
    href: routeName.backoffice.reports.adjustReport,
    icon: <FormatListBulleted />,
  },
  {
    title: 'Transfer Report',
    href: routeName.backoffice.reports.transferReport,
    icon: <FormatListBulleted />,
  },
  {
    title: 'Unpaid Bills',
    href: routeName.backoffice.reports.unpaidBilling,
    icon: <FormatListBulleted />,
  },
  {
    title: 'Expenses Report',
    href: routeName.backoffice.reports.expensesReport,
    icon: <FormatListBulleted />,
  },
];

const plans: ISideMenu[] = [
  {
    title: 'Supplies Usage Plan',
    href: routeName.backoffice.plans.suppliesUsagePlan,
    icon: <FormatListBulleted />,
  },
];

const settings: ISideMenu[] = [
  {
    title: 'Sales Report Import',
    href: routeName.backoffice.settings.salesReportImport,
  },
];

function Sidebar() {
  const store = useContext(StoreContext);

  const [orderActionOpen, setOrderActionOpen] = useState(false);
  const [stockActionOpen, setStockActionOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const [plansOpen, setPlansOpen] = useState(false);
  const [settingOpen, setSettingOpen] = useState(false);

  const history = useHistory();
  const classes = useStyles();

  const _pages = pages.filter((item) => RoleStore.canAccessPage(item.href, store.auth.currentUser));
  const _ordersActions = ordersActions.filter((item) =>
    RoleStore.canAccessPage(item.href, store.auth.currentUser)
  );
  const _stockActions = stockActions.filter((item) =>
    RoleStore.canAccessPage(item.href, store.auth.currentUser)
  );
  const _reports = reports.filter((item) =>
    RoleStore.canAccessPage(item.href, store.auth.currentUser)
  );
  const _plans = plans.filter((item) => RoleStore.canAccessPage(item.href, store.auth.currentUser));
  const _settings = settings.filter((item) =>
    RoleStore.canAccessPage(item.href, store.auth.currentUser)
  );

  useEffect(() => {
    const isStockOpen = stockActions.map((item) => item.href).includes(history.location.pathname);
    const isReportOpen = reports.map((item) => item.href).includes(history.location.pathname);
    const isPlansOpen = plans.map((item) => item.href).includes(history.location.pathname);
    const isSettingOpen = settings.map((item) => item.href).includes(history.location.pathname);

    setStockActionOpen(isStockOpen);
    setReportOpen(isReportOpen);
    setPlansOpen(isPlansOpen);
    setSettingOpen(isSettingOpen);
  }, [history.location.pathname]);

  const renderMenuItem = (menu: ISideMenu, className?: any) => {
    return (
      <ListItem
        button
        key={menu.title}
        className={className}
        component="a"
        onClick={() => history.push(menu.href)}
      >
        {menu.icon && <ListItemIcon>{menu.icon}</ListItemIcon>}
        <ListItemText primary={menu.title} />
      </ListItem>
    );
  };

  return (
    <Drawer
      anchor="left"
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
      variant="permanent"
    >
      <div className={classes.toolbar} />
      <Divider />
      <List className={classes.list}>
        {_pages.map((page) => renderMenuItem(page))}

        {_ordersActions.length > 0 && (
          <>
            <ListItem button onClick={() => setOrderActionOpen(!orderActionOpen)}>
              <ListItemIcon>
                <ShoppingCart />
              </ListItemIcon>
              <ListItemText primary={'Booking Orders'} />
              {orderActionOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={orderActionOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {_ordersActions.map((page) => renderMenuItem(page, classes.nested))}
              </List>
            </Collapse>
          </>
        )}

        {_stockActions.length > 0 && (
          <>
            <ListItem button onClick={() => setStockActionOpen(!stockActionOpen)}>
              <ListItemIcon>
                <Icon className="fas fa-blender" />
              </ListItemIcon>
              <ListItemText primary={'Stock Management'} />
              {stockActionOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={stockActionOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {_stockActions.map((page) => renderMenuItem(page, classes.nested))}
              </List>
            </Collapse>
          </>
        )}

        {_reports.length > 0 && (
          <>
            <ListItem button onClick={() => setReportOpen(!reportOpen)}>
              <ListItemIcon>
                <Timeline />
              </ListItemIcon>
              <ListItemText primary={'Report'} />
              {reportOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={reportOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {_reports.map((page) => renderMenuItem(page, classes.nested))}
              </List>
            </Collapse>
          </>
        )}

        {_plans.length > 0 && (
          <>
            <ListItem button onClick={() => setPlansOpen(!plansOpen)}>
              <ListItemIcon>
                <Timeline />
              </ListItemIcon>
              <ListItemText primary={'Plans'} />
              {plansOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={plansOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {_plans.map((page) => renderMenuItem(page, classes.nested))}
              </List>
            </Collapse>
          </>
        )}

        {_settings.length > 0 && (
          <>
            <ListItem button onClick={() => setSettingOpen(!settingOpen)}>
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary={'Setting'} />
              {settingOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={settingOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {settings.map((page) => renderMenuItem(page, classes.nested))}
              </List>
            </Collapse>
          </>
        )}
      </List>
      <div className={classes.footer}>
        <Typography color="textSecondary">{`v${constants.APP_VERSION}`}</Typography>
      </div>
    </Drawer>
  );
}

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.grey[200],
  },
  root: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
  nested: {
    paddingLeft: theme.spacing(4),
  },
  list: {
    flex: 1,
  },
  footer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

export default Sidebar;
