import { observable } from 'mobx';
import BranchAddStore from './branch.add.store';
import listStore from './branch.list.store';
import transferListStore from './branch.transferList.store';
import BranchUpdateStore from './branch.update.store';

export class BranchStore {
  @observable public list = listStore;
  @observable public update: BranchUpdateStore;
  @observable public add: BranchAddStore;
  @observable public transferListStore = transferListStore;

  constructor() {
    this.update = new BranchUpdateStore(this);
    this.add = new BranchAddStore(this);
  }
}

const branchStore = new BranchStore();
export default branchStore;
