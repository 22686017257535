const routeName = {
  root: '/',
  auth: {
    login: '/auth/login',
    logout: '/auth/logout',
  },
  backoffice: {
    dashboard: '/backoffice/dashboard',
    user: {
      list: '/backoffice/users',
      new: '/backoffice/users/new',
      edit: '/backoffice/users/edit',
    },
    supplies: '/backoffice/supplies',
    branches: '/backoffice/branches',
    recipes: {
      new: '/backoffice/recipes/new',
      update: '/backoffice/recipes/:id',
      list: '/backoffice/recipes/',
    },
    stock: {
      index: '/backoffice/stock',
      buy: '/backoffice/stock/buy',
      buyEdit: '/backoffice/stock/buy/edit',
      mix: '/backoffice/stock/mix',
      transfer: '/backoffice/stock/transfer',
      receiveList: '/backoffice/stock/receive',
    },
    reports: {
      stockDailyReport: '/backoffice/report/stock-daily-report',
      stockRangeReport: '/backoffice/report/stock-range-report',
      productSalesReport: '/backoffice/report/product-sales-report',
      productSalesReportList: '/backoffice/report/product-sales-report-list',
      productSalesReportDateDetail: '/backoffice/report/product-sales-report-by-date',
      adjustReport: '/backoffice/report/adjust-report',
      transferReport: '/backoffice/report/transfer-report',
      unpaidBilling: '/backoffice/report/unpaid-bill',
      expensesReport: '/backoffice/report/expenses-report',
      expensesCategoryReport: '/backoffice/report/expenses-category-report',
    },
    plans: {
      suppliesUsagePlan: '/backoffice/plans/supplies-usage',
    },
    product: {
      list: '/backoffice/products',
    },
    productRecipes: {
      new: '/backoffice/products/recipes/new',
      update: '/backoffice/products/recipes/:id',
      list: '/backoffice/products/recipes/',
    },
    orders: {
      list: '/backoffice/orders',
      new: '/backoffice/orders/new',
      groupProducts: '/backoffice/orders/group-products',
      update: '/backoffice/orders/:id',
    },
    settings: {
      salesReportImport: '/backoffice/settings/sales-report-import',
    },
  },
  404: '404',
};

export default routeName;
