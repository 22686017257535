import DateFnsUtils from '@date-io/date-fns';
import {
  Collapse,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../../mobx';
import { dateFormatter, supplyAmountFormat } from '../../../utils/formatter';

function SuppliesUsagePlanPage() {
  const store = useContext(StoreContext);
  const suppliesUsage = store.plans.suppliesUsage;
  const classes = useStyles();

  const [date, setDate] = useState<Date | null>(new Date());
  const [openProducts, setOpenProducts] = useState(false);

  useEffect(() => {
    if (date) {
      const dateString = dateFormatter(date);
      suppliesUsage.request(dateString);
    }
  }, [date]);

  return (
    <div className={classes.root}>
      <Typography variant="h4" align="left">
        Supplies Usage Plan
      </Typography>

      <Paper className={classes.paper}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Date"
            value={date}
            onChange={setDate}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </Paper>

      <Paper className={classes.paper}>
        <LinearProgress hidden={!suppliesUsage.requesting} />
        <Typography variant="h5" align="left">
          Supplies
        </Typography>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>วัตถุดิบ</TableCell>
              <TableCell>จำนวนที่คาดการว่าจะใช้ทั้งหมด</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {suppliesUsage.data &&
              suppliesUsage.data.useageData &&
              suppliesUsage.data.useageData.map(row => (
                <TableRow key={row._id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{supplyAmountFormat(row.totalAmount, row.unit)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>

      <Paper className={classes.paper}>
        <LinearProgress hidden={!suppliesUsage.requesting} />
        <div className={classes.productContainer} onClick={() => setOpenProducts(!openProducts)}>
          <Grid container justify="space-between" style={{ padding: 10 }}>
            <Typography variant="h5" align="left">
              Products
            </Typography>
            {openProducts ? <ExpandLess /> : <ExpandMore />}
          </Grid>
        </div>
        <Collapse in={openProducts} timeout="auto" unmountOnExit>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>สินค้า</TableCell>
                <TableCell>จำนวนที่คาดการว่าจะขายได้ทั้งหมด</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {suppliesUsage.data &&
                suppliesUsage.data.productsSalesData &&
                suppliesUsage.data.productsSalesData.map(row => (
                  <TableRow key={row._id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{supplyAmountFormat(row.totalVolume, row.unit)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Collapse>
      </Paper>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
    minHeight: 50,
  },
  table: {
    minWidth: 500,
  },
  textField: {
    minWidth: 200,
  },
  menu: {
    minWidth: 200,
  },
  margin: {
    margin: theme.spacing(1),
  },
  productContainer: {
    padding: theme.spacing,
    cursor: 'pointer',
  },
}));

export default observer(SuppliesUsagePlanPage);
