import React, { useMemo } from 'react';
import {
  Card,
  Container,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import { dateISOFomatter, supplyAmountFormat } from '../../utils/formatter';

interface Props {
  item?: ITransferSupply;
  open: boolean;
  handleClose: () => void;
}

export const TransferItemReportModal = ({ item, open, handleClose }: Props) => {
  const classes = useStyles();

  const supplies = useMemo(() => {
    return (
      item?.supplies?.map((supply) => {
        const receive = item.receivedSupplies?.find(
          (receive) => receive.supply._id === supply.supply._id
        );

        const name = supply.supply.code
          ? `${supply.supply.code} - ${supply.supply.name}`
          : supply.supply.name;

        return {
          code: supply.supply.code,
          name,
          unit: supply.supply.unit,
          amount: supply.amount,
          receiveAmount: receive?.amount,
        };
      }) ?? []
    );
  }, [item]);

  if (!item) return null;

  return (
    <Container className={classes.container}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Card className={classes.card}>
          <h2>รายละเอียด </h2>
          <div>
            สถานะ: <span className={classes.bold}>{item.status}</span>
          </div>
          <div>สาขา: {item.branch.name}</div>
          <div>สาขาปลายทาง: {item.toBranch.name}</div>
          <div>วันที่ส่ง: {dateISOFomatter(item.createdAt ?? '', 'ccc, dd-MM-yyyy HH:mm')}</div>
          <div>
            วันที่รับ:{' '}
            {item.status === 'RECEIVED'
              ? dateISOFomatter(item.updatedAt ?? '', 'ccc, dd-MM-yyyy HH:mm')
              : '-'}
          </div>
          <div>ผู้ส่ง: {item.actor.firstname}</div>
          <div>โน๊ต: {item.note}</div>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.bold}>ลำดับ</TableCell>
                <TableCell className={classes.bold}>ชื่อวัตถุดิบ</TableCell>
                <TableCell className={classes.bold}>จำนวนส่งออก</TableCell>
                <TableCell className={classes.bold}>จำนวนรับ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {supplies.map((row, index) => (
                <TableRow key={row.code}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{supplyAmountFormat(row.amount, row.unit)}</TableCell>
                  <TableCell>
                    {row.receiveAmount != null
                      ? supplyAmountFormat(row.receiveAmount, row.unit)
                      : '-'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </Modal>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 1280,
    marginTop: '5%',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxHeight: '80%',
    overflow: 'auto',
  },
  bold: {
    fontWeight: 'bold',
  },
  table: {},
}));
